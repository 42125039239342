export const changelog = {
  1.5: {
    date: "2020-12-01",
    sections: [
      {
        title: "Track artwork status",
        texts: ["Add and sort by artwork statuses for easier management of your artworks"],
        image: `${process.env.REACT_APP_CLIENT_URL}/images/product-examples/feature-gifs/artwork-status.gif`,
      },
      {
        title: "Save more information",
        texts: [
          "With your help we added more useful artwork fields: <b>work descriptions</b>, <b>editions</b>, <b>series titles</b>,  and <b>incoming date</b> as well as <b>detailed packaging info</b> and allowing <b>variable dimensions</b>.",
        ],
      },
      {
        title: "Full screen images",
        texts: ["View your images full screen by clicking on artwork images in the sidebar or in the images form"],
        image: `${process.env.REACT_APP_CLIENT_URL}/images/product-examples/feature-gifs/fullscreen-artwork.gif`,
      },
    ],
  },
  1.6: {
    date: "2021-04-04",
    sections: [
      {
        title: "Expanded exports",
        texts: [
          "Welcome to your new export system, redesigned from the ground up!",
          "You can now pick from 12 unique artwork exports with even more coming soon.",
        ],
        image: `${process.env.REACT_APP_CLIENT_URL}/images/product-examples/feature-gifs/advanced-exports.gif`,
      },
      {
        title: "Sort by Dates",
        texts: [
          "You can now sort your artworks by the date they were created ...",
          "As well as the date you last updated them!",
        ],
        image: `${process.env.REACT_APP_CLIENT_URL}/images/product-examples/feature-gifs/sort-dates.gif`,
      },
    ],
  },
  1.7: {
    date: "2021-05-28",
    sections: [
      {
        title: "Currency support",
        texts: ["You can now add different currencies to your prices across all your devices!"],
        image: `${process.env.REACT_APP_CLIENT_URL}/images/product-examples/feature-gifs/currency-support.gif`,
      },
    ],
  },
};
