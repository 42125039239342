import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { newTutorialStep, tutorialCompleted } from "../../store/actions";
import { JOYRIDE_STEPS } from "../../config";
import { ButtonLabels } from "../../localisation";

import ReactJoyride, { ACTIONS, STATUS, LIFECYCLE } from "react-joyride";
import { Box, Flex, Text } from "../fundamentals";
import { Button } from ".";

const Joyride = ({ tutorial, tutorialCompleted, newTutorialStep, ...props }) => {
  function tutorialCallback({ lifecycle, status, action, index }) {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && lifecycle === LIFECYCLE.COMPLETE) tutorialCompleted();
    else if (ACTIONS.NEXT === action && lifecycle === LIFECYCLE.COMPLETE) newTutorialStep(index + 1);
    else if (ACTIONS.PREV === action && lifecycle === LIFECYCLE.COMPLETE) newTutorialStep(index + 1);
  }
  if (tutorial.executing && JOYRIDE_STEPS[tutorial.executing.name].length > 0) {
    const [firstStep, ...remainingSteps] = JOYRIDE_STEPS[tutorial.executing.name];
    firstStep.floaterProps = {
      styles: { floaterWithAnimation: { transition: "opacity 0.8s, transform 0s" } }
    };
    return (
      <ReactJoyride
        run={true}
        continuous={true}
        callback={tutorialCallback}
        steps={[firstStep, ...remainingSteps]}
        tooltipComponent={JoyrideTooltip}
        {...props}
      />
    );
  } else return null;
};

const JoyrideTooltip = ({
  continuous,
  isLastStep,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps,
  ...props
}) => {
  return (
    <Box maxWidth="40vw" fontFamily="body" bg="white" borderRadius="2px" p="m" pt="s" pb="s" {...tooltipProps}>
      {step.title && (
        <Text size="heading3" mb="xs">
          {step.title}
        </Text>
      )}
      <Text size="body" whiteSpace="pre-wrap">
        {step.content}
      </Text>
      <Flex pt="xs" justifyContent="flex-end">
        {continuous && size > 1 && !isLastStep && (
          <Box flex="1">
            <Button buttonStyle="secondary" size="small" {...skipProps}>
              <Text id="skip">{ButtonLabels.skip}</Text>
            </Button>
          </Box>
        )}
        {index > 0 && (
          <Button buttonStyle="primary" size="small" ml="xxs" {...backProps}>
            <Text id="back">{ButtonLabels.back}</Text>
          </Button>
        )}
        {size > 1 && !isLastStep && (
          <Button buttonStyle="primary" size="small" ml="xxs" {...primaryProps}>
            <Text id="next">{ButtonLabels.next}</Text>
          </Button>
        )}
        {size > 1 && isLastStep && (
          <Button buttonStyle="primary" size="small" ml="xxs" {...closeProps}>
            <Text id="close">{ButtonLabels.finish}</Text>
          </Button>
        )}
        {size === 1 && (
          <Button buttonStyle="primary" size="small" ml="xxs" {...closeProps}>
            <Text id="close">{ButtonLabels.ok}</Text>
          </Button>
        )}
      </Flex>
    </Box>
  );
};

function mapStateToProps(state) {
  const { tutorial } = state;
  return { tutorial };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      newTutorialStep: newTutorialStep,
      tutorialCompleted: tutorialCompleted
    },
    dispatch
  );
}

const ConnectedJoyride = connect(mapStateToProps, mapDispatchToProps)(Joyride);

export { ConnectedJoyride as Joyride };
