import React from "react";
import { changePassword } from "../../store/actions";
import { ButtonLabels, Headings, InputLabels, InputErrors } from "../../localisation";

import { Field, reduxForm } from "redux-form";
import { Box, Text } from "../fundamentals";
import { Input, Button } from "../elements";

const Form = ({ handleSubmit, submitting }) => {
  return (
    <form onSubmit={handleSubmit(changePassword)}>
      <Box width="320px">
        <Text size="heading3" mb="xxl" color="black">
          {Headings.changePassword}
        </Text>
        <Field name="currentPassword" label={InputLabels.currentPassword} component={Input} password />
        <Field name="newPassword" label={InputLabels.newPassword} component={Input} password />
        <Button
          type="submit"
          buttonStyle="primary"
          size="medium"
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          mt="m"
          disabled={submitting}
        >
          {ButtonLabels.changePassword}
        </Button>
      </Box>
    </form>
  );
};

const validate = values => {
  const errors = {};

  const { currentPassword, newPassword } = values;

  if (!currentPassword || currentPassword === "") errors.currentPassword = InputErrors.noCurrentPassword;
  if (!newPassword || newPassword === "") errors.newPassword = InputErrors.noNewPassword;
  else if (!/^.{9,}$/.test(newPassword)) errors.newPassword = InputErrors.insecurePassword;

  return errors;
};

const ChangePasswordModal = reduxForm({
  form: "changePassword",
  validate
})(Form);

export { ChangePasswordModal };
