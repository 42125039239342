import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions";
import { Icon } from "../fundamentals";

const containerStyle = { position: "relative", width: "268px", display: "flex", justifyContent: "center" };

const infoStyle = {
  margin: "32px 48px 0px 48px",
  width: "172px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "8px",
};

const iconStyle = {
  width: "26px",
  height: "26px",
  backgroundColor: "#5E54FF",
  borderRadius: "50%",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  left: "65%",
  top: "17%",
  cursor: "pointer",
};

const tagStyle = {
  fontSize: "12px",
  lineHeight: "16px",
  color: "#2B2A2E",
  backgroundColor: "#DEE1FF",
  padding: "4px 8px",
  fontFamily: "Inter",
  fontWeight: "500",
  borderRadius: "4px",
};

const imageStyle = {
  height: "172px",
  width: "172px",
  borderRadius: "50%",
  margin: "28px 48px 0px 48px",
  backgroundColor: "#EFEFF0",
};

const headerTextStyle = {
  color: "#2B2A2E",
  fontFamily: "DM Sans",
  fontSize: "24px",
  lineHeight: "32px",
  textAlign: "center",
  width: "100%",
};
const secondaryTextStyle = { color: "#6A6870", fontFamily: "Inter", fontSize: "12px", lineHeight: "16px" };

const socialTextStyle = { color: "#5E54FF", fontFamily: "Inter", fontSize: "14px", lineHeight: "18px" };

const organisationTextStyle = { color: "#2B2A2E", fontFamily: "Inter", fontSize: "14px", lineHeight: "18px" };

const linkTextStyle = { color: "#9AA1FF", fontFamily: "Inter", fontSize: "12px", lineHeight: "16px" };

const ContactSidebar = ({ data }) => {
  const dispatch = useDispatch();

  const openImageUpload = () => {
    dispatch(openModal("imageUpload"));
  };

  const instaUsernameHandler = () => {
    if (data?.instagramUrl) {
      const username = `@${data?.instagramUrl?.split(".com/")[1]?.slice(0, -1)}`;
      return username;
    }
  };

  const addressMerger = () => {
    return `${data?.address?.address1 || ""} ${data?.address?.address2 || ""} ${data?.address?.city || ""} ${
      data?.address?.postalCode || ""
    } ${data?.address?.state || ""} ${data?.address?.country || ""}`;
  };

  return (
    <Box sx={{ position: "sticky", top: "72px" }}>
      <Box sx={containerStyle}>
        <Box component={data?.image ? "img" : "div"} sx={imageStyle} src={data?.image} />

        <Box onClick={openImageUpload} sx={iconStyle}>
          <Icon
            icon={data?.image ? "Edit2" : "Plus"}
            cursor="pointer"
            size="16px"
            strokeWidth="4"
            color="white"
            m="5px"
            pl="2px"
          />
        </Box>
      </Box>

      {data?.firstName || data?.lastName || data?.organisation ? (
        <>
          <Box sx={infoStyle}>
            <Typography sx={{ ...headerTextStyle, textAlign: "left" }}>
              {(data?.firstName || " ") + " " + (data?.lastName || " ")}
            </Typography>
            <Link sx={socialTextStyle} href={data?.instagramUrl} underline="none" target="_blank">
              {instaUsernameHandler() || ""}
            </Link>

            {data?.email && <Typography sx={socialTextStyle}>{data.email}</Typography>}
            {data?.types && (
              <Typography sx={organisationTextStyle}>{`${data?.types[0]} @ ${data?.organisation}`}</Typography>
            )}
            {data?.types?.length > 0 && (
              <Stack direction="row" flexWrap="wrap" gap={"4px"}>
                {data?.types?.map((tag) => (
                  <Typography sx={tagStyle}>{tag}</Typography>
                ))}
              </Stack>
            )}
          </Box>

          <Box sx={infoStyle}>
            {data?.phone && <Typography sx={secondaryTextStyle}>{data?.phone}</Typography>}
            {data?.address && <Typography sx={secondaryTextStyle}>{addressMerger()}</Typography>}

            {data?.websiteUrl && (
              <Link sx={linkTextStyle} href={data?.websiteUrl} underline="none" target="_blank">
                {data?.websiteUrl}
              </Link>
            )}

            {data?.vatNumber && <Typography sx={secondaryTextStyle}>VAT: {data?.vatNumber}</Typography>}
          </Box>
        </>
      ) : (
        <Box sx={infoStyle}>
          <Typography sx={headerTextStyle}>New Contact</Typography>
        </Box>
      )}
    </Box>
  );
};

export { ContactSidebar };
