import React, { useState } from "react";
import styled from "styled-components";
import css from "@styled-system/css";

import { Box, Flex, Text, Icon } from "../fundamentals";

const StyledCheckbox = styled(Box)`
  border: 3px solid;
  transform: scale(0.76);
  border-radius: ${(props) => (props.rounded ? "50%" : "7px")};
  svg {
    stroke-width: 3px;
  }
  ${(props) => props.rounded && `padding: 2px;`}
  ${(props) =>
    !props.disabled &&
    css({
      bg: props.checked ? "blue.60" : "transparent",
      borderColor: props.checked ? "blue.60" : props.hover ? "blue.60" : "grey.80",
      marginTop:'5px',
      "&:hover": {
        borderColor: "blue.60",
      },
    })}
  ${(props) =>
    props.disabled &&
    css({
      bg: "transparent",
      borderColor: "grey.40",
    })}
`;

export const Checkbox = ({
  input,
  meta,
  label,
  labelSize,
  description,
  grouped,
  itemValue,
  disabled,
  rounded,
  additionalOnChange,
  color,
  ...props
}) => {
  const [hover, setHover] = useState(false)
  function onChange() {
    if (!disabled) {
      const value = grouped || (itemValue && input.value !== itemValue) ? itemValue : !input.value;
      input.onChange(value);
      additionalOnChange && additionalOnChange(value);
    }
  }
  const checked = input.value && (!itemValue || input.value === itemValue);
  return (
    <Box onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} cursor={disabled ? "inherit" : "pointer"} onClick={onChange} {...props}>
      <Flex alignItems="center">
        <StyledCheckbox checked={checked} hover={hover} disabled={disabled} borderColor="red" rounded={rounded} mt="xxxs" mb="xxxs">
          <Icon icon="Circle" color={checked && !disabled ? "#5E54FF" : "transparent"} size="18" />
        </StyledCheckbox>
        <Box>
          <Text
            color={disabled ? "grey.60" : (checked || hover) ? "#5E54FF" : color ? color : "grey.90"}
            size={labelSize || "body"}
            ml="xxs"
            mr="s"
            userSelect="none"
            fontWeight="700"
            lineHeight="26px"
            fontFamily="DM Sans"
          >
            {label}
          </Text>
        </Box>
      </Flex>
      {description && (
        <Text
          color={disabled ? "grey.60" : (checked || hover) ? "#5E54FF" : color ? color : "grey.80"}
          size="smaller"
          mb="xxs"
          ml="35px"
          userSelect="none"
          whiteSpace="pre-wrap"
          fontWeight="400"
        >
          {description}
        </Text>
      )}
      {meta && meta.submitFailed && meta.error && (
        <Text size="small" color="red">
          {meta.error}
        </Text>
      )}
    </Box>
  );
};
