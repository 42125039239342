import React, { useEffect } from "react";
import { updateUserDetails } from "../../store/actions";
import { Headings, ButtonLabels, InputErrors } from "../../localisation";

import { Field, reduxForm } from "redux-form";
import { Text } from "../fundamentals";
import { Section, Button, Input } from "../elements";

const Form = ({ handleSubmit, submitting, pristine, ...props }) => {
  useEffect(() => {
    console.log("AccountSettingsForm MOUNTS");
    return () => {
      console.log("AccountSettingsForm UNMOUNTS");
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(updateUserDetails)}>
      <Text size="heading2" color="grey.80" mb="xxl">
        {Headings.accountDetails}
      </Text>
      <Section title="Profile Information" color="grey.80" width="460px" mb="xl">
        <Field name="name" label="Full Name" component={Input} />
        <Field name="email" label="E-mail" component={Input} />
        <Button
          type="submit"
          buttonStyle="primary"
          size="medium"
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          mt="s"
          disabled={pristine || submitting}
        >
          {ButtonLabels.updateProfile}
        </Button>
      </Section>
    </form>
  );
};

const validate = values => {
  const errors = {};

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
    errors.email = InputErrors.invalidEmail;

  return errors;
};

const AccountSettingsForm = reduxForm({
  form: "settings.account",
  enableReinitialize: true,
  validate
})(Form);

export { AccountSettingsForm };
