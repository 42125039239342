import produce from "immer";
import { SimplifyApi } from "../../utils";
import {
  signIn,
  register,
  createSubscription,
  createOnboardingSubscription,
  updateUserDetails,
  changePaymentMethod,
  updateOrganisation,
  expiredPaymentMethod,
  restartSubscription,
} from "../actions";
import {
  GET_SUBSCRIPTION_SUCCESS,
  GET_PAYMENT_METHOD_SUCCESS,
  SYNC_USER,
  SIGN_OUT_SUCCESS,
  UNSEEN_SHARE_RECORDS,
  SEEN_ALL_SHARE_RECORDS,
} from "../actions/types";

const USER_INITIAL_STATE = {};

const userReducer = (state = USER_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case signIn.SUCCESS:
        SimplifyApi.defaults.headers.common["Authorization"] = action.payload.session.simplifyAuthorizationToken;
        return { ...state, ...action.payload };
      case register.SUCCESS:
        SimplifyApi.defaults.headers.common["Authorization"] = action.payload.session.simplifyAuthorizationToken;
        return { ...state, ...action.payload };
      case createSubscription.SUCCESS:
        return createSubscriptionSuccess(draftState, action);
      case createOnboardingSubscription.SUCCESS:
        return createOnboardingSubscriptionSuccess(draftState, action);
      case restartSubscription.SUCCESS:
        return createOnboardingSubscriptionSuccess(draftState, action);
      case updateUserDetails.SUCCESS:
        return updateUserDetailsSuccess(draftState, action);
      case updateOrganisation.SUCCESS:
        return updateOrganisationSuccess(draftState, action);
      case GET_SUBSCRIPTION_SUCCESS:
        return getSubscriptionSuccess(draftState, action);
      case GET_PAYMENT_METHOD_SUCCESS:
        return getPaymentMethodSuccess(draftState, action);
      case changePaymentMethod.SUCCESS:
        return changePaymentMethodSuccess(draftState, action);
      case expiredPaymentMethod.SUCCESS:
        return changePaymentMethodSuccess(draftState, action);
      case SYNC_USER:
        return { ...state, ...action.payload.user };
      case UNSEEN_SHARE_RECORDS:
        return unseenShareRecords(draftState, action);
      case SEEN_ALL_SHARE_RECORDS:
        return seenAllShareRecords(draftState, action);
      case SIGN_OUT_SUCCESS:
        delete SimplifyApi.defaults.headers.common["Authorization"];
        return USER_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const createSubscriptionSuccess = (draftState, action) => {
  const { activePlan, accessLevel } = action.payload;
  draftState.account.activePlan = activePlan;
  draftState.account.accessLevel = accessLevel;
};

const createOnboardingSubscriptionSuccess = (draftState, action) => {
  const { activePlan, accessLevel } = action.payload;
  draftState.account.activePlan = activePlan;
  draftState.account.accessLevel = accessLevel;
};

const updateUserDetailsSuccess = (draftState, action) => {
  const { name, email } = action.payload;
  draftState.name = name;
  draftState.email = email;
};

const updateOrganisationSuccess = (draftState, action) => {
  draftState.organisation = action.payload;
};

const getSubscriptionSuccess = (draftState, action) => {
  const { id, created, trial_end, current_period_end, cancel_at_period_end } = action.payload;
  draftState.account.subscription = {
    subscriptionId: id,
    created,
    trialEnd: trial_end,
    currentPeriodEnd: current_period_end,
    cancelAtPeriodEnd: cancel_at_period_end,
  };
};

const getPaymentMethodSuccess = (draftState, action) => {
  const { card: { last4, exp_month, exp_year } = {} } = action.payload;
  if (last4 && exp_month && exp_year) {
    draftState.account.card = { lastFourNumbers: last4, expMonth: exp_month, expYear: exp_year };
  } else {
    delete draftState.account.card;
  }
};

const changePaymentMethodSuccess = (draftState, action) => {
  const {
    card: { last4, exp_month, exp_year },
  } = action.payload;
  draftState.account.paymentStatus = "";
  draftState.account.card = { lastFourNumbers: last4, expMonth: exp_month, expYear: exp_year };
};

const unseenShareRecords = (draftState, action) => {
  draftState.session.unseenShareRecords = true;
};

const seenAllShareRecords = (draftState, action) => {
  draftState.session.unseenShareRecords = false;
};

export default userReducer;
