import _ from "lodash";
import { all, put, takeEvery } from "redux-saga/effects";
import { purgeStoredState } from "redux-persist";
import { PERSIST_CONFIG } from "../storeSettings";
import { RESET_APP } from "../actions/types";
import { resetAppSuccess, resetAppFailure, signOut, clearUnactiveCacheImages } from "../actions";

function* resetAppSaga() {
  try {
    yield all([put(signOut()), put(clearUnactiveCacheImages())]);

    purgeStoredState(PERSIST_CONFIG);

    window.location.reload();

    yield put(resetAppSuccess());
  } catch (error) {
    yield put(resetAppFailure());
  }
}

function* appSaga() {
  yield takeEvery(RESET_APP, resetAppSaga);
}

export default appSaga;
