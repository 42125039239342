import produce from "immer";
import { CACHE_IMAGE, RECACHE_IMAGE, CACHE_IMAGE_ACTIVE, REMOVE_CACHE_IMAGE } from "../actions/types";

const SESSION_INITIAL_STATE = {
  cachedImageUrls: {},
  uploads: {}
};

const sessionReducer = (state = SESSION_INITIAL_STATE, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case CACHE_IMAGE:
        return cacheImage(draftState, action);
      case RECACHE_IMAGE:
        return recacheImage(draftState, action);
      case CACHE_IMAGE_ACTIVE:
        return cacheImageActive(draftState, action);
      case REMOVE_CACHE_IMAGE:
        return removeCacheImage(draftState, action);
      default:
        return state;
    }
  });
};

const cacheImage = (draftState, action) => {
  const { localId, url } = action.payload;
  draftState.cachedImageUrls[localId] = { localId, url, isActive: false };
};

const recacheImage = (draftState, action) => {
  const { localId, url } = action.payload;

  draftState.cachedImageUrls[localId].url = url;
};

const cacheImageActive = (draftState, action) => {
  const { localId } = action.payload;

  if (draftState.cachedImageUrls[localId]) draftState.cachedImageUrls[localId].isActive = true;
};

const removeCacheImage = (draftState, action) => {
  const { localId } = action.payload;
  delete draftState.cachedImageUrls[localId];
};

export default sessionReducer;
