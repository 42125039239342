import produce from "immer";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import {
  DOWNLOAD_ARCHIVE,
  CREATE_ARTWORK_LOCALLY,
  CREATE_ARTWORK_SUCCESS,
  EDIT_ARTWORK_LOCALLY,
  EDIT_ARTWORK_SUCCESS,
  DELETE_ARTWORKS,
  DELETE_ARTWORK_SUCCESS,
  SAVE_SHARED_ARTWORK_LOCALLY,
  UPLOAD_ARTWORK_IMAGE_SUCCESS,
  SYNC_ARCHIVE,
  SIGN_OUT_SUCCESS
} from "../actions/types";
import { shareArtwork } from "../actions";

const ARCHIVE_INITIAL_STATE = {
  collections: { byId: {} },
  artworks: { byId: {} },
  artists: { byId: {} },
  shareRecords: { byId: {} }
};

const archiveReducer = (state = ARCHIVE_INITIAL_STATE, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case DOWNLOAD_ARCHIVE:
        return downloadArchive(draftState, action);
      case CREATE_ARTWORK_LOCALLY:
        return createArtworkLocally(draftState, action);
      case CREATE_ARTWORK_SUCCESS:
        return createArtworkSuccess(draftState, action);
      case EDIT_ARTWORK_LOCALLY:
        return editArtworkLocally(draftState, action);
      case EDIT_ARTWORK_SUCCESS:
        return editArtworkSuccess(draftState, action);
      case DELETE_ARTWORKS:
        return deleteArtworks(draftState, action);
      case DELETE_ARTWORK_SUCCESS:
        return deleteArtworkSuccess(draftState, action);
      case shareArtwork.SUCCESS:
        return shareArtworkSuccess(draftState, action);
      case SAVE_SHARED_ARTWORK_LOCALLY:
        return saveSharedArtworkLocally(draftState, action);
      case UPLOAD_ARTWORK_IMAGE_SUCCESS:
        return uploadArtworkImageSuccess(draftState, action);
      case SYNC_ARCHIVE:
        return syncArchive(state, draftState, action);
      case SIGN_OUT_SUCCESS:
        return ARCHIVE_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const downloadArchive = (draftState, action) => {
  const { collections, artists, artworks, shareRecords } = action.payload;

  collections.forEach(collection => {
    draftState.collections.byId[collection.id] = collection;
  });

  artists.forEach(artist => {
    draftState.artists.byId[artist.id] = artist;
  });

  artworks.forEach(({ images, artist, ...artwork }) => {
    if (artwork.isDeleted && !artwork.activeShareSource) {
      return;
    }

    const imageList = images.map(image => {
      return { key: uuid(), ...image, isFromServer: true, isModified: false, isActive: true };
    });

    draftState.artworks.byId[artwork.id] = {
      ...artwork,
      artist: artist.name,
      images: _.sortBy(imageList, ["sortIndex"]),
      isFromServer: true,
      isModified: false,
      isActive: true
    };
  });

  shareRecords.forEach(shareRecord => {
    draftState.shareRecords.byId[shareRecord.id] = shareRecord;
  });
};

const createArtworkLocally = (draftState, action) => {
  const {
    tempArtworkId,
    collection: { id },
    ...restOfArtwork
  } = action.payload;
  draftState.artworks.byId[tempArtworkId] = {
    id: tempArtworkId,
    artworkCollection: id,
    ...restOfArtwork,
    isFromServer: false,
    isModified: false,
    isActive: true
  };
};

const createArtworkSuccess = (draftState, action) => {
  const { artworkId, tempArtworkId, collectionId, artworkFamilyId, archiveArtworkNumber, artistId } = action.payload;

  draftState.artworks.byId[artworkId] = { ...draftState.artworks.byId[tempArtworkId] };
  draftState.artworks.byId[artworkId].id = artworkId;
  draftState.artworks.byId[artworkId].artworkFamily = artworkFamilyId;
  draftState.artworks.byId[artworkId].archiveArtworkNumber = archiveArtworkNumber;
  draftState.artworks.byId[artworkId].artistId = artistId;
  draftState.artworks.byId[artworkId].artworkCollection = collectionId;
  draftState.artworks.byId[artworkId].isFromServer = true;
  delete draftState.artworks.byId[tempArtworkId];
};

const editArtworkLocally = (draftState, action) => {
  const { artworkValues: oldArtworkValues, ...oldArtworkData } = draftState.artworks.byId[action.payload.id];
  const { artworkValues: newArtworkValues, ...newArtworkData } = action.payload;

  draftState.artworks.byId[action.payload.id] = {
    artworkValues: { ...oldArtworkValues, ...newArtworkValues },
    ...oldArtworkData,
    ...newArtworkData,
    isModified: true
  };
};

const editArtworkSuccess = (draftState, action) => {
  const { artworkId } = action.payload;

  draftState.artworks.byId[artworkId].isModified = false;
};

const deleteArtworks = (draftState, action) => {
  const { artworkIds } = action.payload;

  artworkIds.forEach(artworkId => {
    draftState.artworks.byId[artworkId].isActive = false;
  });
};

const deleteArtworkSuccess = (draftState, action) => {
  if (!draftState.artworks.byId[action.payload.artworkId].activeShareSource) {
    delete draftState.artworks.byId[action.payload.artworkId];
  }
};

const shareArtworkSuccess = (draftState, action) => {
  const { shareRecord } = action.payload;

  draftState.shareRecords.byId[shareRecord.id] = shareRecord;
};

const saveSharedArtworkLocally = (draftState, action) => {
  const { collectionId, artworkId } = action.payload;

  draftState.artworks.byId[artworkId].artworkCollection = collectionId;
  draftState.artworks.byId[artworkId].isModified = true;
  draftState.artworks.byId[artworkId].isActive = true;
  draftState.artworks.byId[artworkId].isDeleted = false;
};

const uploadArtworkImageSuccess = (draftState, action) => {
  const { artworkId, connectionId, imageToConnect } = action.payload;

  const index = draftState.artworks.byId[artworkId].images.findIndex(image => image.connectionId === connectionId);

  if (index !== -1) {
    draftState.artworks.byId[artworkId].images[index].key = uuid();
    draftState.artworks.byId[artworkId].images[index].url = imageToConnect.url;
    draftState.artworks.byId[artworkId].images[index].description = imageToConnect.description;
    draftState.artworks.byId[artworkId].images[index].sortIndex = imageToConnect.sortIndex;
    draftState.artworks.byId[artworkId].images[index].isFromServer = true;
  }
};

const syncArchive = (state, draftState, action) => {
  const { artworks, artists, recievedRecords, sentRecords } = action.payload;

  artists.forEach(artist => {
    draftState.artists.byId[artist.id] = artist;
  });

  artworks.forEach(({ images: imageListFromServer, artist, ...artwork }) => {
    if (artwork.isDeleted && !artwork.activeShareSource) {
      delete draftState.artworks.byId[artwork.id];
      return;
    }

    const imageList = _.map(imageListFromServer, serverImage => {
      let tempImage;

      if (state.artworks.byId[artwork.id]) {
        state.artworks.byId[artwork.id].images.forEach(localImage => {
          if (serverImage.connectionId === localImage.connectionId) {
            tempImage = { ...localImage, ...serverImage, isFromServer: true, isModified: false, isActive: true };
          }
        });
      }

      if (!tempImage) {
        tempImage = { key: uuid(), ...serverImage, isFromServer: true, isModified: false, isActive: true };
      }

      return tempImage;
    });

    draftState.artworks.byId[artwork.id] = {
      ...artwork,
      artist: artist.name,
      images: _.sortBy(imageList, ["sortIndex"]),
      isFromServer: true,
      isModified: false,
      isActive: true
    };
  });

  recievedRecords.forEach(recievedRecord => {
    draftState.shareRecords.byId[recievedRecord.id] = recievedRecord;
  });

  sentRecords.forEach(sentRecord => {
    draftState.shareRecords.byId[sentRecord.id] = sentRecord;
  });
};

export default archiveReducer;
