import React, { useRef } from "react";
import { Stack } from "@mui/material";
import { reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { ContactGeneralFormSection } from "./ContactGeneralFormSection";
import { ContactAdditionalFormSection } from "./ContactAdditionalFormSection";
import { FloatingActionPill } from "../FloatingActionPill";
import { Modal } from "../../elements";
import { DeleteModal } from "../../modals";
import { DynamicLabels, Warnings, InputErrors } from "../../../localisation";
import { clearAllSelectedContacts, deleteContacts } from "../../../store/actions";

const Form = ({
  selectedContactIds,
  handleSubmit,
  form,
  dirty,
  pristine,
  submitting,
  reset,
  renderFloatingActionPillButtons,
}) => {
  const dispatch = useDispatch();
  const fixedPillRef = useRef(null);

  const deleteSelectedContacts = () => {
    dispatch(deleteContacts(selectedContactIds));
    dispatch(clearAllSelectedContacts());
  };

  return (
    <>
      <Stack sx={{ width: "100%", alignItems: "center", paddingBottom: "100px" }}>
        <form onSubmit={handleSubmit}>
          <ContactGeneralFormSection form={form} reset={reset} />
          <ContactAdditionalFormSection form={form} reset={reset} />

          {renderFloatingActionPillButtons && (
            <FloatingActionPill ref={fixedPillRef}>
              {renderFloatingActionPillButtons(dispatch, form)}
            </FloatingActionPill>
          )}
        </form>
      </Stack>

      <Modal id="delete_contact">
        <DeleteModal
          headerText={DynamicLabels.popupHeaderForDelete("contact")}
          bodyText={Warnings.popupWarning}
          onDelete={deleteSelectedContacts}
        />
      </Modal>
    </>
  );
};

const validate = (values = {}, ownProps) => {
  const errors = { ContactAdditionalFormSection: {} };

  const {
    ContactGeneralFormSection: { firstName, lastName, organisation } = {},
    ContactAdditionalFormSection: { websiteUrl, instagramUrl } = {},
  } = values;

  if (!firstName && !lastName && !organisation) {
    errors.ContactGeneralFormSection = {
      firstName: InputErrors.noContactGeneral,
      lastName: InputErrors.noContactGeneral,
      organisation: InputErrors.noContactGeneral,
    };
  }

  if (websiteUrl) {
    if (!websiteUrl.startsWith("https://") && !websiteUrl.startsWith("http://") && !websiteUrl.startsWith("www.")) {
      errors.ContactAdditionalFormSection.websiteUrl = InputErrors.wrongUrl;
    }
  }

  if (instagramUrl) {
    if (!instagramUrl.startsWith("https://") && !instagramUrl.startsWith("http://")) {
      errors.ContactAdditionalFormSection.instagramUrl = InputErrors.wrongUrl;
    }
  }

  return errors;
};

const ContactForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(Form);

export { ContactForm };
