import _ from "lodash";
import { put, select, takeEvery } from "redux-saga/effects";
import { EXECUTE_JOYRIDE } from "../actions/types";
import { executeTutorial } from "../actions";

function* executeJoyrideSaga(action) {
  const { name } = action.payload;

  try {
    const tutorial = yield select(state => state.tutorial);
    const { executing, joyrides } = tutorial;

    if (!executing && (!joyrides.hasOwnProperty(name) || joyrides[name])) {
      yield put(executeTutorial(name));
    }
  } catch (error) {
    console.log(error);
  }
}

function* tutorialSaga() {
  yield takeEvery(EXECUTE_JOYRIDE, executeJoyrideSaga);
}

export default tutorialSaga;
