import React from "react";
import { Box, Flex, Text, Separator, Icon } from "../fundamentals";
import { useSelector } from "react-redux";

const Footer = () => {
  const isOnline = useSelector(state => state.sync.isOnline);

  return (
    <Box position="sticky" bottom="-4px" zIndex="1">
      {!isOnline && (
        <Flex justifyContent="center" alignItems="center" bg="grey.90" height="24px" width="100%">
          <Icon icon="CloudOff" size="18" color="white" mr="xxs" />
          <Text size="body" color="white" fontFamily="body" whiteSpace="nowrap">
            No Internet connection. All changes are saved and synced later.
          </Text>
        </Flex>
      )}
      <Separator bg="blue.60" height="4px" mb="none" mt="none" />
    </Box>
  );
};

export { Footer };
