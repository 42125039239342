import { Box } from "@mui/material";
import React from "react";

const boxStyle = {
  transition: "background-color 0.25s",
  backgroundColor: "#5E54FF",
  borderRadius: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px 32px",
  gap: "40px",
  boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.14)",
  position: "fixed",
  zIndex: "100",
  bottom: "24px",
  color: "white",
};

const containerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
};

export const FloatingActionPill = React.forwardRef(({ children, inverted = false, ...props }, ref) => {
  return (
    <Box sx={containerStyle}>
      <Box sx={boxStyle}>{children.map((child) => child)}</Box>
    </Box>
  );
});
