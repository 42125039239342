import React from "react";
import _ from "lodash";
import { ButtonLabels, InputLabels, Headings, DynamicLabels } from "../../localisation";

import { Field, FieldArray } from "redux-form";
import { Box, Flex, Text, Icon } from "../fundamentals";
import { Button, Input, InputSelect } from "../elements";

const columnOptions = [
  {
    name: "dimensions.height",
    options: { placeholder: "H" },
    component: Input,
    label: "Dimensions",
    width: "56px",
    hideError: true
  },
  {
    name: "dimensions.width",
    options: { placeholder: "W" },
    component: Input,
    width: "56px",
    hideError: true,
    ml: "-8px"
  },
  {
    name: "dimensions.depth",
    options: { placeholder: "D" },
    component: Input,
    width: "56px",
    hideError: true,
    ml: "-8px"
  },

  { name: "packagingType", component: Input, label: InputLabels.packagingType, hideError: true },
  {
    name: "openingOrder",
    label: InputLabels.openingOrder,
    pb: "none",
    placeholder: "",
    width: "64px",
    component: InputSelect
  }
];

const CrateColumn = ({ crate, crates, column: { name, label, ...props }, disabled }) => {
  return (
    <Box paddingTop="24px" position="relative">
      <Text
        top="0"
        position="absolute"
        minHeight={label ? "16px" : undefined}
        fontSize="smaller"
        lineHeight="button"
        fontWeight="500"
        color="black"
        mb="xxs"
        whiteSpace="nowrap"
      >
        {label}
      </Text>

      {name === "openingOrder" ? (
        <Field
          name={`${crate}.${name}`}
          disabled={disabled}
          options={crates.map((_, index) => ({ value: index + 1, label: index + 1 }))}
          onChange={(event, newValue, previousValue) => {
            if (previousValue !== newValue) {
              crates.forEach((crate, crateIndex) => {
                const fieldValues = Object.assign({}, crates.get(crateIndex));
                if (fieldValues.openingOrder < previousValue && fieldValues.openingOrder >= newValue) {
                  fieldValues.openingOrder = fieldValues.openingOrder + 1;
                  crates.splice(crateIndex, 1, fieldValues);
                } else if (fieldValues.openingOrder > previousValue && fieldValues.openingOrder <= newValue) {
                  fieldValues.openingOrder = fieldValues.openingOrder - 1;
                  crates.splice(crateIndex, 1, fieldValues);
                }
              });
            }
          }}
          {...props}
        />
      ) : (
        <Field name={`${crate}.${name}`} disabled={disabled} {...props} />
      )}
    </Box>
  );
};

const onRemoveRow = (index, fields) => {
  const removedField = fields.get(index);
  fields.forEach((field, fieldIndex) => {
    const fieldValues = Object.assign({}, fields.get(fieldIndex));
    if (removedField.openingOrder < fieldValues.openingOrder) {
      fieldValues.openingOrder = fieldValues.openingOrder - 1;
      fields.splice(fieldIndex, 1, fieldValues);
    }
  });
};

const renderCrates = ({ fields, meta: { error, submitFailed }, sortBy, disabled }) => {
  const orderedFields = _.orderBy(
    fields.map((crate, crateIndex) => {
      return { crate, crateIndex, rowValues: fields.get(crateIndex) };
    }),
    `rowValues.${sortBy}`
  );

  return (
    <Box>
      {orderedFields.map(({ crate, crateIndex }) => (
        <Flex key={crateIndex} justifyContent="space-between" mb="xxs">
          {columnOptions.map((column, columnIndex) => (
            <CrateColumn key={columnIndex} crate={crate} crates={fields} column={column} disabled={disabled} />
          ))}

          <Button
            buttonStyle="text"
            size="medium"
            color="black"
            icon="X"
            alignSelf="center"
            mt="s"
            pr="none"
            pl="none"
            disabled={disabled}
            onClick={() => {
              onRemoveRow(crateIndex, fields);
              fields.remove(crateIndex);
            }}
          />
        </Flex>
      ))}

      {!disabled && (
        <Button
          buttonStyle="text"
          size="small"
          pl="none"
          icon="Plus"
          preventDefault
          onClick={() => fields.push({ openingOrder: fields.length + 1 })}
        >
          {ButtonLabels.addCrates}
        </Button>
      )}
    </Box>
  );
};

const CrateFields = ({ groupName, sortBy, disabled, isMultiEditMode, numArtworks }) => {
  return (
    <Box mb="m">
      <Text size="small" color="grey.80" mb="s">
        {Headings.crates}
      </Text>
      {isMultiEditMode && (
        <Flex alignItems="center" color={"amber.90"} overflow="hidden" height={24} mb="xxs">
          <Icon icon="Info" size="14" mr="xxxs" />
          <Text fontSize="smaller" lineHeight="button">
            {DynamicLabels.multiPackagingEditWarning(numArtworks)}
          </Text>
        </Flex>
      )}

      <FieldArray
        disabled={disabled}
        rerenderOnEveryChange={true}
        name={groupName}
        sortBy={sortBy}
        component={renderCrates}
      />
    </Box>
  );
};

export { CrateFields };
