import React, { useRef, useCallback, useLayoutEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

const WithItemMeasure = WrappedComponent => {
  const WithItemMeasureComponent = ({ measure, ...restProps }) => {
    const roRef = useRef(null);
    const elRef = useRef(null);

    const measureRef = useRef(measure);
    measureRef.current = measure;

    const refSetter = useCallback(el => {
      const ro = roRef.current;

      if (ro !== null && elRef.current !== null) {
        ro.unobserve(elRef.current);
      }

      elRef.current = el;

      if (ro !== null && elRef.current !== null) {
        ro.observe(elRef.current);
      }
    }, []);

    useLayoutEffect(() => {
      const update = () => {
        measureRef.current(elRef.current);
      };

      // sync measure for initial render ?
      update();

      const ro = roRef.current ? roRef.current : new ResizeObserver(update);

      const el = elRef.current;
      if (el !== null) {
        ro.observe(el);
      }
      roRef.current = ro;

      return () => {
        ro.disconnect();
      };
    }, []);

    return <WrappedComponent ref={refSetter} {...restProps} />;
  };

  return WithItemMeasureComponent;
};

export { WithItemMeasure };
