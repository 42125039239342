import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import css from "@styled-system/css";

import { Box, Flex, Icon, Text } from "../fundamentals";

const StyledNotificationContainer = styled(Box)`
  transition: ${({ transition }) => transition};
  padding: 5px;
  overflow: hidden;
`;

const StyledNotification = styled(Flex)`
  width: 368px;
  align-items: center;
  transition: ${({ transitionDuration }) =>
    `transform ${transitionDuration}ms cubic-bezier(0.2, 0, 0, 1), opacity ${transitionDuration}ms`};
  ${props =>
    css({
      bg: "white",
      p: "m",
      pt: "s",
      pb: "s",
      mr: "s",
      // mt: "xxs",
      boxShadow: "medium"
    })};
  ${({ transition }) => transition}
  overflow: hidden;
`;

const StyledNotificationIcon = styled(Icon)`
  ${variant({
    prop: "type",
    variants: {
      success: {
        bg: "green",
        color: "white",
        borderRadius: "50%",
        padding: "xxxs",
        transform: "scale(0.75) translateX(-4px)"
      },
      error: {
        color: "red"
      },
      warning: {
        color: "amber.60"
      },
      info: {
        color: "blue.60"
      }
    }
  })}
`;

export const Notification = ({
  children: { type, title, text, secondaryText, closeButton },
  onDismiss,
  onMouseEnter,
  onMouseLeave,
  transitionState,
  transitionDuration,
  placement,
  textAlign = "justify",
  ...props
}) => {
  const [height, setHeight] = useState("auto");
  const elementRef = useRef();
  useEffect(() => {
    if (transitionState === "entered") setHeight(elementRef.current.offsetHeight + 8);
    if (transitionState === "exiting") setHeight(0);
  }, [transitionState]);
  const notificationStates = {
    entering: { transform: "translate3d(120%,0,0)" },
    entered: { transform: "translate3d(0,0,0)" },
    exiting: { transform: "scale(0.66)", opacity: 0 },
    exited: { transform: "scale(0.66)", opacity: 0 }
  };
  const icons = { success: "Check", error: "AlertOctagon", warning: "AlertTriangle", info: "AlertCircle" };
  return (
    <StyledNotificationContainer
      ref={elementRef}
      height={height}
      transition={`height ${transitionDuration - 100}ms 100ms`}
      {...props}
    >
      <StyledNotification
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        transitionDuration={transitionDuration}
        transition={notificationStates[transitionState]}
      >
        <Flex height="20px" alignItems="center" mr="s">
          <StyledNotificationIcon icon={icons[type]} type={type} />
        </Flex>
        <Box color="black" width="280px" fontFamily="body">
          {title && <Text size="body">{title}</Text>}
          <Text size="small" textAlign={textAlign}>
            {text}
          </Text>
          {secondaryText && <Text size="small">{secondaryText}</Text>}
        </Box>
        {closeButton && <Icon icon="X" size="19" color="grey.80" ml="xxs" onClick={onDismiss} alignSelf="flex-start" />}
      </StyledNotification>
    </StyledNotificationContainer>
  );
};
