import React, { useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions";
import { DynamicLabels, InputLabels } from "../../localisation";

import { Box, Flex, ImageWithCache, Text } from "../fundamentals";
import { Button } from "../elements";

const ArtworkSidebar = ({ artworks, artworkPreviewNum, isMultiEditMode, ...props }) => {
  const [showAllArtworks, setShowAllArtworks] = useState(false);
  const dispatch = useDispatch();

  const renderSidebarArtwork = (artwork, multiple, index) => {
    const { id, artist, images, artworkValues = {} } = artwork;
    const [image = {}] = _.sortBy(images, "sortIndex");

    if (multiple) {
      if (index < artworkPreviewNum || showAllArtworks)
        return (
          <Flex key={id} pb="m">
            <ImageWithCache
              image={image}
              urlSuffix="/preview.jpg"
              height="75px"
              maxWidth="100px"
              onClick={() => {
                if (!isMultiEditMode) dispatch(openModal("image_viewer"));
              }}
            />

            <Flex flexDirection="column" justifyContent="space-evenly" textAlign="left" minWidth="80px" ml="m">
              <Text size="body" color="black">
                {artist}
              </Text>
              <Text size="body" color="black">
                {artworkValues.title}
              </Text>
              <Text size="body" color="grey.70">
                {artworkValues.year}
              </Text>
            </Flex>
          </Flex>
        );
      return null;
    }

    return (
      <Box key={id}>
        <ImageWithCache
          image={image}
          urlSuffix="/preview.jpg"
          maxHeight="210px"
          maxWidth="100%"
          onClick={() => {
            if (!isMultiEditMode) dispatch(openModal("image_viewer"));
          }}
        />

        <Box pt="l" textAlign="left">
          <Text size="body" color="black">
            {artist}
          </Text>
          <Text size="body" color="black">
            {artworkValues.title}
          </Text>
          <Text size="body" color="grey.70">
            {artworkValues.year}
          </Text>
          <Box fontSize="15px" color="black" mt="l">
            {artworkValues.dimensions && artworkValues.dimensions.height && (
              <Flex>
                <Text label="height" mr="m">
                  {`${artworkValues.dimensions.height} cm`}
                </Text>
                <Text label="width" mr="m">
                  {`${artworkValues.dimensions.width} cm`}
                </Text>
                {artworkValues.dimensions.depth && <Text label="depth">{`${artworkValues.dimensions.depth} cm`}</Text>}
              </Flex>
            )}
            {artworkValues.dimensions && artworkValues.dimensions.variable && (
              <Text label={InputLabels.dimensions}>{InputLabels.dimensionsVariable}</Text>
            )}
            {artworkValues.technique && (
              <Text label={InputLabels.technique} mt="m">
                {artworkValues.technique}
              </Text>
            )}
            {artworkValues.price && (
              <Text label={InputLabels.price} mt="m">
                {`${artworkValues.price.currency} ${artworkValues.price.value}`}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box maxWidth="377px" width="100%" minHeight="100%" bg="grey.0" textAlign="center" {...props}>
      <Box position="sticky" top="72px" m="xxl" mb="l" mt="xl">
        {_.map(artworks, (artwork, index) => renderSidebarArtwork(artwork, artworks.length > 1, index))}

        {artworks.length > artworkPreviewNum && (
          <Button
            buttonStyle="text"
            size="small"
            color="grey.80"
            icon={showAllArtworks ? "ChevronUp" : "ChevronDown"}
            onClick={() => setShowAllArtworks(!showAllArtworks)}
            textAlign="left"
            pl="none"
            mt="xxxs"
          >
            {showAllArtworks
              ? DynamicLabels.collapseToArtworks(artworks.length)
              : DynamicLabels.showAllArtworks(artworks.length)}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export { ArtworkSidebar };
