import _ from "lodash";
import isOnline from "is-online";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas/index";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";
import { SimplifyApi } from "../utils";
import { WEBAPP_VERSION } from "../config";
import { PERSIST_CONFIG } from "./storeSettings";
import { changelog } from "../../changelog.js";
import createRootReducer from "./reducers";
import {
  initialiseQueueSystem,
  initialiseQueueSystemCleaner,
  initialiseSyncSystem,
  changeSelectedCollection,
  changeConnectionStatus,
  startBackgroundSync,
  startQueueSystemCleaner,
  openModal,
  tutorialCompleted,
  checkForRemoteAlerts,
} from "./actions";
import LogRocket from "logrocket";
import { AddUserAnalytics, InitialiseAnalytics, InitialiseLogRocket } from "../analytics";
import ReactPixel from "react-facebook-pixel";
import Modal from "react-modal";

export const history = createBrowserHistory();

export const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, LogRocket.reduxMiddleware(), routerMiddleware(history)];
  const rootReducer = createRootReducer(history);
  const persistRootReducer = persistReducer(PERSIST_CONFIG, rootReducer);
  const store = createStore(persistRootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  return store;
};

export const persistStoreState = (store) => {
  let persistor = persistStore(store, null, () => initialiseApplication(store));
  return persistor;
};

export const initialiseApplication = async (store) => {
  const {
    user,
    app: { version },
    archive: { collections },
  } = store.getState();

  console.log("initialiseApplication");

  store.dispatch(tutorialCompleted());
  store.dispatch(initialiseQueueSystem());
  store.dispatch(initialiseQueueSystemCleaner());
  store.dispatch(initialiseSyncSystem());

  const isConnected = await isOnline();

  store.dispatch(changeConnectionStatus(isConnected));

  InitialiseLogRocket();
  InitialiseAnalytics();
  AddUserAnalytics(user);

  if (process.env.REACT_APP_ENV === "production") {
    ReactPixel.init(
      "359401282794385",
      {},
      {
        autoConfig: true,
        debug: false,
      }
    );
    ReactPixel.pageView(); // For tracking page view
  }

  const savedVersionSplit = version.split(".");
  const configVersionSplit = WEBAPP_VERSION.split(".");

  if (
    (savedVersionSplit[0] !== configVersionSplit[0] || savedVersionSplit[1] !== configVersionSplit[1]) &&
    changelog[`${configVersionSplit[0]}.${configVersionSplit[1]}`]
  ) {
    store.dispatch(openModal("feature_changelog"));
  }

  if (user.session) {
    SimplifyApi.defaults.headers.common["Authorization"] = user.session.simplifyAuthorizationToken;

    if (user.role) {
      store.dispatch(startBackgroundSync());
      store.dispatch(startQueueSystemCleaner());

      store.dispatch(checkForRemoteAlerts());

      let initialCollection;

      if (user.role === "artist") {
        initialCollection = _.find(collections.byId, (collection) => collection.type === "production");
      } else {
        initialCollection = _.find(collections.byId, (collection) => collection.type === "collection");
      }

      store.dispatch(changeSelectedCollection(initialCollection.id));
    }
  }

  Modal.setAppElement("#root");
};
