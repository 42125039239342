import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { openModal, deleteUserAccount } from "../../store/actions";
import { selectUser } from "../../store/selectors";
import { ButtonLabels, Warnings } from "../../localisation";

import { AccountSettingsForm } from "../forms";
import { Box, Text, Separator } from "../fundamentals";
import { Section, Button } from "../elements";

const AccountSettingsScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const initialValues = {
    name: user.name,
    email: user.email
  };

  return (
    <Box flex="1">
      <AccountSettingsForm initialValues={initialValues} />

      <Separator mb="xxl" />

      <Section title="Security" color="grey.80">
        <Button buttonStyle="secondary" size="medium" mt="xs" onClick={() => dispatch(openModal("change_password"))}>
          {ButtonLabels.changePassword}
        </Button>
      </Section>

      <Separator mb="xxl" />

      <Section title="Delete Account" color="grey.80" mb="none">
        <Text color="black" size="body">
          {Warnings.deleteAccount}
        </Text>
        <Button
          buttonStyle="text"
          size="medium"
          color="red"
          hoverColor="#ff6666"
          mt="xs"
          pl="none"
          onClick={() => dispatch(deleteUserAccount())}
        >
          {ButtonLabels.deleteAccount}
        </Button>
      </Section>
    </Box>
  );
};

export default AccountSettingsScreen;
