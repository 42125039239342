import * as firebase from "firebase/app";
import "firebase/analytics";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { UserAttributes, Events, Properties } from "./types";

const FIREBASE_CONFIG = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

export const InitialiseAnalytics = () => {
  firebase.initializeApp(FIREBASE_CONFIG);
  window.gtag("config", FIREBASE_CONFIG.measurementId, { send_page_view: false });
  firebase.analytics();
  RecordScreenAnalytics(window.location.pathname);
};

export const InitialiseLogRocket = () => {
  if (process.env.NODE_ENV === "production") {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_PROJECT);
    setupLogRocketReact(LogRocket);
  }
};

export const RecordEventAnalytics = (event, properties = {}) => {
  firebase.analytics().logEvent(event, properties);
};

export const RecordExceptionAnalytics = (action) => {
  firebase.analytics().logEvent(Events.ACTION_EXCEPTION, { [Properties.TYPE]: action });
};

export const RecordScreenAnalytics = (path) => {
  window.gtag("config", FIREBASE_CONFIG.measurementId, {
    page_location: path,
  });
};

export const AddUserAnalytics = ({ id, role = null, account, session }) => {
  firebase.analytics().setUserId(id);
  firebase.analytics().setUserProperties({
    [UserAttributes.USER_ID]: id,
  });
  if (role && session.firstLogin) {
    RecordEventAnalytics(Events.REGISTER, { role });
  } else {
    firebase.analytics().setUserProperties({
      [UserAttributes.SUBSCRIPTION]: account ? account.accessLevel : null,
      [UserAttributes.ROLE]: role,
    });
    RecordEventAnalytics(Events.LOGIN);
  }
};

export const ClearAnalyticsUser = () => {
  firebase.analytics().setUserId(null);
  firebase.analytics().setUserProperties({
    [UserAttributes.USER_ID]: null,
    [UserAttributes.SUBSCRIPTION]: null,
    [UserAttributes.ROLE]: null,
  });
  RecordEventAnalytics(Events.LOGOUT);
};
