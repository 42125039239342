import React, { useState } from "react";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FormLabel } from "@mui/material";

const StyledSelect = styled(Select)(() => ({
  "&.MuiFilledInput-root": {
    background: "#F4F4F5",

    "&:hover": {
      background: "#E8E7EA",
    },
    "&:focus": {
      background: "#F4F4F5",
    },
    "&:before": {
      borderBottom: "2px solid #6A6870",
    },
    "&:after": {
      borderBottomColor: "#5E54FF",
    },
  },

  "& .MuiSelect-filled": {
    paddingTop: "8px",
    paddingBottom: "8px",
    background: "#F4F4F5",
    "&:hover": {
      background: "#E8E7EA",
    },
    "&:focus": {
      background: "#F4F4F5",
    },
  },
}));

const labelStyle = {
  fontSize: "14px",
  lineHeight: "18px",
  fontFamily: "Inter",
  color: "black !important",
  paddingBottom: "3px",
  "& .MuiFormLabel-asterisk": {
    color: "#5E54FF",
  },
};

const selectedLabelStyle = {
  fontFamily: "Inter",
  color: "#2B2A2E",
  fontSize: "16px",
  lineHeight: "24px",
};

const InputSelect = ({ input, meta: { touched, error }, options, disabled, label, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleOnOpen = () => {
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleOnChange = (event) => {
    input.onChange(event.target.value);
  };

  const getOptionLabel = (selectedValue) => {
    return _.find(options, (option) => option.value === selectedValue)?.label;
  };

  return (
    <FormControl variant="filled" error={touched && error} disabled={disabled}>
      {label && (
        <FormLabel sx={labelStyle} required={true}>
          {label}
        </FormLabel>
      )}

      <StyledSelect
        value={input.value}
        onChange={handleOnChange}
        open={open}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        IconComponent={(props) => <KeyboardArrowUpIcon {...props} color={"#2B2A2E"} />}
        renderValue={(selected) => (
          <MenuItem sx={{ paddingLeft: "8px" }}>
            <Typography sx={selectedLabelStyle}>{getOptionLabel(selected)}</Typography>
          </MenuItem>
        )}
        {...props}
      >
        {_.map(options, (option, key) => (
          <MenuItem
            key={key}
            value={option.value}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              background: "#FFFFFF",
              "&:hover": { background: "#F3F4FF" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                color: option.value === input.value ? "#5E54FF" : "#2B2A2E",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              {option?.label}
            </Typography>
            {option.value === input.value && <CheckIcon sx={{ color: "#5E54FF" }} />}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export { InputSelect };
