import produce from "immer";
import {
  UPDATE_SEARCH_TERM,
  UPDATE_SORT_BY_OPTION,
  SIGN_OUT_SUCCESS,
  UPDATE_SEARCH_TERM_CONTACT,
} from "../actions/types";
import { SORT_OPTIONS } from "../../config";

const SEARCH_INITIAL_STATE = {
  searchTerm: "",
  searchTermContact: "",
  sortBy: "artist",
  sortOrder: SORT_OPTIONS["artist"].sortOrder,
};

const searchReducer = (state = SEARCH_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case UPDATE_SEARCH_TERM:
        return updateSearchTerm(draftState, action);
      case UPDATE_SEARCH_TERM_CONTACT:
        return updateSearchTermContact(draftState, action);
      case UPDATE_SORT_BY_OPTION:
        return updateSortByOption(draftState, action);
      case SIGN_OUT_SUCCESS:
        return SEARCH_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const updateSearchTerm = (draftState, action) => {
  draftState.searchTerm = action.payload.searchTerm;
};

const updateSearchTermContact = (draftState, action) => {
  draftState.searchTermContact = action.payload.searchTerm;
};

const updateSortByOption = (draftState, action) => {
  const { sortBy } = action.payload;
  draftState.sortBy = sortBy;
  draftState.sortOrder = SORT_OPTIONS[sortBy].sortOrder;
};

export default searchReducer;
