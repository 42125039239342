import produce from "immer";
import {
  CHANGE_CONNECTION_STATUS,
  SYNC_STARTED,
  SYNC_SUCCESS,
  SYNC_FAILURE,
  INCREASE_SYNC_COUNT
} from "../actions/types";

const SYNC_INITIAL_STATE = {
  nextSync: null,
  lastSyncSuccess: null,
  lastSyncFailure: null,
  syncCount: 0,
  isOnline: false
};

const syncReducer = (state = SYNC_INITIAL_STATE, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case SYNC_STARTED:
        return syncStarted(draftState, action);
      case SYNC_SUCCESS:
        return syncSuccess(draftState, action);
      case SYNC_FAILURE:
        return syncFailure(draftState, action);
      case INCREASE_SYNC_COUNT:
        return increaseSyncCount(draftState, action);
      case CHANGE_CONNECTION_STATUS:
        return changeConnectionStatus(draftState, action);
      default:
        return state;
    }
  });
};

const syncStarted = (draftState, action) => {
  draftState.nextSync = action.payload.startSync;
};

const syncSuccess = (draftState, action) => {
  draftState.lastSyncSuccess = action.payload.lastSyncSuccess;
  draftState.nextSync = action.payload.nextSync;
};

const syncFailure = (draftState, action) => {
  draftState.lastSyncFailure = action.payload.lastSyncFailure;
};

const increaseSyncCount = (draftState, action) => {
  draftState.syncCount++;
};

const changeConnectionStatus = (draftState, action) => {
  draftState.isOnline = action.payload.connectionState;
};

export default syncReducer;
