import React from "react";
import { Field, reduxForm } from "redux-form";
import { shareArtwork } from "../../store/actions";
import { Box, Text } from "../fundamentals";
import { Input, Section, Checkbox, Button } from "../elements";
import { InputLabels, InputErrors, ButtonLabels, Headings, UiLabels } from "../../localisation";

const Form = ({ handleSubmit, submitting }) => {
  return (
    <form onSubmit={handleSubmit(shareArtwork)}>
      <Box width="320px">
        <Text size="heading3" mb="xxl" color="black">
          {Headings.shareArtworks}
        </Text>
        <Field name="email" label={InputLabels.recipientEmail} component={Input} />
        <Field name="message" type="textarea" label={InputLabels.message} minHeight="120px" component={Input} />
        <Section subtitle={UiLabels.include} mt="l" mb="xl">
          <Field name="sharePrice" label={InputLabels.sharePrice} component={Checkbox} />
        </Section>
        <Button
          type="submit"
          buttonStyle="primary"
          size="medium"
          mt="m"
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          icon={submitting ? "Loader" : null}
          disabled={submitting}
        >
          {ButtonLabels.share}
        </Button>
      </Box>
    </form>
  );
};

const validate = values => {
  const errors = {};

  const { email } = values;

  if (!email) {
    errors.email = InputErrors.noEmail;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = InputErrors.invalidEmail;
  }

  return errors;
};

const ShareModal = reduxForm({
  form: "share",
  validate
})(Form);

export { ShareModal };
