import React from "react";
import { Field, reduxForm } from "redux-form";
import { Flex, Text, Box } from "../fundamentals";
import { Headings, DynamicLabels } from "../../localisation";
import { StripeCardExpiryInput, StripeCardCvcInput, StripeCardNumberInput, Button } from "../elements";
import { InputPlaceholders, ButtonLabels } from "../../localisation";
import { expiredPaymentMethod } from "../../store/actions";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";

const Form = ({ handleSubmit, submitting, lastFourNumbers }) => {
  const stripe = useStripe();
  const elements = useElements();

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    return dispatch(
      expiredPaymentMethod.request({
        ...values,
        stripe,
        elements,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Text size="heading2" fontWeight="700" textAlign="center" mb="l">
          {Headings.creditCardExpired}
        </Text>
        <Box width="80%">
          <Text size="small" mb="l">
            {DynamicLabels.expiredCreditCard(lastFourNumbers)}
          </Text>
          <Field
            name="cardNumber"
            component={StripeCardNumberInput}
            options={{ placeholder: InputPlaceholders.creditCardNumber }}
          />
          <Flex justifyContent="space-between" mt="m">
            <Field name="cardExpiryDate" component={StripeCardExpiryInput} />
            <Field name="cardCvc" component={StripeCardCvcInput} />
          </Flex>
        </Box>
        <Button
          buttonStyle="default"
          size="medium"
          type="submit"
          disabled={submitting}
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          mt="l"
          alignSelf="center"
        >
          {ButtonLabels.update}
        </Button>
      </Flex>
    </form>
  );
};

function mapStateToProps(state) {
  const lastFourNumbers = state.user.account.card?.lastFourNumbers ?? "8549";
  return { lastFourNumbers };
}

let ExpiredPaymentForm = reduxForm({
  form: "expired-payment-method",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form);

ExpiredPaymentForm = connect(mapStateToProps, null)(ExpiredPaymentForm);
export default ExpiredPaymentForm;
