import produce from "immer";
import { CHANGE_CONNECTION_STATUS, SIGN_OUT_SUCCESS } from "../actions/types";
import { WEBAPP_VERSION } from "../../config";

const APP_INITIAL_STATE = {
  isOnline: false,
  version: WEBAPP_VERSION
};

const appReducer = (state = APP_INITIAL_STATE, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case CHANGE_CONNECTION_STATUS:
        return { ...state, isOnline: action.payload.connectionState };
      case SIGN_OUT_SUCCESS:
        return APP_INITIAL_STATE;
      default:
        return state;
    }
  });
};

export default appReducer;
