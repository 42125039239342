import React, { useRef } from "react";
import { SimplifyApi } from "../../utils";
import { push } from "connected-react-router";
import {
  Headings,
  Instructions,
  LinkLabels,
  ButtonLabels,
  InputErrors,
  Info,
  InputPlaceholders,
  ApiErrors,
} from "../../localisation";

import { Flex, Text, Link, Box } from "../fundamentals";
import { Field, reduxForm } from "redux-form";
import { Input, Button } from "../elements";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Typography } from "@mui/material";

const headerTextStyle = (mobile) => {
  console.log('mobile', mobile)
  return {
    textAlign: "center",
    fontFamily: "DM Sans",
    fontSize: mobile ? "20px" : "32px",
    lineHeight: mobile ? "26px" : "40px",
    marginBottom: mobile ? "30px" : "50px",
    fontWeight: "400",
  };
};

const Form = ({ handleSubmit, submitting }) => {
  const isMobile = useIsScreenMobile();
  const passwordRef = useRef(null);

  return (
    <form onSubmit={handleSubmit}>
      <Flex flexDirection="column" justifyContent="center">
        <Typography sx={headerTextStyle(isMobile)}>{Headings.trySimplify}</Typography>

        <Field
          name="email"
          placeholder={InputPlaceholders.emailText}
          focusColor="blue.60"
          required={true}
          minHeight={isMobile ? "28px" : "50px"}
          options={{
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                passwordRef.current.focus();
              }
            },
          }}
          component={Input}
        />

        <Field
          customRef={passwordRef}
          name="password"
          placeholder={InputPlaceholders.password}
          required={true}
          password
          component={Input}
          minHeight={isMobile ? "28px" : "50px"}
        />

        <Flex flexDirection="column" alignItems="center">
          <Box>
            <Button
              buttonStyle="default"
              type="submit"
              size={isMobile ? "small" : "medium"}
              fontSize={isMobile ? "13px" : "20px"}
              icon={submitting ? "Loader" : null}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              bg="white"
              p={isMobile ? "5px 12px" : "8.5px 20px"}
              mt="s"
              fontWeight="700"
            >
              {ButtonLabels.createAccount}
            </Button>
          </Box>

          {!isMobile && (
            <Text fontSize="smaller" lineHeight="button" color="black" mt="s" width="302px" textAlign="center">
              {Instructions.acceptTermsText}
              <Link
                href="https://www.simplify.art/imprint#terms-conditions"
                target="_blank"
                textDecoration="underline"
                color="black"
                fontFamily="Inter"
              >
                {LinkLabels.terms}
              </Link>
              {" and "}
              <Link
                href="https://www.simplify.art/imprint#privacy-policy"
                target="_blank"
                textDecoration="underline"
                color="black"
                fontFamily="Inter"
              >
                {LinkLabels.privacyPolicy}
              </Link>
            </Text>
          )}
        </Flex>
      </Flex>
    </form>
  );
};

const asyncValidate = (values) => {
  const { email } = values;
  console.log(email);
  return new Promise((resolve, reject) => {
    SimplifyApi.get(`/v1/user/${email}`).then((response) => {
      const { exists, active } = response.data;

      console.dir(response.data);

      if (exists && active) reject({ email: ApiErrors.emailExists });
      else resolve();
    });
  });
};

const validate = (values) => {
  const errors = {};

  const { email, password } = values;

  if (!email) errors.email = InputErrors.noEmail;
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = InputErrors.invalidEmail;

  if (!password) errors.password = InputErrors.noPassword;
  else if (!/^.{9,}$/.test(password)) errors.password = InputErrors.insecurePassword;

  return errors;
};

const RegisterForm = reduxForm({
  form: "register",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate,
  asyncBlurFields: ["email"],
})(Form);

export { RegisterForm };
