import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { initialize } from "redux-form";
import {
  editMultipleArtworks,
  openModal,
  clearAllSelectedArtworks,
  clearUnactiveCacheImages,
} from "../../store/actions";
import {
  selectUser,
  selectSelectedCollection,
  selectArtworksById,
  selectSelectedArtworkIds,
} from "../../store/selectors";
import { ButtonLabels, UiLabels, DynamicLabels } from "../../localisation";

import { NavigationBar } from "../react-material/NavigationBar";
import { SizeMe } from "react-sizeme";
import { Flex, Box, Text } from "../fundamentals";
import { Button, Modal } from "../elements";
import { ArtworkForm } from "../forms";
import { AdvancedExportsModal, ShareModal } from "../modals";
import { ArtworkSidebar, ArtworkDetailsHeader, DropdownContainer, Dropdown, DropdownItem, BackToTop } from "../modules";
import { WithRequireAccess, StickyObservable } from "../hocs";

const StyledHeaderContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
`;

const EditMultipleArtworkScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sidebarWidth, setSidebarWidth] = useState();
  const user = useSelector(selectUser);
  const selectedCollection = useSelector(selectSelectedCollection);
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const artworks = useSelector((state) => selectArtworksById(state, selectedArtworkIds));
  const firstArtwork = artworks[0];

  useEffect(() => {
    const initialFormValues = {
      collection: {
        id: selectedCollection.id,
      },
    };
    if (selectedCollection.type === "production") initialFormValues.artist = user.name;

    dispatch(initialize("artworks.multi-edit", initialFormValues));
  }, []);

  const renderFloatingActionPillButtons = (reset, dirty, pristine, submitting) => {
    if (dirty) {
      return [
        <Button key="save" type="submit" buttonStyle="text" size="medium" icon="UploadCloud" inverted={true}>
          {ButtonLabels.saveArtwork}
        </Button>,

        <Button
          key="reset"
          type="button"
          buttonStyle="text"
          size="medium"
          icon="RotateCcw"
          inverted={true}
          onClick={!pristine ? reset : null}
          preventDefault
        >
          {ButtonLabels.reset}
        </Button>,
      ];
    } else {
      return [
        <Button
          key="export"
          buttonStyle="text"
          size="medium"
          icon="FileText"
          preventDefault
          onClick={() => dispatch(openModal("advanced_export"))}
        >
          {ButtonLabels.export}
        </Button>,
        <Button
          key="share"
          buttonStyle="text"
          size="medium"
          icon="Share"
          preventDefault
          onClick={() => dispatch(openModal("share"))}
        >
          {ButtonLabels.share}
        </Button>,
        <DropdownContainer
          component={Button}
          buttonStyle="text"
          size="medium"
          icon="MoreHorizontal"
          iconSize="22"
          iconOffset="xs"
          label={ButtonLabels.more}
          key="more"
          mr="s"
          preventDefault
        >
          <Dropdown bottom="xl">
            <DropdownItem
              buttonStyle="text"
              size="medium"
              icon="Trash2"
              inactiveColor="red"
              iconOffset="xl"
              label={DynamicLabels.deleteArtworks(selectedArtworkIds.length)}
              pl="xxs"
              preventDefault
              onSelect={() => dispatch(openModal("delete_artwork"))}
            />
          </Dropdown>
        </DropdownContainer>,
      ];
    }
  };

  const renderStatusSelector = () => {
    return (
      <Text size="small" mr="m">
        {`Status ${UiLabels.multiple}`}
      </Text>
    );
  };

  const submit = (values, dispatch) => {
    console.log("EXECUTES MULTI-EDIT");
    console.dir(values);
    return dispatch(editMultipleArtworks(selectedArtworkIds, values));
  };

  return (
    <Flex flex="1" flexDirection="column" maxWidth="1680px" width="100%">
      <NavigationBar />

      <Flex bg="white" boxShadow="small" mb="xxxl" position="relative" flex="1">
        <StyledHeaderContainer mt="s">
          <Flex pl="xl">
            <Button
              buttonStyle="text"
              size="small"
              icon="ArrowLeft"
              onClick={() => {
                history.replace("/artworks");
                dispatch(clearUnactiveCacheImages());
                dispatch(clearAllSelectedArtworks());
              }}
              pl="0px"
              pb="none"
            >
              {ButtonLabels.backToArtworks}
            </Button>
          </Flex>

          <StickyObservable options={{ threshold: 1 }}>
            <ArtworkDetailsHeader
              artwork={firstArtwork}
              pl="xl"
              right="0"
              sidebarWidth={sidebarWidth}
              renderStatusSelector={renderStatusSelector}
              showNavigationButtons={false}
              numArtworks={selectedArtworkIds.length}
              isMultiEditMode={true}
            />
          </StickyObservable>

          <Flex color="grey.80" mt="xxxs" pl="xl" mb="xxl" alignItems="center" whiteSpace="nowrap">
            <Text size="small" mr="m">
              {`ID ${UiLabels.multiple}`}
            </Text>

            {renderStatusSelector()}

            <Text size="small" ml="m">{`${selectedArtworkIds.length} ${UiLabels.selected}`}</Text>
          </Flex>
        </StyledHeaderContainer>

        <Box flex="1">
          <ArtworkForm
            form="artworks.multi-edit"
            singleTab={false}
            selectedArtworkIds={selectedArtworkIds}
            isMultiEditMode={true}
            numArtworks={selectedArtworkIds.length}
            renderFloatingActionPillButtons={renderFloatingActionPillButtons}
            onSubmit={submit}
            mt="168px"
          />
        </Box>

        <SizeMe>
          {({ size }) => {
            if (sidebarWidth !== size.width) setSidebarWidth(size.width);
            return <ArtworkSidebar artworks={artworks} artworkPreviewNum={6} isMultiEditMode={true} />;
          }}
        </SizeMe>
      </Flex>

      <BackToTop />

      <Modal id="share">
        <ShareModal />
      </Modal>
      <Modal id="advanced_export">
        <AdvancedExportsModal />
      </Modal>
    </Flex>
  );
};

export default WithRequireAccess(EditMultipleArtworkScreen, 1);
