import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { SimplifyApi } from "../../utils";
import { Headings, ButtonLabels, InputErrors, ApiErrors, InputPlaceholders } from "../../localisation";
import { signIn } from "../../store/actions";

import { Flex, Text, Box } from "../fundamentals";

import { Input, Button } from "../elements";

import { useIsScreenMobile } from "../hooks/useMediaQuery";

const Form = ({ handleSubmit, submitting }) => {
  const history = useHistory();
  const passwordRef = useRef(null);
  const isMobile = useIsScreenMobile();

  return (
    <form onSubmit={handleSubmit(signIn)}>
      <Flex flexDirection="column" justifyContent="center">
        <Text size={isMobile ? "heading3" : "heading1"} textAlign="center" mb={isMobile ? "m" : "xxl"}>
          {Headings.welcomeBack}
        </Text>
        <Field
          name="email"
          type="text"
          focusColor="blue.60"
          placeholder={InputPlaceholders.emailText}
          minHeight={isMobile ? "32px" : "50px"}
          options={{
            onKeyDown: (event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                passwordRef.current.focus();
              }
            },
          }}
          component={Input}
        />

        <Field
          customRef={passwordRef}
          name="password"
          component={Input}
          password
          placeholder={InputPlaceholders.password}
          minHeight={isMobile ? "32px" : "50px"}
        />

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box>
            <Button
              buttonStyle="default"
              type="submit"
              size={isMobile ? "small" : "medium"}
              fontSize={isMobile ? "13px" : "20px"}
              disabled={submitting}
              icon={submitting ? "Loader" : null}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              bg="white"
              p={isMobile ? "5px 12px" : "8.5px 20px"}
              mt="s"
              fontWeight="700"
            >
              {ButtonLabels.login}
            </Button>
          </Box>
          <Box>
            <Button
              buttonStyle="text"
              fontSize={isMobile ? "8px" : "small"}
              color="#000000"
              mt={isMobile ? "xxxs" : "xxs"}
              textDecoration="underline"
              onClick={(e) => {
                e.preventDefault();
                history.push("/forgotPassword");
              }}
            >
              {ButtonLabels.forgotPassword}
            </Button>
          </Box>
        </Box>
      </Flex>
    </form>
  );
};

const asyncValidate = (values) => {
  const { email } = values;

  return new Promise((resolve, reject) => {
    SimplifyApi.get(`/v1/user/${email}`).then((response) => {
      const { exists, active } = response.data;
      console.dir(response.data);

      if ((!exists && !active) || !exists) reject({ email: ApiErrors.noEmailExists });
      else resolve();
    });
  });
};

const validate = (values) => {
  const errors = {};

  const { email, password } = values;

  if (!email) errors.email = InputErrors.noEmail;
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = InputErrors.invalidEmail;

  if (!password) errors.password = InputErrors.noPassword;

  return errors;
};

const LoginForm = reduxForm({
  form: "login",
  validate,
  asyncValidate,
  asyncBlurFields: ["email"],
})(Form);

export { LoginForm };
