import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CountryData from "country-region-data";
import {
  Headings,
  ButtonLabels,
  InputPlaceholders,
  UiLabels,
  DynamicLabels,
  Messages,
  InputErrors,
} from "../../localisation";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Flex, Text } from "../fundamentals";
import {
  Button,
  Input,
  InputSelect,
  StripeCardNumberInput,
  StripeCardExpiryInput,
  StripeCardCvcInput,
  RadioSelect,
} from "../elements";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { register } from "../../store/actions";
import { returnSelectedPlan, returnStripePlansBasedOnUserRole } from "../../utils";
import { useIsScreenDesktop, useIsScreenMobile, useIsScreenTablet } from "../hooks/useMediaQuery";
import { Box, Typography } from "@mui/material";
import { RenderPaymentPlan } from "../react-material/RenderPaymentPlan/RenderPaymentPlan";

const billingStyle = {
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "600",
  textAlign: "center",
  letterSpacing: "2px",
};

const priceStyle = {
  fontFamily: "DM Sans",
  fontWeight: "700",
  fontSize: "20px",
};

const Form = ({ handleSubmit, submitting, plan, role, user }) => {
  const isTablet = useIsScreenTablet();
  const isMobile = useIsScreenMobile();
  const isDesktop = useIsScreenDesktop();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    if (!role && !user) {
      history.push("/register");
    }
  }, [role, history]);

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    return dispatch(
      register.request({
        ...values,
        stripe,
        elements,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex
        flexDirection="column"
        alignItems="center"
        width={isTablet ? "588px" : isMobile ? "325px" : "800px"}
        bg="white"
        p={isMobile ? "m" : "60px 60px 35px 60px"}
        boxShadow="medium"
        borderRadius="20px"
        marginBottom="72px"
      >
        <Text size={isMobile ? "heading3" : "heading2"} mb="m" fontWeight="700" textAlign="center">
          {Headings.chooseBillingPeriod}
        </Text>
        <Flex justifyContent="space-between" width={isTablet ? "50%" : isMobile ? "90%" : "40%"}>
          <Field
            name="plan"
            options={returnStripePlansBasedOnUserRole(role)}
            direction="horizontal"
            customItemComponent={RenderPaymentPlan}
            component={RadioSelect}
          />
        </Flex>

        <Flex
          flexDirection={isTablet || isMobile ? "column-reverse" : "row"}
          justifyContent="space-between"
          alignItems="center"
          mt="xxxs"
         
        >
          {/* Left side */}

          <Box
            width={isTablet ? "80%" : isMobile ? "100%" : "45%"}
            mt={(isTablet || isMobile) && "m"}
          >
            <Typography sx={{ ...billingStyle, textAlign: "left", marginBottom: "24px" }}>
              {Headings.accountDetails.toUpperCase()}
            </Typography>

            <Flex justifyContent="space-between" mt="s">
              <Field name="address" placeholder={InputPlaceholders.address} flex="2" mr="m" component={Input} />
              <Field name="apt" placeholder={InputPlaceholders.apt} flex="1" component={Input} />
            </Flex>

            <Field name="city" placeholder={InputPlaceholders.city} component={Input} />

            <Field
              name="country"
              placeholder="Country"
              options={CountryData.map((country) => ({ value: country.countryName, label: country.countryName }))}
              component={InputSelect}
            />

            <Field mt="-10px" mb="s" name="postalCode" placeholder={InputPlaceholders.postalCode} component={Input} />

            <Field
              name="cardNumber"
              component={StripeCardNumberInput}
              options={{ placeholder: InputPlaceholders.creditCardNumber }}
            />

            <Flex justifyContent="space-between" mt="s">
              <Field name="cardExpiryDate" component={StripeCardExpiryInput} />
              <Field name="cardCvc" component={StripeCardCvcInput} />
            </Flex>

            <Button
              buttonStyle="link"
              type="button"
              textDecoration="underline"
              color="black"
              my="s"
              alignSelf="center"
              onClick={() => history.goBack()}
            >
              {ButtonLabels.back}
            </Button>
            {(isTablet || isMobile) && (
              <Box display="flex" justifyContent="center">
                <Button
                  buttonStyle="default"
                  size="small"
                  type="submit"
                  disabled={submitting || !plan}
                  icon={submitting ? "Loader" : null}
                  iconSpinning={true}
                  flip={true}
                  iconOffset="xs"
                  alignSelf="center"
                  mb="l"
                  bg="white"
                  cursor={plan ? "pointer" : "default"}
                >
                  {ButtonLabels.startFreeTrial}
                </Button>
              </Box>
            )}
          </Box>

          {/* Right Side */}

          <Box width={isTablet ? "80%" : isMobile ? "100%" : "45%"} height={isMobile ? "320px" : "410px"}>
            <Typography sx={{ ...billingStyle, textAlign: "left", marginBottom: "24px" }}>
              {Headings.orderSummary.toUpperCase()}
            </Typography>

            <Flex justifyContent="space-between" fontWeight="700">
              <Typography sx={priceStyle}>{`${returnSelectedPlan(plan).label} ${UiLabels.plan}`}</Typography>
              <Typography sx={priceStyle}>{"\u20ac" + returnSelectedPlan(plan).price}</Typography>
            </Flex>

            <Box borderBottom="2px solid black" my="s"></Box>

            <Flex justifyContent="space-between" mb="xxxs">
              <Text size="body">{UiLabels.subTotal}</Text>
              <Text size="body">{"\u20ac" + returnSelectedPlan(plan).subtotal}</Text>
            </Flex>

            <Flex justifyContent="space-between" mb="xxxs">
              <Text size="body">{UiLabels.vat.toUpperCase()}</Text>
              <Text size="body">{"\u20ac" + returnSelectedPlan(plan).vat}</Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Text size="body" color={"blue.80"}>
                {DynamicLabels.numFreeTrial(30)}
              </Text>
              <Text size="body" color={"blue.80"}>
                - {"\u20ac" + returnSelectedPlan(plan).price}
              </Text>
            </Flex>

            <Box borderBottom="2px solid black" my="s"></Box>

            <Flex justifyContent="space-between" fontWeight="700" mb="xxxs">
              <Text sx={priceStyle}>{UiLabels.dueNow}</Text>
              <Text sx={priceStyle}>{"\u20ac" + 0}</Text>
            </Flex>

            <Flex justifyContent="space-between" mb="xxxs">
              <Text size="body">{DynamicLabels.dateStartingToPay(moment().add(30, "days"))}</Text>
              <Text size="body">{"\u20ac" + returnSelectedPlan(plan).price}</Text>
            </Flex>

            <Text size="smaller" width="80%">
              {`${Messages.trialMessage(30)}. ${Messages.paymentMessage}.`}
            </Text>

            {isDesktop && (
              <Button
                buttonStyle="default"
                size="medium"
                type="submit"
                disabled={submitting || !plan}
                icon={submitting ? "Loader" : null}
                iconSpinning={true}
                flip={true}
                iconOffset="xs"
                my="xxl"
                alignSelf="center"
                cursor={plan ? "pointer" : "default"}
                bg="white"
              >
                {ButtonLabels.startFreeTrial}
              </Button>
            )}
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  const { plan, address, city, country, postalCode } = values;

  if (!plan) errors.plan = InputErrors.noPlan;
  if (!address) errors.address = InputErrors.noAddress;
  if (!city) errors.city = InputErrors.noCity;
  if (!country) errors.country = InputErrors.noCountry;
  if (!postalCode) errors.postalCode = InputErrors.noPostalCode;

  // if (!cardNumber) errors.cardNumber = InputErrors.noCardNumber;
  // if (!cardExpiryDate) errors.cardExpiryDate = InputErrors.noCardExpiryDate;
  // if (!cardCvc) errors.cardCvc = InputErrors.noCardCvc;

  return errors;
};

let OnboardingThirdForm = reduxForm({
  form: "register",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("register");

function mapStateToProps(state) {
  const role = selector(state, "role");
  const plan = selector(state, "plan");

  return { role, plan, user: state.user };
}

OnboardingThirdForm = connect(mapStateToProps, null)(OnboardingThirdForm);

export { OnboardingThirdForm };
