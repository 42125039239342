import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { InputBase, styled, Checkbox, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from "@mui/icons-material";

const chipStyle = (isSelect) => {
  return {
    backgroundColor: isSelect ? "white" : "#DEE1FF",
    border: isSelect && "1px solid #C3C2C9",
    borderRadius: "4px",
    padding: "6px 12px",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "500",
    color: "#2B2A2E",
    fontFamily: "Inter",
    gap: "4px",
  };
};

const chipWrapper = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  height: "28px",
  padding: "9px 11px",
};

const selectedMenuStyle = (isSelected) => {
  return {
    backgroundColor: isSelected && "#5E54FF !important",
    color: isSelected ? "white" : "black",
    height: "48px",
    fontFamily: "Inter !important",
    borderRadius: "0px !important",
    "&:hover": {
      backgroundColor: !isSelected && "#F3F4FF !important",
      color: !isSelected && "#5E54FF",
      height: "48px",
    },
  };
};

const selectedMenuStyles = {
  PaperProps: {
    sx: {
      "& .MuiMenuItem-root.Mui-selected": {
        backgroundColor: "#5E54FF",
        color: "white",
        fontFamily: "Inter !important",
      },
      "& .MuiMenuItem-root:hover": {
        backgroundColor: "#F3F4FF",
        color: "#5E54FF",
        fontFamily: "Inter",
      },
      "& .MuiMenuItem-root.Mui-selected:hover": {
        backgroundColor: "#5E54FF",
        color: "white",
        fontFamily: "Inter !important",
      },
    },
  },
};

const CustomizedInput = styled(InputBase)((props) => {
  return {
    "& .MuiInputBase-input": {
      borderRadius: 0,
      backgroundColor: props?.open ? "#F3F4FF" : "white",
      borderBottom: props?.open ? "2px solid #5E54FF" : "0px solid #C8CEFF",
      minWidth: "223px",
      display: "flex",
      alignItems: "center",
      height: "50px !important",
      "&:hover": {
        borderColor: "#5E54FF",
      },
      "&:focus": {
        borderRadius: 0,
        borderColor: "#5E54FF",
      },
    },
  };
});

const selectValue = [{ value: "", label: "Select" }];

const MultiInputSelect = ({ input, size, meta: { error, ...meta }, options, ...props }) => {
  const { value, onChange } = input;
  const [open, setOpen] = useState(false);
  const [hoverMenu, setHoverMenu] = useState("");
  const [toggle, setToggle] = useState(false)

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    if (input.value?.length === 0) {
      value.shift(); // Remove placeholder data (Select)
    }

    const uniqueValues = [];
    value.map((data1, i) => {
      const duplicate = value.filter((data2) => data1.value === data2.value);
      if (duplicate.length === 1) {
        uniqueValues.push(data1);
      }
      return 0;
    });

    onChange(uniqueValues);
  };

  const getColor = (type) => {
    if (type) {
      return "#5E54FF";
    } else {
      return "#6A6870";
    }
  };

  const refreshHandler=()=>{
    return toggle
  }

  const checkboxSelectHandler = (option) => {
    return value?.length > 0 && value.find((data) => data.value === option.value);
  };

  const stringToObject = () => {
    const types = options.filter((o) => value?.some((value) => o.value === value));
    onChange(types);
  };

  const handleOnOpen = () => {
    setOpen(true);
    setToggle(true)
    setTimeout(() => {
      setToggle(false);
    }, 100);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (value?.length > 0) {
      if (!value?.[0]?.value) {
        stringToObject();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);


  return (
    <Select
      multiple
      open={open}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      value={input.value?.length > 0 ? input.value : selectValue}
      onChange={handleChange}
      input={<CustomizedInput open={open} {...props} />}
      renderValue={(selected) => (
        <Box sx={chipWrapper}>
          {selected.map((value) => (
            <Box sx={chipStyle(value.label === "Select")} key={value.label}>
              {value.label}
            </Box>
          ))}
        </Box>
      )}
      IconComponent={(props) => <KeyboardArrowUpIcon {...props} color={"#2B2A2E"} />}
    >
      {options.map((option) => (
        <MenuItem
          key={option}
          value={option}
          onMouseOver={() => setHoverMenu(option.label)}
          onMouseLeave={() => setHoverMenu("")}
          sx={selectedMenuStyle(checkboxSelectHandler(option))}
        >
          <Checkbox
          disableRipple
            size={size}
            checkedIcon={<CheckBoxRounded sx={{ color: "white" }} />}
            icon={<CheckBoxOutlineBlankRounded sx={{ color: getColor(option.label === hoverMenu) }} />}
            checked={checkboxSelectHandler(option) || refreshHandler()}
          />

          <Typography sx={{ fontFamily: "Inter", fontSize: "16px" }}>{option.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export { MultiInputSelect };
