import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { UiLabels, InputLabels } from "../../localisation";

import { Field, FormSection, change, getFormValues } from "redux-form";
import { Box, Flex, Text } from "../fundamentals";
import { Input, Checkbox } from ".";

const DimensionsInput = ({
  form,
  name,
  dimensions,
  preventOverlapRef,
  readOnly,
  variable,
  diameter,
  changeValue,
  untouchField,
  warning,
  ...props
}) => {
  const [diameterOption, setDiameterOption] = useState(dimensions && dimensions.hasOwnProperty("diameter"));
  const clearDimensions = () => changeValue(form, name, undefined);
  return (
    <FormSection name={name}>
      <Box mb="xl" {...props}>
        <Box mb="xxs">
          {diameterOption ? (
            <Field
              name="diameter"
              width="50%"
              disabled={readOnly}
              label={InputLabels.diameter}
              preventOverlapRef={preventOverlapRef}
              scrollingRef={{ current: window }}
              options={{ type: "number" }}
              suffix={
                <Text size="body" color="grey.60" position="absolute" right="xxs">
                  {UiLabels.cm}
                </Text>
              }
              additionalOnChange={value => {
                if (value) changeValue(form, name, { diameter: value });
                else if (!value || isNaN(value)) clearDimensions();
              }}
              hideError
              component={Input}
            />
          ) : (
            <Flex justifyContent="space-between">
              <Field
                name="height"
                width="30%"
                disabled={readOnly || (dimensions && dimensions.variable)}
                label={InputLabels.height}
                preventOverlapRef={preventOverlapRef}
                scrollingRef={{ current: window }}
                options={{ type: "number" }}
                suffix={
                  <Text size="body" color="grey.60" position="absolute" right="xxs">
                    {UiLabels.cm}
                  </Text>
                }
                additionalOnChange={value => {
                  if (dimensions) {
                    if ((!value || isNaN(value)) && (!dimensions.width || isNaN(dimensions.width))) clearDimensions();
                    else if (dimensions.diameter && value && !isNaN(value)) changeValue(form, name, { height: value });
                  }
                }}
                normalize={value => value && value.toString()}
                hideError
                component={Input}
              />
              <Field
                name="width"
                width="30%"
                disabled={readOnly || (dimensions && dimensions.variable)}
                label={InputLabels.width}
                preventOverlapRef={preventOverlapRef}
                scrollingRef={{ current: window }}
                options={{ type: "number" }}
                suffix={
                  <Text size="body" color="grey.60" position="absolute" right="xxs">
                    {UiLabels.cm}
                  </Text>
                }
                additionalOnChange={value => {
                  if (dimensions) {
                    if ((!value || isNaN(value)) && (!dimensions.height || isNaN(dimensions.height))) clearDimensions();
                    else if (dimensions.diameter) changeValue(form, name, { width: value });
                  }
                }}
                normalize={value => value && value.toString()}
                hideError
                component={Input}
              />
              <Field
                name="depth"
                width="30%"
                disabled={readOnly || (dimensions && dimensions.variable)}
                label={InputLabels.depth}
                preventOverlapRef={preventOverlapRef}
                scrollingRef={{ current: window }}
                options={{ type: "number" }}
                suffix={
                  <Text size="body" color="grey.60" position="absolute" right="xxs">
                    {UiLabels.cm}
                  </Text>
                }
                additionalOnChange={value => {
                  if (!value || isNaN(value)) changeValue(form, name, { depth: value });
                  dimensions && dimensions.diameter && changeValue(form, name, _.omit(dimensions, diameter));
                }}
                normalize={value => value && value.toString()}
                hideError
                component={Input}
              />
            </Flex>
          )}
        </Box>
        <Flex>
          {variable && (
            <Field
              name="variable"
              label={InputLabels.dimensionsVariable}
              labelSize="small"
              disabled={readOnly}
              additionalOnChange={variable => {
                if (variable) changeValue(form, name, { variable });
                else clearDimensions();
              }}
              component={Checkbox}
            />
          )}
          {diameter && (
            <Checkbox
              input={{ value: dimensions && dimensions.variable ? false : diameterOption, onChange: setDiameterOption }}
              label="Diameter"
              labelSize="small"
              disabled={(dimensions && dimensions.variable) || readOnly}
              additionalOnChange={value => {}}
            />
          )}
        </Flex>
      </Box>
    </FormSection>
  );
};

function mapStateToProps(state, { form, name }) {
  const dimensions = _.get(getFormValues(form)(state), name);
  return { dimensions };
}

function mapDispatchToProps(dispatch, { form, name }) {
  return bindActionCreators(
    {
      changeValue: change
    },
    dispatch
  );
}

const ConnectedDimensionsInput = connect(mapStateToProps, mapDispatchToProps)(DimensionsInput);

export { ConnectedDimensionsInput as DimensionsInput };
