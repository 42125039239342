import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Element } from "react-scroll";
import { connect, useDispatch } from "react-redux";
import { deleteArtworks, clearAllSelectedArtworks } from "../../store/actions";
import { Field, FieldArray, FormSection, reduxForm, submit, formValueSelector, change } from "redux-form";
import { Box, Flex, Text, Icon } from "../fundamentals";
import {
  Section,
  TabSelect,
  Input,
  Modal,
  PriceInput,
  DimensionsInput,
  InputSelect,
  DropdownSelect,
  CrateFields,
  FloatingActionPill,
  DatePicker,
  Checkbox
} from "../elements";
import { ImageViewerModal, AlertModal } from "../modals";

import { ScrollSectionIndicator, ImageUpload } from "../modules";
import { DynamicLabels, ButtonLabels, InputLabels, InputErrors } from "../../localisation";
import { DEFAULT_ARTWORK_STATUSES, ARTWORK_FORM_SECTIONS } from "../../config";

const Form = ({
  form,
  handleSubmit,
  renderFloatingActionPillButtons,
  reset,
  pristine,
  submitting,
  dirty,
  initialValues,
  singleTab,
  readOnly,
  selectedArtworkIds,
  status,
  modalImages,
  hasFrameData,
  collections,
  isMultiEditMode,
  numArtworks,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fixedPillRef = useRef(null);
  const tabRef = useRef(null);
  const [tab, setTab] = useState("values");

  const handleOnEnterSubmitForm = event => {
    if (event.keyCode === 13) {
      if (!submitting && !pristine) dispatch(submit(form));
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", handleOnEnterSubmitForm);

    return () => {
      document.removeEventListener("keyup", handleOnEnterSubmitForm);
    };
  });

  return (
    <form onSubmit={handleSubmit}>
      <Flex flex="1" flexDirection="column" pl="xl" pr="xl" {...props}>
        {!singleTab && (
          <Box ref={tabRef} mb="xl">
            <TabSelect
              input={{ value: tab, onChange: setTab }}
              options={{ values: "Artwork Details", images: "Images" }}
            />
          </Box>
        )}

        <Flex display={tab !== "values" ? "none" : null} justifyContent="center">
          <ScrollSectionIndicator
            sections={singleTab ? ARTWORK_FORM_SECTIONS : _.omit(ARTWORK_FORM_SECTIONS, ["artworkImages"])}
            height="100%"
            position="sticky"
            top={singleTab ? "m" : "mega"}
            mr="xxxl"
          />

          <Box flex="1" maxWidth="710px">
            {singleTab && !isMultiEditMode && <ArtworkImages form={form} readOnly={readOnly} />}
            <ArtworkCollection
              collections={collections}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
            />
            <ArtworkGeneralInfo
              form={form}
              fixedPillRef={fixedPillRef}
              status={status}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
              hasFrameData={hasFrameData}
            />
            <ArtworkLocation
              fixedPillRef={fixedPillRef}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
            />
            <ArtworkValue
              fixedPillRef={fixedPillRef}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
            />
            <ArtworkAdditionalInfo
              fixedPillRef={fixedPillRef}
              readOnly={readOnly}
              isMultiEditMode={isMultiEditMode}
              numArtworks={numArtworks}
            />
          </Box>
        </Flex>

        {!isMultiEditMode && (
          <Flex display={tab !== "images" ? "none" : null}>
            <ArtworkImages form={form} readOnly={readOnly} />
          </Flex>
        )}

        {isMultiEditMode && (
          <Flex display={tab !== "images" ? "none" : null}>
            <Text fontSize="body" lineHeight="body" color="grey.100" mt="s" mb="xs">
              {"Sorry you can't add/remove images in multi edit mode"}
            </Text>
          </Flex>
        )}

        <FloatingActionPill ref={fixedPillRef} inverted={dirty || selectedArtworkIds.length === 0}>
          {renderFloatingActionPillButtons(reset, dirty, pristine, submitting, handleSubmit)}
        </FloatingActionPill>

        <Modal id="image_viewer">
          <ImageViewerModal images={modalImages} />
        </Modal>
        <Modal id="delete_artwork">
          <AlertModal
            title={DynamicLabels.confirmDeleteArtworks(selectedArtworkIds.length)}
            onAccept={() => {
              history.replace("/artworks");
              dispatch(deleteArtworks(selectedArtworkIds));
              dispatch(clearAllSelectedArtworks());
            }}
          />
        </Modal>
      </Flex>
    </form>
  );
};

const ArtworkImages = ({ form, readOnly }) => {
  return (
    <Element name={ARTWORK_FORM_SECTIONS["artworkImages"].title} style={{ width: "100%" }}>
      <Section {...ARTWORK_FORM_SECTIONS["artworkImages"]} whiteSpace="nowrap">
        <FieldArray
          name="images"
          disabled={readOnly}
          component={ImageUpload}
          form={form}
          urlSuffix="/preview.jpg"
          contained
          withDropbox
          isLogoUpload={false}
        />
      </Section>
    </Element>
  );
};

const ArtworkCollection = ({ collections, readOnly, isMultiEditMode, numArtworks }) => {
  return (
    <Element name={ARTWORK_FORM_SECTIONS["collection"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["collection"]}>
        <Field
          name="collection"
          disabled={readOnly}
          label={InputLabels.collection}
          options={_.map(collections, ({ id, name }) => ({ label: name, value: { id } }))}
          warning={isMultiEditMode && DynamicLabels.multiCollectionEditWarning(numArtworks)}
          component={InputSelect}
          stateful
        />
      </Section>
    </Element>
  );
};

const ArtworkGeneralInfo = ({ fixedPillRef, form, status, readOnly, isMultiEditMode, numArtworks, hasFrameData }) => {
  const dispatch = useDispatch();
  const [hasFrame, setHasFrame] = useState(hasFrameData);

  useEffect(() => {
    setHasFrame(hasFrameData);
  }, [hasFrameData]);

  return (
    <Element name={ARTWORK_FORM_SECTIONS["generalInformation"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["generalInformation"]}>
        <Field
          name="status"
          disabled={readOnly}
          label={InputLabels.status}
          options={DEFAULT_ARTWORK_STATUSES}
          buttonProps={{
            size: "small",
            height: "100%",
            bg: DEFAULT_ARTWORK_STATUSES[status] ? DEFAULT_ARTWORK_STATUSES[status].color : null,
            color: status ? "black" : "grey.80",
            borderRadius: "4px",
            hoverColor: "grey.90",
            ml: "xs"
          }}
          placeholder={InputLabels.status}
          labelSize="small"
          color={"grey.90"}
          mb="m"
          clearable
          preventDefault
          warning={isMultiEditMode && DynamicLabels.multiStatusEditWarning(numArtworks)}
          component={DropdownSelect}
        />

        <Field
          name="artist"
          disabled={readOnly}
          label={InputLabels.artist}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiArtistEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="title"
          disabled={readOnly}
          label={InputLabels.title}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiTitleEditWarning(numArtworks)}
          component={Input}
        />

        <Flex justifyContent="space-between">
          <Field
            name="year"
            disabled={readOnly}
            label={InputLabels.year}
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            warning={isMultiEditMode && DynamicLabels.multiYearEditWarning(numArtworks)}
            flex="1"
            mr="m"
            component={Input}
          />

          <Field
            name="edition"
            disabled={readOnly}
            label={InputLabels.edition}
            preventOverlapRef={fixedPillRef}
            scrollingRef={{ current: window }}
            warning={isMultiEditMode && DynamicLabels.multiEditionEditWarning(numArtworks)}
            flex="1"
            component={Input}
          />
        </Flex>

        <Field
          name="seriesTitle"
          disabled={readOnly}
          label={InputLabels.seriesTitle}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiSeriesTitleEditWarning(numArtworks)}
          component={Input}
        />

        <Text fontSize="body" lineHeight="body" color="grey.100" mt="s" mb="xs">
          {InputLabels.dimensions}
        </Text>

        {isMultiEditMode && (
          <Flex alignItems="center" color={"amber.90"} overflow="hidden" height={24} mb="xxs">
            <Icon icon="Info" size="14" mr="xxxs" />
            <Text fontSize="smaller" lineHeight="button">
              {DynamicLabels.multiDimensionsEditWarning(numArtworks)}
            </Text>
          </Flex>
        )}

        <DimensionsInput
          name="dimensions"
          form={form}
          readOnly={readOnly}
          preventOverlapRef={fixedPillRef}
          variable
          diameter
        />

        <Checkbox
          input={{
            value: hasFrame,
            onChange: hasFrame => {
              setHasFrame(hasFrame);
              if (!hasFrame) dispatch(change(form, "frame", null));
            }
          }}
          label={InputLabels.isFramed}
          labelSize="small"
          mb="l"
          disabled={readOnly}
        />

        {hasFrame && (
          <FormSection name="frame">
            <Field
              name="typeOfFrame"
              label={InputLabels.typeOfFrame}
              disabled={readOnly}
              scrollingRef={{ current: window }}
              preventOverlapRef={fixedPillRef}
              component={Input}
            />
            <DimensionsInput name="dimensions" readOnly={readOnly} preventOverlapRef={fixedPillRef} />
          </FormSection>
        )}

        <Field
          name="technique"
          disabled={readOnly}
          label={InputLabels.technique}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiTechniqueEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="workDescription"
          disabled={readOnly}
          label={InputLabels.workDescription}
          type="textarea"
          minHeight="100px"
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiWorkDescriptionEditWarning(numArtworks)}
          component={Input}
        />
      </Section>
    </Element>
  );
};

const ArtworkLocation = ({ fixedPillRef, readOnly, isMultiEditMode, numArtworks }) => {
  return (
    <Element name={ARTWORK_FORM_SECTIONS["artworkLocation"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["artworkLocation"]}>
        <Field
          name="location"
          disabled={readOnly}
          label={InputLabels.location}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiLocationEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="position"
          disabled={readOnly}
          label={InputLabels.position}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPositionEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="currentOwner"
          disabled={readOnly}
          label={InputLabels.currentOwner}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiCurrentOwnerEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="source"
          disabled={readOnly}
          label={InputLabels.source}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiSourceEditWarning(numArtworks)}
          component={Input}
        />
      </Section>
    </Element>
  );
};

const ArtworkValue = ({ fixedPillRef, readOnly, isMultiEditMode, numArtworks }) => {
  return (
    <Element name={ARTWORK_FORM_SECTIONS["artworkValue"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["artworkValue"]}>
        <PriceInput
          name="price"
          disabled={readOnly}
          label={InputLabels.price}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPriceEditWarning(numArtworks)}
        />

        <PriceInput
          name="insuranceValue"
          disabled={readOnly}
          label={InputLabels.insuranceValue}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiInsuranceValueEditWarning(numArtworks)}
        />

        <PriceInput
          name="productionCosts"
          disabled={readOnly}
          label={InputLabels.productionCosts}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiProductionCostsEditWarning(numArtworks)}
        />

        <PriceInput
          name="framingCosts"
          disabled={readOnly}
          label={InputLabels.framingCosts}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiFramingCostsEditWarning(numArtworks)}
        />
      </Section>
    </Element>
  );
};

const ArtworkAdditionalInfo = ({ fixedPillRef, readOnly, isMultiEditMode, numArtworks }) => {
  return (
    <Element name={ARTWORK_FORM_SECTIONS["additionalInformation"].name}>
      <Section {...ARTWORK_FORM_SECTIONS["additionalInformation"]}>
        <Field
          name="photoCopyrightOwner"
          disabled={readOnly}
          label={InputLabels.photoCopyrightOwner}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPhotoCopyrightOwnerEditWarning(numArtworks)}
          component={Input}
        />

        <Field
          name="incomingDate"
          disabled={readOnly}
          label={InputLabels.incomingDate}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiIncomingDateEditWarning(numArtworks)}
          component={DatePicker}
        />

        <CrateFields
          groupName="packaging.crates"
          disabled={readOnly}
          sortBy="openingOrder"
          isMultiEditMode={isMultiEditMode}
          numArtworks={numArtworks}
        />

        <Field
          name="packaging.instructions"
          disabled={readOnly}
          label={InputLabels.installationInstructions}
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiPackagingInstructionsEditWarning(numArtworks)}
          type="textarea"
          minHeight="100px"
          component={Input}
        />

        <Field
          name="notes"
          disabled={readOnly}
          label={InputLabels.notes}
          type="textarea"
          minHeight="100px"
          preventOverlapRef={fixedPillRef}
          scrollingRef={{ current: window }}
          warning={isMultiEditMode && DynamicLabels.multiNotesEditWarning(numArtworks)}
          component={Input}
        />
      </Section>
    </Element>
  );
};

const validate = (values, ownProps) => {
  const errors = {};

  const { collection, artist, title, dimensions: { height, width, depth, variable: dimensionsVariable } = {} } = values;

  if (!collection || collection.id === "") {
    errors.collection = InputErrors.noCollection;
  }

  if ((!artist && !ownProps.isMultiEditMode) || (_.trim(title) === "" && !ownProps.isMultiEditMode)) {
    errors.artist = InputErrors.noArtist;
  } else if (collection && ownProps.collections[collection.id].type === "production" && artist !== ownProps.user.name) {
    errors.artist = InputErrors.notUserArtist;
  }

  if ((!title && !ownProps.isMultiEditMode) || (_.trim(title) === "" && !ownProps.isMultiEditMode)) {
    errors.title = InputErrors.noTitle;
  }

  if (!dimensionsVariable && (height || depth) && !width) {
    errors.dimensions = { width: InputErrors.noWidthError };
  }
  if (!dimensionsVariable && !height && (width || depth)) {
    errors.dimensions = { height: InputErrors.noHeightError };
  }

  return errors;
};

let ArtworkForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate
})(Form);

const selector = (form, ...other) => formValueSelector(form)(...other);

const mapStateToProps = (state, initialProps) => {
  const { user, archive } = state;
  const status = selector(initialProps.form, state, "status");
  const modalImages = selector(initialProps.form, state, "images");
  const frame = selector(initialProps.form, state, "frame");
  const hasFrameData = !_.isNil(frame);

  return { user, collections: archive.collections.byId, status, modalImages, hasFrameData };
};

ArtworkForm = connect(mapStateToProps, null)(ArtworkForm);

export { ArtworkForm };
