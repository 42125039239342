import axios from "axios";
import { SimplifyApi, cachedDataUrlToFile, createToast } from "../../utils";
import { replace } from "connected-react-router";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { startSubmit, stopSubmit, initialize } from "redux-form";
import { saveAs } from "file-saver";
import moment from "moment";
import _ from "lodash";
import { EDIT_MULTIPLE_ARTWORKS, DELETE_ARTWORKS, CREATE_EXPORT, CREATE_CSV } from "../actions/types";
import {
  addActionToQueue,
  addSelectedArtwork,
  createArtworkLocally,
  createArtworkSuccess,
  createArtworkFailure,
  uploadArtworkImageSuccess,
  uploadArtworkImageFailure,
  editArtworkLocally,
  updateArtworkAfterMultiEdit,
  editArtworkSuccess,
  editArtworkFailure,
  deleteArtwork,
  deleteArtworkSuccess,
  deleteArtworkFailure,
  uploadArtworkImage,
  createExportSuccess,
  createExportFailure,
  createCsvSuccess,
  createCsvFailure,
  shareArtwork,
  saveSharedArtworkLocally,
  saveSharedArtworkSuccess,
  saveSharedArtworkFailure,
  createViewingRoom,
  closeModal,
  removeCacheImage,
  cacheImageActive,
} from "../actions";
import { selectArtworkById, selectSelectedArtworkIds, selectSelectedCollection } from "../selectors";
import { RecordEventAnalytics } from "../../analytics";
import { Events, Properties } from "../../analytics/types";
import { Notifications } from "../../localisation";

// SERVICES

const createArtworkService = ({ artworkCollection, dateCreated, artworkValues, images, ...artwork }) =>
  SimplifyApi.post("/v1/artworks", {
    artwork: { ...artworkValues, ...artwork },
    collection: { id: artworkCollection },
    dateCreated,
  });

const getArtworkImageUploadUrlService = (artworkId) => SimplifyApi.get(`/v1/artworks/imageURLs/${artworkId}/1`);

const deleteArtworkService = (artworkId) => SimplifyApi.delete(`/v1/artworks?artworkId=${artworkId}`);

const editArtworkService = (artwork) => SimplifyApi.patch("/v1/artworks", { artwork });

const uploadArtworkImageService = (uploadURL, imageFile, imageType, watchUploadProgress) =>
  axios.put(uploadURL, imageFile, {
    headers: { "Cache-Control": "max-age: 31536000, immutable", "Content-Type": imageType },
    // watchUploadProgress
  });

const publishArtworkImageService = (artworkId, image) =>
  SimplifyApi.put(`/v1/artworks/imageURL/${artworkId}`, {
    imageToConnect: image,
  });

const createExportService = (artworkIds, exportType, options) =>
  SimplifyApi.post(`/v1/exports/document/${exportType}`, { artworkIds, options });

const createCsvService = (artworkIds, options) => SimplifyApi.post("/v1/exports/csv", { ...options, artworkIds });

const shareArtworkService = (recipient, artworkIds, shareOptions, message) =>
  SimplifyApi.post("/v1/artworks/share", {
    recipient,
    artworkIds,
    shareOptions,
    message,
  });

const saveSharedArtworkService = (collectionId, artworkId) =>
  SimplifyApi.put(`/v1/artworks/share/${collectionId}`, { artworkIds: [artworkId] });

const createViewingRoomService = (artworkIds, options) =>
  SimplifyApi.post("/v1/viewingRoom", { artworkIds, ...options });

// SAGAS

export function* createArtworkSaga(payload) {
  try {
    let artwork;

    artwork = yield select((state) => selectArtworkById(state, payload.tempArtworkId));

    if (!artwork) {
      yield put(createArtworkLocally(payload));

      // Make cached images in IndexDB active
      // so the automatic cleanup will not remove them
      // before they are uploaded to the server
      if (payload.images) {
        for (const image of payload.images) {
          yield put(cacheImageActive(image.localId));
        }
      }

      yield all([put(addSelectedArtwork(payload.tempArtworkId)), put(replace("/artworks/details"))]);
      artwork = yield select((state) => selectArtworkById(state, payload.tempArtworkId));
    }

    const response = yield call(createArtworkService, artwork);

    if (artwork.images) {
      for (const image of artwork.images) {
        yield put(
          addActionToQueue(uploadArtworkImage(response.data.artworkId, image.localId, image), response.data.artworkId)
        );
      }
    }

    yield put(createArtworkSuccess(payload.tempArtworkId, response.data));

    RecordEventAnalytics(Events.NEW_ARTWORK, {
      [Properties.NUM_FILLED_FIELDS]: Object.keys(payload).length,
      [Properties.ARTIST]: payload.artist,
    });
  } catch (error) {
    yield put(createArtworkFailure());
    throw error;
  }
}

export function* createArtworkAndCopySaga(payload) {
  try {
    let artwork;

    artwork = yield select((state) => selectArtworkById(state, payload.tempArtworkId));

    if (!artwork) {
      yield put(createArtworkLocally(payload));

      // Make cached images in IndexDB active
      // so the automatic cleanup do not remove them
      // before they are uploaded to the server
      if (payload.images) {
        for (const image of payload.images) {
          yield put(cacheImageActive(image.localId));
        }
      }

      const copiedArtwork = _.omit(payload, ["tempArtworkId", "dateCreated", "images"]);
      const { artworkValues, ...restOfArtwork } = copiedArtwork;
      const initialValues = {
        ...artworkValues,
        ...restOfArtwork,
      };

      yield put(initialize("artworks.add", initialValues));

      createToast({ type: "success", ...Notifications.saveAndCopyArtworkSuccess });
      window.scrollTo({ top: 0 });

      artwork = yield select((state) => selectArtworkById(state, payload.tempArtworkId));
    }

    const response = yield call(createArtworkService, artwork);

    if (artwork.images) {
      for (const image of artwork.images) {
        yield put(
          addActionToQueue(uploadArtworkImage(response.data.artworkId, image.localId, image), response.data.artworkId)
        );
      }
    }

    yield put(createArtworkSuccess(payload.tempArtworkId, response.data));

    RecordEventAnalytics(Events.NEW_ARTWORK, {
      [Properties.NUM_FILLED_FIELDS]: Object.keys(payload).length,
      [Properties.ARTIST]: payload.artist,
    });
  } catch (error) {
    createToast({ type: "error", ...Notifications.saveAndNewArtworkFailure });
    yield put(createArtworkFailure());
    throw error;
  }
}

export function* createArtworkAndNewSaga(payload) {
  try {
    let artwork;

    artwork = yield select((state) => selectArtworkById(state, payload.tempArtworkId));

    if (!artwork) {
      yield put(createArtworkLocally(payload));

      // Make cached images in IndexDB active
      // so the automatic cleanup do not remove them
      // before they are uploaded to the server
      if (payload.images) {
        for (const image of payload.images) {
          yield put(cacheImageActive(image.localId));
        }
      }

      const initialValues = {
        collection: {
          id: payload.collection.id,
        },
        artist: payload.artist,
        currentOwner: payload.artworkValues.currentOwner,
        images: [],
      };

      yield put(initialize("artworks.add", initialValues));

      createToast({ type: "success", ...Notifications.saveAndNewArtworkSuccess });
      window.scrollTo({ top: 0 });

      artwork = yield select((state) => selectArtworkById(state, payload.tempArtworkId));
    }

    const response = yield call(createArtworkService, artwork);

    if (artwork.images) {
      for (const image of artwork.images) {
        yield put(
          addActionToQueue(uploadArtworkImage(response.data.artworkId, image.localId, image), response.data.artworkId)
        );
      }
    }

    yield put(createArtworkSuccess(payload.tempArtworkId, response.data));

    RecordEventAnalytics(Events.NEW_ARTWORK, {
      [Properties.NUM_FILLED_FIELDS]: Object.keys(payload).length,
      [Properties.ARTIST]: payload.artist,
    });
  } catch (error) {
    createToast({ type: "error", ...Notifications.saveAndNewArtworkFailure });
    yield put(createArtworkFailure());
    throw error;
  }
}

export function* uploadArtworkImageSaga(payload) {
  try {
    const { artworkId, imageLocalId, image } = payload;

    const imageFile = yield call(cachedDataUrlToFile, imageLocalId);

    if (imageFile) {
      // Get Upload URL
      const response = yield call(getArtworkImageUploadUrlService, artworkId);
      const { imageUploads } = response.data;
      const uploadURL = imageUploads[0].uploadURL;
      const previewURL = imageUploads[0].key;

      // const imageFile = yield call(cachedDataUrlToFile, imageLocalId);

      // PROBLEM
      // const watchUploadProgress = progressEvent => {
      //   let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //
      //   yield put(updateArtworkImageUploadProgress(artworkId, image.key, progress));
      // };

      // Upload image file to S3 bucket
      yield call(uploadArtworkImageService, uploadURL, imageFile, image.type, {});

      // Connect uploaded image file in S3 bucket to mongoDB database(Finish)
      const imageToConnect = {
        connectionId: image.connectionId,
        url: previewURL,
        description: image.description,
        sortIndex: image.sortIndex,
        dateCreated: image.dateCreated,
      };

      yield call(publishArtworkImageService, artworkId, imageToConnect);

      yield put(uploadArtworkImageSuccess(artworkId, image.connectionId, imageToConnect));

      yield put(removeCacheImage(image.localId));
    }
  } catch (error) {
    yield put(uploadArtworkImageFailure());
    throw error;
  }
}

export function* editArtworkSaga(payload) {
  try {
    yield put(editArtworkLocally(payload));

    // Make cached images in IndexDB active
    // so the automatic cleanup will not remove them
    // before they are uploaded to the server
    if (payload.images) {
      for (const image of payload.images) {
        if (!image.isFromServer) {
          yield put(cacheImageActive(image.localId));
        }
      }
    }

    const artwork = yield select((state) => selectArtworkById(state, payload.id));

    if (artwork.isFromServer) {
      const response = yield call(editArtworkService, artwork);

      if (artwork.images) {
        for (const image of artwork.images) {
          if (!image.isFromServer) {
            yield put(addActionToQueue(uploadArtworkImage(artwork.id, image.localId, image), artwork.id));
          }
        }
      }
    }

    yield put(editArtworkSuccess(artwork.id));

    RecordEventAnalytics(Events.EDIT_ARTWORK);
  } catch (error) {
    yield put(editArtworkFailure());
    throw error;
  }
}

function* editMultipleArtworksSaga(action) {
  const { artworkIds, multiEditArtworkData } = action.payload;
  yield put(startSubmit("artworks.multi-edit"));

  try {
    for (const artworkId of artworkIds) {
      const artwork = { id: artworkId, ...multiEditArtworkData };
      const cleanedArtwork = _.pickBy(artwork, (v) => v !== undefined);
      yield put(editArtworkLocally(cleanedArtwork));
      yield put(addActionToQueue(updateArtworkAfterMultiEdit(artworkId), artworkId));
    }

    yield put(stopSubmit("artworks.multi-edit"));

    const { artworkCollection, status, artworkValues, ...restOfData } = multiEditArtworkData;
    const initialValues = {
      ...artworkValues,
      collection: {
        id: artworkCollection,
      },
      status,
      ...restOfData,
    };

    createToast({ type: "success", ...Notifications.multiEditArtworksSuccess });

    yield put(initialize("artworks.multi-edit", initialValues));
  } catch (error) {
    console.log(error);
    createToast({ type: "error", ...Notifications.multiEditArtworksFailure });
  }
}

export function* updateArtworkAfterMultiEditSaga(payload) {
  try {
    const artwork = yield select((state) => selectArtworkById(state, payload.artworkId));

    if (artwork.isFromServer) {
      const response = yield call(editArtworkService, artwork);
    }

    yield put(editArtworkSuccess(artwork.id));
  } catch (error) {
    yield put(editArtworkFailure());
    throw error;
  }
}

export function* deleteArtworksSaga(action) {
  const { artworkIds } = action.payload;

  try {
    for (const artworkId of artworkIds) {
      yield put(addActionToQueue(deleteArtwork(artworkId), artworkId));
    }

    const collection = yield select(selectSelectedCollection);

    RecordEventAnalytics(Events.DELETE_COLLECTION_ARTWORKS, {
      [Properties.COLLECTION_TYPE]: collection.type,
      [Properties.NUM_ARTWORKS]: artworkIds.length,
    });
  } catch (error) {
    console.log(error);
  }
}

export function* deleteArtworkSaga(payload) {
  const { artworkId } = payload;
  try {
    const artworkForDelete = yield select((state) => selectArtworkById(state, artworkId));

    if (artworkForDelete.isFromServer) {
      yield call(deleteArtworkService, artworkId);

      if (artworkForDelete.images) {
        for (const image of artworkForDelete.images) {
          if (!image.isFromServer) yield put(removeCacheImage(image.localId));
        }
      }
    } else {
      if (artworkForDelete.images) {
        for (const image of artworkForDelete.images) {
          if (!image.isFromServer) yield put(removeCacheImage(image.localId));
        }
      }
    }

    yield put(deleteArtworkSuccess(artworkId));
  } catch (error) {
    yield put(deleteArtworkFailure(artworkId));
    throw error;
  }
}

function* createExportSaga(action) {
  yield put(startSubmit("exports"));
  const { exportType, options } = action.payload;
  const artworkIds = yield select(selectSelectedArtworkIds);

  try {
    const response = yield call(createExportService, artworkIds, exportType, options);

    const { path, data } = response.data;

    const splitData = data.split(".");

    if (splitData.length > 0 && splitData[0] === "pdf") {
      yield call(
        saveAs,
        `${process.env.REACT_APP_S3_URL}${path}`,
        `${exportType}-${moment(new Date()).format("DD/MM/YYYY")}`
      );
    }

    createToast({ type: "success", ...Notifications.exportSuccess });
    yield all([put(closeModal()), put(createExportSuccess())]);

    const analyticsProperties = {
      [Properties.NUM_ARTWORKS]: artworkIds.length,
      [Properties.TYPE]: exportType,
    };

    RecordEventAnalytics(Events.NEW_PDF_EXPORT, analyticsProperties);
  } catch (error) {
    createToast({ type: "error", ...Notifications.exportFailed });

    yield all([put(closeModal()), put(createExportFailure())]);

    const analyticsProperties = {
      [Properties.NUM_ARTWORKS]: artworkIds.length,
      [Properties.TYPE]: exportType,
    };
    RecordEventAnalytics(Events.FAILED_PDF_EXPORT, analyticsProperties);
  } finally {
    yield put(stopSubmit("exports"));
  }
}

function* createCsvSaga(action) {
  yield put(startSubmit("exports"));
  const { options } = action.payload;
  const artworkIds = yield select(selectSelectedArtworkIds);

  try {
    const response = yield call(createCsvService, artworkIds, options);

    const csv = "data:text/csv;charset=utf-16," + escape(response.data);

    yield call(saveAs, csv, `${options.title}-list.csv`);

    createToast({ type: "success", ...Notifications.exportSuccess });
    yield all([put(closeModal()), put(createCsvSuccess())]);

    const analyticsProperties = {
      [Properties.NUM_ARTWORKS]: artworkIds.length,
      [Properties.TYPE]: "csv",
    };
    RecordEventAnalytics(Events.NEW_PDF_EXPORT, analyticsProperties);
  } catch (error) {
    createToast({ type: "error", ...Notifications.exportFailed });
    yield all([put(closeModal()), put(createCsvFailure())]);

    const analyticsProperties = {
      [Properties.NUM_ARTWORKS]: artworkIds.length,
      [Properties.TYPE]: "csv",
    };
    RecordEventAnalytics(Events.FAILED_PDF_EXPORT, analyticsProperties);
  } finally {
    yield put(stopSubmit("exports"));
  }
}

function* shareArtworkSaga(action) {
  const { email, sharePrice, message } = action.payload;

  const recipient = { email };
  const shareOptions = { sharePrice };
  try {
    const artworkIds = yield select(selectSelectedArtworkIds);

    const response = yield call(shareArtworkService, recipient, artworkIds, shareOptions, message);

    createToast({ type: "success", ...Notifications.shareSuccess });
    yield all([put(closeModal()), put(shareArtwork.success(response.data))]);

    RecordEventAnalytics(Events.SHARE_ARTWORKS, {
      [Properties.NUM_ARTWORKS]: artworkIds.length,
      [Properties.HAS_MESSAGE]: (message !== null).toString(),
    });
  } catch (error) {
    createToast({ type: "error", ...Notifications.shareFailure });
    yield all([put(closeModal()), put(shareArtwork.failure())]);
  }
}

export function* saveSharedArtworkSaga(payload) {
  const { collectionId, artworkId } = payload;
  try {
    yield put(saveSharedArtworkLocally(collectionId, artworkId));

    yield call(saveSharedArtworkService, collectionId, artworkId);

    createToast({ type: "success", ...Notifications.saveShareArtworkSuccess });
    yield put(saveSharedArtworkSuccess(artworkId));

    const collection = yield select(selectSelectedCollection);

    RecordEventAnalytics(Events.SAVE_SHARED_ARTWORKS, {
      [Properties.COLLECTION_TYPE]: collection.type,
      [Properties.NUM_ARTWORKS]: 1,
    });
  } catch (error) {
    createToast({ type: "error", ...Notifications.saveShareArtworkFailure });
    yield all([put(saveSharedArtworkFailure())]);
    throw error;
  }
}

function* createViewingRoomSaga(action) {
  try {
    const artworkIds = yield select(selectSelectedArtworkIds);

    const response = yield call(createViewingRoomService, artworkIds, action.payload);

    yield all([put(closeModal()), put(createViewingRoom.success(response.data))]);
  } catch (error) {
    yield all([put(closeModal()), put(createViewingRoom.failure())]);
  }
}

function* archiveSaga() {
  yield takeEvery(EDIT_MULTIPLE_ARTWORKS, editMultipleArtworksSaga);
  yield takeEvery(DELETE_ARTWORKS, deleteArtworksSaga);
  yield takeEvery(CREATE_EXPORT, createExportSaga);
  yield takeEvery(CREATE_CSV, createCsvSaga);
  yield takeEvery(shareArtwork.REQUEST, shareArtworkSaga);
  yield takeEvery(createViewingRoom.REQUEST, createViewingRoomSaga);
}

export default archiveSaga;
