import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selectors";
import { OrganisationSettingsForm } from "../forms";
import { Box } from "../fundamentals";

const OrganisationSettingsScreen = () => {
  const user = useSelector(selectUser);
  const { logoUrl, ...organisation } = user.organisation;

  const initialValues = logoUrl
    ? {
        logo: [{ url: logoUrl, description: "", sortIndex: 0, isFromServer: logoUrl !== null ? true : false }],
        ...organisation
      }
    : { ...organisation };

  return (
    <Box flex="1">
      <OrganisationSettingsForm initialValues={initialValues} />
    </Box>
  );
};

export default OrganisationSettingsScreen;
