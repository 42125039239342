import React from "react";
import { Stack, Typography } from "@mui/material";
import { Info } from "react-feather";

export const RenderError = ({ meta: { touched, error }, ...props }) => {
  if (touched && error) {
    return (
      <Stack direction="row" mb="5px" ml="9.5rem" {...props}>
        <Info size={"12px"} style={{ alignSelf: "center", paddingRight: "5px", color: "#FF3737" }} />

        <Typography sx={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400, lineHeight:'16px', color: "#FF3737" }}>
          {error}
        </Typography>
      </Stack>
    );
  }
  return <></>;
};
