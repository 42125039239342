import { Box, Button, Stack, Typography } from "@mui/material";
import { Messages } from "../../localisation/messages";
import { WithRequireAccess } from "../hocs";
import { NavigationBar } from "../react-material/NavigationBar";
import { ArrowLeft, Mail } from "react-feather";
import { ButtonLabels } from "../../localisation";
import { useHistory } from "react-router-dom";

const actionButtonStyle = {
  color: "#5E54FF",
  borderRadius: "16px",
  fontSize: "16px",
  fontWeight: "400",
  border: "1px solid #5E54FF",
  width: "121px",
  height: "32px",
};

const ComingSoonScreen = ({ message }) => {
  const history = useHistory();

  const onClickEmailHandler = () => {
    window.open("mailto:contact@simplify.art?subject=User Input/Feedback for the New Feature");
  };
  return (
    <Box width="98%">
      <NavigationBar />

      <Box height="568px" display="flex" alignItems="center" justifyContent="center">
        <Stack direction="column" alignItems="center">
          <Box
            component="img"
            alt="coming soon"
            src={process.env.PUBLIC_URL + "/images/icons/brand-icons/coming-soon.svg"}
            width="218px"
            height="180px"
          ></Box>
          <Typography variant="h2" sx={{ marginTop: "20px" }}>
            {Messages.comingSoon}
          </Typography>
          <Typography marginTop="8px" textAlign="center" width="396px" fontSize="16px" lineHeight="24px">
            {message}
          </Typography>
          <Stack justifyContent="center" direction="row" spacing={1.8} sx={{ marginTop: "32px" }}>
            <Button
              startIcon={<ArrowLeft style={{ width: "20px", height: "20px", left: "5px", position: "relative" }} />}
              sx={{ ...actionButtonStyle, fontWeight: "500" }}
              onClick={() => history.goBack()}
            >
              {ButtonLabels.goBack}
            </Button>

            <Button
              startIcon={<Mail style={{ width: "20px", height: "20px", left: "5px", position: "relative" }} />}
              sx={{ ...actionButtonStyle, bgcolor: "#5E54FF", color: "white", "&:hover": { bgcolor: "#5E54FF" } }}
              onClick={onClickEmailHandler}
            >
              {ButtonLabels.emailUs}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default WithRequireAccess(ComingSoonScreen, 1);
