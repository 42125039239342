import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { Field, change } from "redux-form";
import { useDropzone } from "react-dropzone";
import DropboxChooser from "react-dropbox-chooser";
import { openModal, cacheImage, removeCacheImage } from "../../store/actions";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Box, Flex, Text, ImageWithCache, Icon } from "../fundamentals";
import { Button, Input } from "../elements";
import { ButtonLabels, UiLabels, InputLabels } from "../../localisation";
import { dataURLtoBlob } from "../../utils/image";

const Handle = SortableHandle(() => (
  <Box pr="m" minWidth="24px">
    <Flex cursor="move">
      <Icon icon="MoreVertical" size="16" />
      <Icon icon="MoreVertical" size="16" ml="-10px" />
    </Flex>
  </Box>
));

const SortableImageItem = SortableElement(
  ({
    field,
    fieldIndex,
    image,
    urlSuffix,
    handleOnRemoveImage,
    handleOnChangeImageDescription,
    handleOnSetAsCover,
    disable, //SortedElement does not allow the "disabled" prop to be passed into it
    isLogoUpload,
  }) => {
    return (
      <Flex key={image.key} alignItems="center" bg="grey.10" mb="xxxs" p="xxs" pl="m" pr="m">
        {!disable && <Handle />}

        <ImageWithCache image={image} urlSuffix={urlSuffix} width="100px" height="74px" isLogo={isLogoUpload} />

        {!isLogoUpload && (
          <Flex flexDirection="column" flex="1" pl="l" mt="xxs">
            <Input
              label={InputLabels.description}
              focusBackground="grey.0"
              input={{
                value: image.description,
                onChange: (event) => handleOnChangeImageDescription(event, field),
              }}
              disabled={disable}
              hideError
            />
          </Flex>
        )}

        {!disable && fieldIndex === 0 && (
          <Flex ml="xxxl" mr="xl" pl="none" pr="none" width="85px" justifyContent="flex-end">
            <Icon size="18" color="black" icon="Check" pr="xxxs" />
            <Text size="small" color="black">
              {UiLabels.cover}
            </Text>
          </Flex>
        )}

        {!disable && fieldIndex !== 0 && (
          <Button
            buttonStyle="text"
            size="small"
            pl="xxxl"
            pr="xl"
            alignSelf="stretch"
            onClick={() => handleOnSetAsCover(image)}
            preventDefault
          >
            {ButtonLabels.setAsCover}
          </Button>
        )}

        {!disable && (
          <Flex alignItems="center">
            <Button
              buttonStyle="text"
              size="medium"
              icon="X"
              color="grey.80"
              alignSelf="center"
              onClick={() => handleOnRemoveImage(image)}
              preventDefault
            />
          </Flex>
        )}
      </Flex>
    );
  }
);

const SortableImageList = SortableContainer(
  ({
    fields,
    urlSuffix,
    handleOnRemoveImage,
    handleOnSetAsCover,
    handleOnChangeImageDescription,
    disabled,
    isLogoUpload,
  }) => {
    return (
      <Box>
        {fields.map((field, index) => {
          const image = fields.get(index);
          return (
            <Field
              key={image.key}
              index={index}
              sortIndex={index}
              name={field}
              field={field}
              fieldIndex={index}
              image={image}
              disable={disabled}
              urlSuffix={urlSuffix}
              handleOnRemoveImage={handleOnRemoveImage}
              handleOnChangeImageDescription={handleOnChangeImageDescription}
              handleOnSetAsCover={handleOnSetAsCover}
              isLogoUpload={isLogoUpload}
              component={SortableImageItem}
            />
          );
        })}
      </Box>
    );
  }
);

const ImageUpload = ({
  form,
  fields,
  urlSuffix,
  maxImages,
  width,
  emptyHeight,
  label,
  minimal,
  contained,
  disabled,
  withDropbox,
  isLogoUpload,
}) => {
  const dispatch = useDispatch();
  const onDropForLogo = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        const key = uuid();
        const localId = uuid();
        const connectionId = uuid();
        const sortIndex = 0;

        const image = {
          key,
          localId,
          connectionId,
          name: file.name,
          path: file.path,
          type: file.type,
          url: "",
          description: "",
          sortIndex: sortIndex,
          dateCreated: new Date().toISOString(),
          isFromServer: false,
          isModified: false,
          isActive: true,
        };

        fields.remove(sortIndex);
        fields.insert(sortIndex, image);

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          dispatch(cacheImage(localId, reader.result, URL.createObjectURL(file)));
        };
      });
    },
    [fields]
  );
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        const key = uuid();
        const localId = uuid();
        const connectionId = uuid();
        const sortIndex = acceptedFiles.length > 1 ? fields.length + index : fields.length;

        const image = {
          key,
          localId,
          connectionId,
          name: file.name,
          path: file.path,
          type: file.type,
          url: "",
          description: "",
          sortIndex: sortIndex,
          dateCreated: new Date().toISOString(),
          isFromServer: false,
          isModified: false,
          isActive: true,
        };

        fields.insert(sortIndex, image);

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          dispatch(cacheImage(localId, reader.result, URL.createObjectURL(file)));
        };
      });
    },
    [fields]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: !isLogoUpload ? onDrop : onDropForLogo,
    maxFiles: maxImages ? maxImages : 0,
    accept: "image/jpeg, image/png",
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const handleOnAddImagesFromDropbox = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "arraybuffer";
        xhr.open("GET", file.link, true);

        const key = uuid();
        const localId = uuid();
        const connectionId = uuid();
        const sortIndex = acceptedFiles.length > 1 ? fields.length + index : fields.length;

        const image = {
          key,
          localId,
          connectionId,
          name: file.name,
          path: file.path,
          type: file.type,
          url: "",
          description: "",
          sortIndex: sortIndex,
          dateCreated: new Date().toISOString(),
          isFromServer: false,
          isModified: false,
          isActive: true,
        };

        fields.insert(sortIndex, image);

        xhr.onabort = () => console.log("file reading was aborted");
        xhr.onerror = () => console.log("file reading has failed");
        xhr.onload = function (e) {
          // Create an array of 8-bit unsigned integers
          var arr = new Uint8Array(this.response);

          // String.fromCharCode returns a 'string' from the specified sequence of Unicode values
          let raw = "";
          let i;
          let j;
          let subArray;
          let chunk = 5000;
          for (i = 0, j = arr.length; i < j; i += chunk) {
            subArray = arr.subarray(i, i + chunk);
            raw += String.fromCharCode.apply(null, subArray);
          }

          //btoa() creates a base-64 encoded ASCII string from a String object
          var b64 = btoa(raw);

          var dataType = "yourImageDataType";
          //ta-da your image data url!
          var dataURL = "data:image/" + dataType + ";base64," + b64;

          const imageBlob = dataURLtoBlob(dataURL);
          dispatch(cacheImage(localId, dataURL, URL.createObjectURL(imageBlob)));
        };

        xhr.send();
      });
    },
    [fields]
  );

  // useEffect(() => () => {
  //   // Make sure to revoke the data uris to avoid memory leaks
  //   fields.getAll().forEach(image => URL.revokeObjectURL(image.url));
  // });

  const handleOnPreviewImage = () => {
    dispatch(openModal("image_viewer"));
  };

  const handleOnSetAsCover = (image) => {
    fields.forEach((field, index) => {
      dispatch(
        change(form, field, ({ sortIndex, ...fieldValue }) => {
          if (image.sortIndex === index) return { ...fieldValue, sortIndex: 0 };
          else if (image.sortIndex < index) return { ...fieldValue, sortIndex: sortIndex };
          else return { ...fieldValue, sortIndex: sortIndex + 1 };
        })
      );
    });
    fields.move(image.sortIndex, 0);
  };

  const handleOnRemoveImage = async (image) => {
    dispatch(removeCacheImage(image.localId));

    if (image.sortIndex + 1 !== fields.length) {
      fields.forEach((field, index) => {
        if (image.sortIndex === index) {
          return;
        }

        dispatch(
          change(form, field, ({ sortIndex, ...fieldValue }) => {
            if (sortIndex === 0) return { ...fieldValue, sortIndex: 0 };
            else if (image.sortIndex > index) return { ...fieldValue, sortIndex: sortIndex };
            else return { ...fieldValue, sortIndex: sortIndex - 1 };
          })
        );
      });
    }

    fields.remove(image.sortIndex);
  };

  const handleOnChangeImageDescription = (event, field) => {
    dispatch(
      change(form, field, ({ description, ...fieldValue }) => {
        return { ...fieldValue, description: event };
      })
    );
  };

  const handleOnSortEnd = ({ oldIndex, newIndex }) => {
    fields.forEach((field, index) => {
      dispatch(
        change(form, field, ({ sortIndex, ...fieldValue }) => {
          if (sortIndex === oldIndex) return { ...fieldValue, sortIndex: newIndex };
          else if (sortIndex <= oldIndex && sortIndex >= newIndex) return { ...fieldValue, sortIndex: sortIndex + 1 };
          else if (sortIndex >= oldIndex && sortIndex <= newIndex) return { ...fieldValue, sortIndex: sortIndex - 1 };
          else return { ...fieldValue, sortIndex: sortIndex };
        })
      );
    });
    fields.move(oldIndex, newIndex);
  };

  return (
    <Flex flexDirection="column" width={width} mb="m">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={emptyHeight || "200px"}
          bg="grey.20"
        >
          {!minimal && <Icon icon="Upload" size="30" strokeWidth="1.5" color="grey.100" />}
          <Text size="small" color="grey.90" m="s" mt="xxs">
            {"Drag 'n' drop some files here, or click to select files"}
          </Text>
          {contained && !disabled && (
            <Flex>
              <Button buttonStyle="secondary" size="small" icon="Plus" alignSelf="center" onClick={open} preventDefault>
                {ButtonLabels.addImages}
              </Button>

              {withDropbox && (
                <DropboxChooser
                  appKey={process.env.REACT_APP_DROPBOX_APP_KEY}
                  success={handleOnAddImagesFromDropbox}
                  cancel={() => {}}
                  extensions={[".jpg", ".jpeg", ".png"]}
                  linkType="direct"
                  multiselect={true}
                >
                  <Button buttonStyle="secondary" size={"small"} icon="Box" ml="xs" preventDefault>
                    {ButtonLabels.addDropbox}
                  </Button>
                </DropboxChooser>
              )}
            </Flex>
          )}
        </Flex>
      </div>

      <SortableImageList
        fields={fields}
        handleOnRemoveImage={handleOnRemoveImage}
        handleOnSetAsCover={handleOnSetAsCover}
        onSortEnd={handleOnSortEnd}
        handleOnChangeImageDescription={handleOnChangeImageDescription}
        urlSuffix={urlSuffix}
        lockAxis="y"
        lockToContainerEdges
        lockOffset={["0%", "100%"]}
        disabled={disabled}
        useDragHandle
        isLogoUpload={isLogoUpload}
      />
    </Flex>
  );
};

export { ImageUpload };
