import React, { useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow, Typography, Stack, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Edit2, Trash2 } from "react-feather";
import { replaceSelectedContact, openModal, addSelectedContact, removeSelectedContact } from "../../../store/actions";
import { selectSelectedContactIds } from "../../../store/selectors";
import DoneIcon from "@mui/icons-material/Done";

const ContactItem = ({ contact, index, deleteContactRow }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hoverContact, handleOnHover] = useState({});
  const selectedContactIds = useSelector(selectSelectedContactIds);
  const isContactSelected = _.includes(selectedContactIds, contact.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  const handleOnContactClick = () => {
    dispatch(replaceSelectedContact(contact.id));
    dispatch(openModal("preview_contact"));
  };

  const handleOnEditContactClick = () => {
    dispatch(replaceSelectedContact(contact.id));
    history.push("/contacts/edit");
  };

  const handleOnDeleteContactClick = () => {
    deleteContactRow([contact.id]);
    dispatch(openModal("delete_contact_row"));
  };

  const handleOnContactSelect = (event) => {
    event.stopPropagation();

    if (isContactSelected) {
      dispatch(removeSelectedContact(contact.id));
    } else {
      dispatch(addSelectedContact(contact.id));
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isContactSelected}
      tabIndex={-1}
      key={contact.id}
      selected={isContactSelected}
      sx={{
        cursor: "pointer",
        "&.MuiTableRow-root": {
          backgroundColor: isContactSelected ? "#EFEFF0" : "#F4F4F5",
          "&:hover": { backgroundColor: "#EFEFF0" },
        },
      }}
      onMouseEnter={() => handleOnHover(contact)}
      onMouseLeave={() => handleOnHover({})}
    >
      <TableCell padding="checkbox">
        {(hoverContact.id === contact.id || isContactSelected) && (
          <Box
            sx={{
              borderRadius: "4px",
              width: "16px",
              height: "16px",
              backgroundColor: isContactSelected && "#5E54FF",
              border: !isContactSelected && "2px solid #95939C",
              p: isContactSelected && ".1rem",
              ml: ".7rem",
            }}
            checked={isContactSelected}
            onClick={handleOnContactSelect}
          >
            {isContactSelected && (
              <DoneIcon
                style={{
                  position: "relative",
                  top: "-1px",
                  left: "0px",
                  color: "white",
                  borderRadius: "4px",
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </Box>
        )}
      </TableCell>
      <TableCell
        onClick={handleOnContactClick}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        sx={{ color: isContactSelected && "#5E54FF" }}
      >
        {contact?.firstName}
      </TableCell>
      <TableCell onClick={handleOnContactClick} sx={{ color: isContactSelected && "#5E54FF" }}>
        {contact?.lastName}
      </TableCell>
      <TableCell onClick={handleOnContactClick} sx={{ color: isContactSelected && "#5E54FF" }}>
        {contact?.organisation}
      </TableCell>
      <TableCell onClick={handleOnContactClick} sx={{ color: isContactSelected && "#5E54FF" }}>
        {contact?.email}
      </TableCell>
      <TableCell onClick={handleOnContactClick} sx={{ color: isContactSelected && "#5E54FF" }}>
        {contact?.address?.country}
      </TableCell>
      <TableCell onClick={handleOnContactClick}>
        <Stack direction="row">
          {contact?.types?.map((type) => (
            <Typography
              sx={{
                bgcolor: "#DEE1FF",
                p: "4px 8px",
                height: "24px",
                borderRadius: "4px",
                mr: "4px",
                fontSize: "12px",
                fontWeight: 500,
                alignItems: "center",
                display: "flex",
              }}
            >
              {type}
            </Typography>
          ))}
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} justifyContent="center">
          <IconButton onClick={handleOnEditContactClick}>
            <Edit2 style={{ width: "20px", height: "20px", color: contact.id === hoverContact.id && "#5E54FF" }} />
          </IconButton>
          <IconButton onClick={handleOnDeleteContactClick}>
            <Trash2 style={{ width: "20px", height: "20px", color: contact.id === hoverContact.id && "#5E54FF" }} />
          </IconButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export { ContactItem };
