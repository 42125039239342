import { Box, Modal as MuiModal } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeModal } from "../../../store/actions";
import IconButton from "@mui/material/IconButton";
import { XCircle } from "react-feather";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  p: 6,
};

const Modal = ({ children, id, modal, modalProps, closeModal }) => {
  return (
    <MuiModal open={id === modal} onClose={closeModal} BackdropProps={{ sx: { bgcolor: "rgba(243, 244, 255, 0.6)" } }}>
      <Box sx={boxStyle}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            component="span"
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: "-28px",
              right: "-28px",
              color: "#5E54FF",
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <XCircle />
          </IconButton>
          {React.cloneElement(children, modalProps)}
        </Box>
      </Box>
    </MuiModal>
  );
};

function mapStateToProps(state) {
  const {
    ui: { modal, modalProps },
  } = state;
  return { modal, modalProps };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeModal: closeModal }, dispatch);
}

const ConnectedModal = connect(mapStateToProps, mapDispatchToProps)(Modal);

export { ConnectedModal as Modal };
