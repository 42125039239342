import produce from "immer";
import {
  ADD_SELECTED_ARTWORK,
  REMOVE_SELECTED_ARTWORK,
  REPLACE_SELECTED_ARTWORK,
  CHANGE_SELECTED_COLLECTION,
  CHANGE_SELECTED_CATEGORY,
  SELECT_ALL_ARTWORKS,
  CLEAR_ALL_SELECTED_ARTWORKS,
  CREATE_ARTWORK_SUCCESS,
  SIGN_OUT_SUCCESS,
  ADD_SELECTED_CONTACT,
  REMOVE_SELECTED_CONTACT,
  REPLACE_SELECTED_CONTACT,
  SELECT_ALL_CONTACTS,
  CLEAR_ALL_SELECTED_CONTACTS,
} from "../actions/types";

const SELECTION_INITIAL_STATE = {
  collection: "",
  category: "",
  artworks: [],
  artists: [],
  contacts: [],
};

const selectionReducer = (state = SELECTION_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case ADD_SELECTED_ARTWORK:
        return addSelectedArtwork(draftState, action);
      case REMOVE_SELECTED_ARTWORK:
        return removeSelectedArtwork(draftState, action);
      case REPLACE_SELECTED_ARTWORK:
        return replaceSelectedArtwork(draftState, action);
      case CHANGE_SELECTED_COLLECTION:
        return changeSelectedCollection(draftState, action);
      case CHANGE_SELECTED_CATEGORY:
        return changeSelectedCategory(draftState, action);
      case SELECT_ALL_ARTWORKS:
        return selectAllArtworks(draftState, action);
      case CLEAR_ALL_SELECTED_ARTWORKS:
        return clearAllSelectedArtworks(draftState, action);
      case CREATE_ARTWORK_SUCCESS:
        return createArtworkSuccess(draftState, action);
      case ADD_SELECTED_CONTACT:
        return addSelectedContact(draftState, action);
      case REMOVE_SELECTED_CONTACT:
        return removeSelectedContact(draftState, action);
      case REPLACE_SELECTED_CONTACT:
        return replaceSelectedContact(draftState, action);
      case SELECT_ALL_CONTACTS:
        return selectAllContacts(draftState, action);
      case CLEAR_ALL_SELECTED_CONTACTS:
        return clearAllSelectedContacts(draftState, action);
      case SIGN_OUT_SUCCESS:
        return SELECTION_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const addSelectedArtwork = (draftState, action) => {
  const { artworkId } = action.payload;
  if (!draftState.artworks.includes(artworkId)) draftState.artworks.push(artworkId);
};

const removeSelectedArtwork = (draftState, action) => {
  const index = draftState.artworks.findIndex((artworkId) => artworkId === action.payload.artworkId);
  if (index !== -1) draftState.artworks.splice(index, 1);
};

const replaceSelectedArtwork = (draftState, action) => {
  draftState.artworks = [];
  draftState.artworks.push(action.payload.artworkId);
};

const changeSelectedCollection = (draftState, action) => {
  draftState.collection = action.payload.collectionId;
};

const changeSelectedCategory = (draftState, action) => {
  draftState.category = action.payload.category;
};

const selectAllArtworks = (draftState, action) => {
  draftState.artworks = action.payload.artworkIds;
};

const clearAllSelectedArtworks = (draftState, action) => {
  draftState.artworks = [];
};

const createArtworkSuccess = (draftState, action) => {
  const index = draftState.artworks.findIndex((artworkId) => artworkId === action.payload.tempArtworkId);
  if (index !== -1) draftState.artworks[index] = action.payload.artworkId;
};

const addSelectedContact = (draftState, action) => {
  const { contactId } = action.payload;
  if (!draftState.contacts.includes(contactId)) draftState.contacts.push(contactId);
};

const removeSelectedContact = (draftState, action) => {
  const index = draftState.contacts.findIndex((contactId) => contactId === action.payload.contactId);
  if (index !== -1) draftState.contacts.splice(index, 1);
};

const replaceSelectedContact = (draftState, action) => {
  draftState.contacts = [];
  draftState.contacts.push(action.payload.contactId);
};

const selectAllContacts = (draftState, action) => {
  draftState.contacts = action.payload.contactIds;
};

const clearAllSelectedContacts = (draftState, action) => {
  draftState.contacts = [];
};

export default selectionReducer;
