import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { WithRequireAccess } from "../hocs";
import { Headings, Messages, LinkLabels, Instructions } from "../../localisation";

import { Box, Flex, Text, Image, Link } from "../fundamentals";

const StyledExpiredPaymentSuccessScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ExpiredPaymentSuccessScreen = ({ theme }) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => history.replace("/artworks"), 5000);
  }, []);

  return (
    <StyledExpiredPaymentSuccessScreen background={theme.backgroundColor}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image src={process.env.PUBLIC_URL + "/images/brand-identity/logo-text.png"} alt="logo" width="184px" p="l" />
        <Box display="flex" alignItems="center">
          <Text size="heading3" mr="s">
            {Instructions.needHelp}
          </Text>
          <Link to="/login" replace mr="xl" isRound="true" px="xs" py="xxxs">
            {LinkLabels.emailUs}
          </Link>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
        <Box width="505px" height="348px" bg="white" p="xxl" boxShadow="medium" borderRadius="20px">
          <Flex flexDirection="column" alignItems="center">
            <Text size="heading1" fontWeight="700" textAlign="center" mb="l" width="317px">
              {Headings.creditCardUpdated}
            </Text>
            <Text size="small" fontWeight="400" textAlign="center" width="300px" mb="s">
              {Messages.welcomeRedirectText}
            </Text>
            <Text size="small" fontWeight="400" textAlign="center" width="250px">
              {Messages.welcomeNotRedirectedText}
              <Link to="/artworks" replace>
                {LinkLabels.here}.
              </Link>
            </Text>
          </Flex>
        </Box>
      </Box>
    </StyledExpiredPaymentSuccessScreen>
  );
};

export default WithRequireAccess(withTheme(ExpiredPaymentSuccessScreen));
