import axios from "axios";
import { replace } from "connected-react-router";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { RecordEventAnalytics } from "../../analytics";
import { Events } from "../../analytics/types";
import { cachedDataUrlToFile, SimplifyApi } from "../../utils";
import { addActionToQueue, addSelectedContact, cacheImageActive, removeCacheImage } from "../actions";
import {
  createContactLocally,
  createContactSuccess,
  createContactFailure,
  deleteContact,
  deleteContactFailure,
  deleteContactSuccess,
  editContactFailure,
  editContactLocally,
  editContactSuccess,
  uploadContactImage,
  uploadContactImageSuccess,
  uploadContctImageFailure,
  downloadContactsFailure,
  downloadContactsSuccess,
} from "../actions/ContactActions";
import { DELETE_CONTACTS, DOWNLOAD_CONTACTS } from "../actions/types";
import { selectContactById } from "../selectors/contactSelector";

const createContactService = (contact) => {
  return SimplifyApi.post("/v1/contacts", { ...contact });
};

const downloadContactService = () => {
  return SimplifyApi.get("/v1/contacts");
};

const getContactImageUploadUrlService = (contactId) => SimplifyApi.get(`/v1/contacts/imageURLs/${contactId}/1`);

const uploadContactImageService = (uploadURL, imageFile, imageType, watchUploadProgress) =>
  axios.put(uploadURL, imageFile, {
    headers: { "Cache-Control": "max-age: 31536000, immutable", "Content-Type": imageType },
    // watchUploadProgress
  });

const publishContactImageService = (contactId, image) =>
  SimplifyApi.put(`/v1/contacts/imageURL/${contactId}`, {
    imageToConnect: image,
  });

const editContactService = ({ id, lastModified, ...contact }) => {
  const data = {
    ...contact,
    id,
    lastModified,
  };

  return SimplifyApi.patch("/v1/contacts", { contact: data });
};

const deleteContactService = (contactId) => SimplifyApi.delete(`/v1/contacts/${contactId}`);

export function* createContactSaga(payload) {
  try {
    let contact;

    contact = yield select((state) => selectContactById(state, payload.tempContactId));

    if (!contact) {
      yield put(createContactLocally(payload));

      // Make cached image in IndexDB active
      // so the automatic cleanup will not remove them
      // before they are uploaded to the server
      if (payload.image) {
        for (const image of payload.image) {
          yield put(cacheImageActive(image.localId));
        }
      }

      yield all([put(addSelectedContact(payload.tempContactId)), put(replace("/contacts"))]);
      contact = yield select((state) => selectContactById(state, payload.tempContactId));
    }
    const response = yield call(createContactService, contact);

    if (contact.image) {
      yield put(
        addActionToQueue(
          uploadContactImage(response.data.contactId, contact.image.localId, contact.image),
          response.data.contactId
        )
      );
    }

    yield put(createContactSuccess(payload.tempContactId, response.data));
  } catch (error) {
    yield put(createContactFailure());
    throw error;
  }
}

export function* uploadContactImageSaga(payload) {
  try {
    const { contactId, imageLocalId, image } = payload;

    const imageFile = yield call(cachedDataUrlToFile, imageLocalId);

    if (imageFile) {
      // Get Upload URL
      const response = yield call(getContactImageUploadUrlService, contactId);
      const { imageUploads } = response.data;
      const uploadURL = imageUploads[0].uploadURL;
      const previewURL = imageUploads[0].key;

      // Upload image file to S3 bucket
      yield call(uploadContactImageService, uploadURL, imageFile, image.type, {});

      // Connect uploaded image file in S3 bucket to mongoDB database(Finish)
      const imageToConnect = {
        connectionId: image.connectionId,
        url: previewURL,
        description: image.description,
        sortIndex: image.sortIndex,
        dateCreated: image.dateCreated,
      };

      yield call(publishContactImageService, contactId, imageToConnect);

      yield put(uploadContactImageSuccess(contactId, image.connectionId, imageToConnect));

      yield put(removeCacheImage(image.localId));
    }
  } catch (error) {
    yield put(uploadContctImageFailure());
    throw error;
  }
}

export function* editContactSaga(payload) {
  try {
    yield put(editContactLocally(payload));

    // Make cached image in IndexDB active
    // so the automatic cleanup will not remove them
    // before they are uploaded to the server
    if (payload.image) {
      if (!payload.image.isFromServer) {
        yield put(cacheImageActive(payload.image.localId));
      }
    }

    const contact = yield select((state) => selectContactById(state, payload.id));

    if (contact.isFromServer) {
      const response = yield call(editContactService, contact);
      if (contact.image) {
        if (!contact.image.isFromServer) {
          yield put(addActionToQueue(uploadContactImage(contact.id, contact.image.localId, contact.image), contact.id));
        }
      }
    }

    yield put(editContactSuccess(contact.id));

    RecordEventAnalytics(Events.EDIT_CONTACT);
  } catch (error) {
    yield put(editContactFailure());
    throw error;
  }
}

export function* deleteContactsSaga(action) {
  const { contactIds } = action.payload;

  try {
    for (const contactId of contactIds) {
      yield put(addActionToQueue(deleteContact(contactId), contactId));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* deleteContactSaga(payload) {
  const { contactId } = payload;
  try {
    const contactForDelete = yield select((state) => selectContactById(state, contactId));

    if (contactForDelete.isFromServer) {
      yield call(deleteContactService, contactId);

      if (contactForDelete.image) {
        if (!contactForDelete.image.isFromServer) yield put(removeCacheImage(contactForDelete.image.localId));
      }
    } else {
      if (contactForDelete.image) {
        if (!contactForDelete.image.isFromServer) yield put(removeCacheImage(contactForDelete.image.localId));
      }
    }

    yield put(deleteContactSuccess(contactId));
  } catch (error) {
    console.log(error);
    yield put(deleteContactFailure(contactId));
    throw error;
  }
}

export function* downloadContacts() {
  try {
    const { data } = yield call(downloadContactService);
    yield put(downloadContactsSuccess(data.contacts));
  } catch (error) {
    yield put(downloadContactsFailure());
  }
}

function* contactSaga() {
  yield takeEvery(DOWNLOAD_CONTACTS, downloadContacts);
  yield takeEvery(DELETE_CONTACTS, deleteContactsSaga);
}

export default contactSaga;
