import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Text } from "../../fundamentals";

const discountStyle = {
  "&:hover": {
    color: "white",
  },
};

const billingStyle = {
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: "600",
  textAlign: "center",
  letterSpacing: "2px",
};

const boxStyle = (selected) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${selected ? "#5E54FF" : "black"}`,
    borderRadius: "15px",
    width: "130px",
    height: "100px",
    cursor: "pointer",
    backgroundColor: selected ? "#5E54FF" : undefined,
    color: selected ? "white" : undefined,
    marginRight: "24px",
    boxShadow: selected && "0px 0px 25px rgba(94, 84, 255, 0.5)",
    "&:hover": {
      color: "white",
      border: `2px solid #5E54FF`,
      backgroundColor: "#5E54FF",
    },
  };
};

export const RenderPaymentPlan = ({ input, label, itemValue, price,price_for_month, discount, billing_period, mr, ...props }) => {
  const selected = input.value === itemValue;
  const [hover, setHover] = useState(false);
 
  return (
    <Box
      sx={boxStyle(selected)}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      onClick={() => input.onChange(itemValue)}
      {...props}
    >
      <Text fontWeight="700" size="30px">
        {"\u20ac" + price_for_month}
        <Text size="small" fontWeight="700">
          /mo
        </Text>
      </Text>
      <Box mt="xxxs">
        <Typography sx={billingStyle}>{billing_period.toUpperCase()}</Typography>
        {discount && (
          <Typography sx={{ ...billingStyle, ...discountStyle, color: `${selected || hover ? "white" : "#5E54FF"}` }}>
            {"SAVE " + discount + "%"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
