import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/actions";
import { Box, Text, Flex } from "../fundamentals";
import { Button } from "../elements";
import { ButtonLabels } from "../../localisation";

const RemoteAlertModal = props => {
  const dispatch = useDispatch();
  const remoteAlert = useSelector(state => state.session.remoteAlert);
  const { title, message, link } = remoteAlert;

  const callToAction = () => {
    if (link) {
      return (
        <Button buttonStyle="primary" size="medium" onClick={() => window.open(link, "_blank")} mt="m">
          {ButtonLabels.continue}
        </Button>
      );
    }
  };

  return (
    <Box width="320px">
      {title && (
        <Text size="heading3" mb="l" color="black">
          {title}
        </Text>
      )}
      {message && (
        <Text size="body" mb="s" color="black">
          {message}
        </Text>
      )}
      <Flex justifyContent="flex-end">
        <Button buttonStyle={link ? "text" : "primary"} size="medium" onClick={() => dispatch(closeModal)} mt="m">
          {link ? ButtonLabels.skip : ButtonLabels.ok}
        </Button>
        {callToAction()}
      </Flex>
    </Box>
  );
};

export { RemoteAlertModal };
