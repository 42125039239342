import React from "react";
import styled, { withTheme } from "styled-components";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../config";
import { Box, Flex, Image } from "../fundamentals";
import { OnboardingThirdForm } from "../forms";
import { ProgressStepBar } from "../modules";
import { STEPS_ONBOARDING } from "../../config";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

const StyledOnboardingScreen = styled(Flex)`
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const OnboardingThirdScreen = () => {
  const isMobile = useIsScreenMobile();
  return (
    <StyledOnboardingScreen bg="white">
      <Image
        src={
          process.env.PUBLIC_URL +
          `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
        }
        alt="logo"
        width={isMobile ? "25px" : "184px"}
        height={isMobile ? "35px" : ""}
        p={isMobile ? "35px 30px" : "35px 50px"}
      />

      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pt="l">
        <ProgressStepBar steps={STEPS_ONBOARDING} activeColor="#5E54FF" color="black" activeIndex={2} />
        <Elements stripe={stripePromise}>
          <OnboardingThirdForm />
        </Elements>
      </Box>
    </StyledOnboardingScreen>
  );
};

export default OnboardingThirdScreen;
