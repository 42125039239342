import React, { useRef } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import ReactDatePicker from "react-datepicker";
import { Box, Text, Flex, Icon } from "../fundamentals";
import { Input } from "../elements";

export const DatePicker = ({
  input: { value, ...input },
  meta: { error, ...meta } = {},
  label,
  disabled,
  preventOverlapRef,
  scrollingRef,
  required,
  warning,
  info,
  hideError,
  ...props
}) => {
  const displayError = error && (meta.visited || meta.submitFailed) && !meta.active && error;
  const feedback = ((meta.active || !meta.pristine) && warning) || info;
  const inputRef = useRef();

  const InputComponent = (
    <Input
      ref={inputRef}
      input={{ ...input, onChange: () => {}, value: value ? moment(value).format("DD/MM/YYYY") : "" }}
      meta={meta}
      preventOverlapRef={preventOverlapRef}
      scrollingRef={scrollingRef}
      disabled={disabled}
      hideError
    />
  );

  return (
    <Box mb="m">
      {label && (
        <Text fontSize="small" lineHeight="button" color={disabled ? "grey.60" : "grey.90"} mb="xxxs" userSelect="none">
          {label} {required ? "*" : ""}
        </Text>
      )}
      <ReactDatePicker
        popperPlacement="bottom-start"
        input={input}
        customInput={InputComponent}
        customInputRef={inputRef}
        disabled={disabled}
        {...input}
        {...props}
      />
      <Flex
        alignItems="center"
        color={displayError ? "red" : warning ? "amber.90" : "grey.80"}
        overflow="hidden"
        height={hideError && !displayError && !feedback ? 4 : 24}
        mb="xxs"
      >
        {(feedback || displayError) && <Icon icon="Info" size="14" mr="xxxs" />}
        <Text fontSize="smaller" lineHeight="button">
          {displayError || feedback}
        </Text>
      </Flex>
    </Box>
  );
};
