import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";
import {
  ROLE_ARTIST,
  ROLE_GALLERIST,
  ROLE_COLLECTOR,
  ROLE_ART_PROFESSIONAL,
  ROLE_ART_INSTITUTION,
} from "../../constants";
import { Headings, ButtonLabels, InputLabels, InputErrors, Info } from "../../localisation";
import { Flex } from "../fundamentals";
import { RadioSelect, Button } from "../elements";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Box, Typography } from "@mui/material";

const headerStyle = (isMobile) => {
  return {
    textAlign: "center",
    fontFamily: "DM Sans",
    fontSize: isMobile ? "20px" : "32px",
    lineHeight: isMobile ? "26px" : "40px",
    fontWeight: "400",
  };
};

const Form = ({ handleSubmit, submitting, email }) => {
  const history = useHistory();
  const isMobile = useIsScreenMobile();

  useEffect(() => {
    if (!email) history.replace("/register");
  }, [email, history]);

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        flexDirection="column"
        alignItems="center"
        width={isMobile ? "325px" : "505px"}
        bg="white"
        p={isMobile ? "m" : "60px 60px 35px 60px"}
        boxShadow="medium"
        borderRadius="20px"
        mb="40px"
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap:'50px', marginBottom:'20px'}}>
          <Typography sx={headerStyle(isMobile)}>{Headings.selectWhichFitsBest}</Typography>
          <Field
            name="role"
            px={isMobile && "l"}
            options={{
              [ROLE_COLLECTOR]: {
                label: InputLabels.collector,
                color: "black",
                fontWeight: "700",
                description: Info.collectorDesc,
              },
              [ROLE_ARTIST]: {
                label: InputLabels.artist_text,
                color: "black",
                fontWeight: "700",
                description: Info.artistDesc,
              },
              [ROLE_GALLERIST]: {
                label: InputLabels.gallerist,
                color: "black",
                fontWeight: "700",
                description: Info.galleristDesc,
              },
              [ROLE_ART_PROFESSIONAL]: {
                label: InputLabels.artProfessional,
                color: "black",
                fontWeight: "700",
                description: Info.artProfessionalDesc,
              },
              [ROLE_ART_INSTITUTION]: {
                label: InputLabels.artInstitution,
                color: "black",
                fontWeight: "700",
                description: Info.artInstitutionDesc,
              },
            }}
            component={RadioSelect}
          />
          
        </Box>
        <Button
          buttonStyle="default"
          type="submit"
          size={isMobile ? "small" : "medium"}
          mb="xxs"
          disabled={submitting}
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          alignSelf="center"
          bg="white"
        >
          {ButtonLabels.next}
        </Button>
        <Button
          buttonStyle="link"
          type="button"
          textDecoration="underline"
          color="black"
          mb="s"
          alignSelf="center"
          onClick={() => history.goBack()}
        >
          {ButtonLabels.back}
        </Button>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const { role } = values;

  if (!role) errors.role = InputErrors.noRole;

  return errors;
};

let OnboardingFirstForm = reduxForm({
  form: "register",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("register");

function mapStateToProps(state) {
  const email = selector(state, "email");

  return { email };
}

OnboardingFirstForm = connect(mapStateToProps, null)(OnboardingFirstForm);

export { OnboardingFirstForm };
