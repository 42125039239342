import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ChevronDown, XCircle } from "react-feather";
import { change, Field, FormSection } from "redux-form";
import { Input, RenderError } from "../Input";
import { ButtonLabels, InputLabels } from "../../../localisation/labels";
import { MultiInputSelect } from "../MultiInputSelect";
import { CONTACT_TYPES } from "../../../config/options";
import { useDispatch } from "react-redux";

const fieldStyle = {
  flexGrow: 1,
  ".MuiFilledInput-root": {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F3F4FF",
      borderBottom: "2px solid #C8CEFF",
      marginBottom: "-3px",
    },
    "&:before": {
      content: "none",
    },
    "&.Mui-focused": {
      borderBottom: "none",
      backgroundColor: "#F3F4FF",
      marginBottom: "-3px",
    },
  },

  "& .Mui-error": {
    ".MuiFilledInput-input": {
      marginBottom: "3px",
    },
  },
};

const multiSelectStyle = {
  "& .MuiInputBase-input": {
    minWidth: "223px",
    height: "40px !important",
  },
};

const accordionStyle = {
  "&.MuiAccordion-root": {
    border: "1px solid #C3C2C9",
    borderRadius: "7px",
    width: "828px",
    boxShadow: "none",
    "&:before": {
      content: "none",
    },
  },
};

const labelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "58px",
};

const headerTextStyle = {
  fontSize: "20px",
  lineHeight: "28px",
  fontFamily: "Inter",
  color: "#2B2A2E",
};

const ContactGeneralFormSection = ({ form, reset }) => {
  const dispatch = useDispatch();

  const generalFormClearHandler = () => {
    dispatch(change(form, "ContactGeneralFormSection", {}));
  };

  return (
    <FormSection name="ContactGeneralFormSection">
      <Accordion sx={accordionStyle} defaultExpanded={true}>
        <AccordionSummary
          sx={{ padding: "32px", height: "92px", backgroundColor: "white", borderRadius: "7px" }}
          expandIcon={<ChevronDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={headerTextStyle}>General</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px 32px 32px 32px" }}>
          <Stack direction="column">
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.firstName}</Typography>
                <Field name="firstName" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="firstName" component={RenderError} />
            </Box>

            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.lastName}</Typography>

                <Field name="lastName" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="lastName" component={RenderError} />
            </Box>

            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.organisationContact}</Typography>

                <Field name="organisation" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="organisation" component={RenderError} />
            </Box>

            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.emailText}</Typography>
              <Field name="email" formName={form} component={Input} sx={fieldStyle} />
            </Stack>
            <Stack sx={stackStyle} direction="row">
              <Typography sx={labelStyle}>{InputLabels.phone}</Typography>
              <Field name="phone" formName={form} component={Input} sx={fieldStyle} />
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              // justifyContent="space-between"
              sx={{ borderBottom: "1px solid #C3C2C9", height: "50px" }}
            >
              <Typography sx={labelStyle}>{InputLabels.type}</Typography>
              <Field
                name="types"
                formName={form}
                component={MultiInputSelect}
                options={CONTACT_TYPES.map((type) => ({
                  value: type.value,
                  label: type.label,
                }))}
                sx={multiSelectStyle}
              />
            </Stack>
          </Stack>
          <Stack direction="row-reverse" alignItems="center" sx={{ marginTop: "24px" }}>
            <Button
              startIcon={
                <XCircle style={{ width: "16px", height: "16px", position: "relative", right: "-5px", top: "-1px" }} />
              }
              sx={{ paddingRight: "0", paddingTop: "0px", fontSize: "14px", fontWeight: 500 }}
              onClick={generalFormClearHandler}
            >
              {ButtonLabels.clear}
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </FormSection>
  );
};

export { ContactGeneralFormSection };
