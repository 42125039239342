import React, { Component } from "react";
import _ from "lodash";
import { ButtonLabels } from "../../localisation";

import { Field, FieldArray } from "redux-form";
import { Box, Flex, Text } from "../fundamentals";
import { Button } from "../elements";

class MultiEntryFormSection extends Component {
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }

  renderRow({ fields, meta: { error, submitFailed } }) {
    const { columns, initialValues = {}, onRemoveRow, sortBy, disabled, addLabel } = this.props;
    const processedFields = _.orderBy(
      fields.map((member, index) => {
        return { member, index, rowValues: fields.get(index) };
      }),
      `rowValues.${sortBy}`
    );
    return (
      <Box>
        {processedFields.map(({ member, index }, sortedIndex) => {
          return (
            <Flex justifyContent="space-between" mb="xxs">
              {columns.map(({ name, label, additionalOnChange, ...props }, column) => {
                if (sortedIndex !== 0)
                  return (
                    <Field
                      name={`${member}.${name}`}
                      additionalOnChange={value => additionalOnChange && additionalOnChange(value, name, index, fields)}
                      disabled={disabled}
                      {...props}
                    />
                  );
                return (
                  <Box paddingTop="24px" position="relative">
                    <Text
                      top="0"
                      position="absolute"
                      minHeight={columns.some(column => column.hasOwnProperty("label")) ? "16px" : undefined}
                      fontSize="smaller"
                      lineHeight="button"
                      fontWeight="500"
                      color="black"
                      mb="xxs"
                      whiteSpace="nowrap"
                    >
                      {label}
                    </Text>
                    <Field
                      name={`${member}.${name}`}
                      additionalOnChange={value => additionalOnChange && additionalOnChange(value, name, index, fields)}
                      disabled={disabled}
                      {...props}
                    />
                  </Box>
                );
              })}
              <Button
                buttonStyle="text"
                size="medium"
                color="black"
                icon="X"
                alignSelf="center"
                mt={sortedIndex === 0 ? "s" : "none"}
                pr="none"
                pl="none"
                onClick={() => {
                  if (onRemoveRow) onRemoveRow(index, fields);
                  fields.remove(index);
                }}
              />
            </Flex>
          );
        })}
        {!disabled && (
          <Button
            buttonStyle="text"
            size="small"
            pl="none"
            icon="Plus"
            onClick={() =>
              fields.push(
                _.mapValues(initialValues, value => (typeof value === "function" ? value(fields.length) : value))
              )
            }
          >
            {addLabel || ButtonLabels.addCrates}
          </Button>
        )}
      </Box>
    );
  }

  render() {
    const { groupName, label } = this.props;
    return (
      <Box mb="m">
        <Text size="small" color="grey.80" mb="s">
          {label}
        </Text>
        <FieldArray name={groupName} component={this.renderRow} />
      </Box>
    );
  }
}

export { MultiEntryFormSection };
