import { SimplifyApi } from "../../utils";
import { all, put, call, takeEvery } from "redux-saga/effects";
import { CHECK_FOR_REMOTE_ALERTS } from "../actions/types";
import { openModal, showRemoteAlert } from "../actions";
import { RecordEventAnalytics } from "../../analytics";
import { Events } from "../../analytics/types";

const checkForRemoteAlertsService = () => SimplifyApi.get(`/v1/getRemoteAlerts/web`);

function* checkForRemoteAlertsSaga() {
  try {
    const response = yield call(checkForRemoteAlertsService);

    const { alerts } = response.data;

    if (alerts.length > 0) {
      yield all([put(openModal("remote_alert")), put(showRemoteAlert(alerts[0]))]);

      RecordEventAnalytics(Events.SHOW_REMOTE_ALERT);
    }
  } catch (error) {
    console.log(error);
  }
}

function* uiSaga() {
  yield takeEvery(CHECK_FOR_REMOTE_ALERTS, checkForRemoteAlertsSaga);
}

export default uiSaga;
