import React from "react";

import { Box, Flex, Text } from "../fundamentals";

export const Section = React.forwardRef(
  ({ title, titleSize = "heading3", titleStyle, subtitle, children, color, ...props }, ref) => {
    return (
      <Flex ref={ref} flexDirection="column" flex="1" mb="xxl" {...props}>
        <Box pb={subtitle ? "s" : "l"} {...titleStyle}>
          {title && (
            <Text color={color || "black"} size={titleSize}>
              {title}
            </Text>
          )}
          {subtitle && (
            <Text pt="xxs" color="grey.80" size="small">
              {subtitle}
            </Text>
          )}
        </Box>
        {children}
      </Flex>
    );
  }
);
