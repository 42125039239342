import React, { useState } from "react";
import { Toolbar, AppBar, Stack, Button, Menu, MenuItem, Link, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { Info, ChevronDown, Bell, User, Plus } from "react-feather";
import { ButtonLabels } from "../../../localisation";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedCollection, seenAllShareRecords, signOut } from "../../../store/actions";
import { SharingDropdown } from "../../modules/SharingDropdown";
import { selectCollections } from "../../../store/selectors";
import _ from "lodash";

const StyledMenu = styled(({ hoverBgColor, hoverColor, borderRadius, ...rest }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...rest}
  />
))((props) => ({
  "& .MuiPaper-root": {
    borderRadius: props.borderRadius ?? "8px",
    backgroundColor: props.bg ? props.bg : "#7274FF",
    color: props.bg ? "black" : "white",
    marginTop: "10px",
    width: props.width ? props.width : 110,
    "& .MuiMenu-list": {
      padding: "0 0",
    },
    "& .MuiMenuItem-root": {
      paddingLeft: props.pl ? props.pl : "25px",
      height: "48px",
      "&:hover": {
        backgroundColor: props.hoverBgColor ?? "#9AA1FF",
        color: props.hoverColor ?? "white",
      },
    },
  },
}));

const StyledButton = styled(Button)((props) => ({
  "&.MuiButton-root": {
    fontSize: "16px",
    padding: props.padding ?? "8px",
    lineHeight: "16px",
    fontWeight: 500,
    color: props.textColor ?? "#5E54FF",
    borderRadius: props.borderRadius ?? "8px",
    backgroundColor: props.bgcolor ?? "transparent",
    width: props.width,
    height: props.height,
    "&:hover": {
      background: props.hoverColor ?? "#FFFFFF",
    },
  },
}));

export const NavigationBar = (props) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const collections = _.sortBy(useSelector(selectCollections), ["type", "name"]);

  const handleSignOutButtonClick = () => {
    dispatch(signOut());
  };

  const handleSettingButtonClick = () => {
    history.push("/settings");
  };

  const handleAddArtworkButtonClick = () => {
    history.push("/artworks/add");
  };
  const handleAddContactButtonClick = () => {
    history.push("/contacts/add");
  };

  const handleShareDropdownClick = (e) => {
    setShareAnchorEl(e.currentTarget);
    dispatch(seenAllShareRecords());
  };

  const handleArtworksButtonClick = () => {
    dispatch(changeSelectedCollection(collections[0].id));
    history.push("/artworks");
  };

  const handleArtistsButtonClick = () => {
    history.push("/artists");
  };

  const handleExhibitionsButtonClick = () => {
    history.push("/exhibitions");
  };

  const handleContactsButtonClick = () => {
    history.push("/contacts");
  };

  const handleListsButtonClick = () => {
    history.push("/lists");
  };

  const handleDiscoveryButtonClick = () => {
    dispatch(changeSelectedCollection(collections[1].id));
    history.push("/discovery");
  };

  const isUpComingFeature = () => {
    return ["/artists", "/exhibitions", "/lists", "/events"].includes(location.pathname);
  };

  return (
    <AppBar position="static" sx={{ height: "80px", bgcolor: "#F4F4F5", boxShadow: "none", ...props }}>
      <Toolbar>
        <RouterLink to={process.env.REACT_APP_ENV === "production" ? "https://www.simplify.art?redirect=no" : "/"}>
          <img
            src={`${process.env.PUBLIC_URL}/images/brand-identity/logo-no-text.svg`}
            alt="logo"
            style={{
              height: "35px",
              width: "25px",
            }}
          />
        </RouterLink>
        <Stack spacing={2} direction="row" sx={{ marginLeft: "20px", flexGrow: 1 }}>
          <StyledButton
            onClick={handleArtworksButtonClick}
            bgcolor={location.pathname === "/artworks" ? "#DEE1FF" : "transparent"}
          >
            {ButtonLabels.artworks}
          </StyledButton>

          <StyledButton
            onClick={handleArtistsButtonClick}
            bgcolor={location.pathname === "/artists" ? "#DEE1FF" : "transparent"}
            endIcon={
              <Info style={{ height: "12px", width: "12px", position: "relative", top: "-2px", right: "3px" }} />
            }
          >
            {ButtonLabels.artists}
          </StyledButton>

          <StyledButton
            onClick={handleExhibitionsButtonClick}
            bgcolor={location.pathname === "/exhibitions" ? "#DEE1FF" : "transparent"}
            endIcon={
              <Info style={{ height: "12px", width: "12px", position: "relative", top: "-2px", right: "3px" }} />
            }
          >
            {ButtonLabels.exhibitions}
          </StyledButton>

          <StyledButton
            onClick={handleContactsButtonClick}
            bgcolor={["/contacts", "/contacts/add"].includes(location.pathname) ? "#DEE1FF" : "transparent"}
          >
            {ButtonLabels.contacts}
          </StyledButton>

          <StyledButton
            onClick={handleListsButtonClick}
            bgcolor={location.pathname === "/lists" ? "#DEE1FF" : "transparent"}
            endIcon={
              <Info style={{ height: "12px", width: "12px", position: "relative", top: "-2px", right: "3px" }} />
            }
          >
            {ButtonLabels.listsLabel}
          </StyledButton>

          <StyledButton
            onClick={handleDiscoveryButtonClick}
            bgcolor={location.pathname === "/discovery" ? "#DEE1FF" : "transparent"}
          >
            {ButtonLabels.discovery}
          </StyledButton>
        </Stack>

        <Stack spacing={3.5} direction="row" alignItems="center">
          <StyledButton
            startIcon={
              isUpComingFeature() ? (
                <Plus style={{ width: "20px", height: "20px", position: "relative", right: "-4px" }} />
              ) : (
                <></>
              )
            }
            endIcon={!isUpComingFeature() ? <ChevronDown /> : <></>}
            onClick={(e) => (!isUpComingFeature() ? setAnchorEl(e.currentTarget) : null)}
            bgcolor="#5E54FF"
            borderRadius="16px"
            padding="8px 16px"
            width="88px"
            height="32px"
            textColor="white"
            hoverColor="#5E54FF"
          >
            {ButtonLabels.add}
          </StyledButton>
          <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            <MenuItem disableRipple onClick={handleAddArtworkButtonClick}>
              {ButtonLabels.artwork}
            </MenuItem>
            <MenuItem disableRipple onClick={handleAddContactButtonClick}>
              {ButtonLabels.contact}
            </MenuItem>
          </StyledMenu>
          <Link
            href="https://www.simplify.art/help"
            target="_blank"
            underline="none"
            sx={{ color: "black", fontWeight: 500 }}
          >
            Help
          </Link>
          <IconButton sx={{ "&:hover": { bgcolor: "transparent" } }} disableRipple onClick={handleShareDropdownClick}>
            <Bell style={{ width: "18px", height: "18px", color: "black" }} />
          </IconButton>

          <IconButton
            sx={{ "&:hover": { bgcolor: "transparent" } }}
            disableRipple
            onClick={(e) => setUserAnchorEl(e.currentTarget)}
          >
            <User style={{ width: "18px", height: "18px", color: "black" }} />
          </IconButton>
          <StyledMenu
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={() => setUserAnchorEl(null)}
            pl="22px"
            width="220px"
            bg="#FFFFFF"
            borderRadius="0"
            hoverBgColor="#E8E7EA"
            hoverColor="#7274FF"
          >
            <MenuItem disableRipple onClick={handleSettingButtonClick}>
              {ButtonLabels.settings}
            </MenuItem>
            <MenuItem disableRipple>{ButtonLabels.contactUs}</MenuItem>
            <MenuItem disableRipple sx={{ borderTop: "1px solid #DBDADF" }} onClick={handleSignOutButtonClick}>
              {ButtonLabels.signout}
            </MenuItem>
          </StyledMenu>
          <StyledMenu
            anchorEl={shareAnchorEl}
            open={Boolean(shareAnchorEl)}
            onClose={() => setShareAnchorEl(null)}
            bg="#FFFFFF"
            borderRadius="0"
            hoverBgColor="#E8E7EA"
            hoverColor="#7274FF"
            width="500px"
          >
            <SharingDropdown />
          </StyledMenu>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
