import React, { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { sendPasswordResetCode, resetPassword } from "../../store/actions";
import { Box, Flex, Text } from "../fundamentals";
import { Input, Button } from "../elements";
import { Headings, InputPlaceholders, ButtonLabels, InputErrors, Instructions } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";

const Form = ({ handleSubmit, submitting, pristine, emailVerified, email, ...props }) => {
  const newPasswordRef = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useIsScreenMobile();
  const history = useHistory();

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Flex flexDirection="column" alignItems="center">
        <Text size={isMobile ? "heading3" : "heading1"} color="black" mb={isMobile ? "m" : "xxl"} textAlign="center">
          {Headings.forgotPassword}
        </Text>
        <Box>
          <Text size="button" color="black" mb="m" textAlign={isMobile && "center"}>
            {emailVerified ? Instructions.changePassword : Instructions.forgotPasswordText}
          </Text>

          <Field
            name="email"
            placeholder={InputPlaceholders.emailText}
            width="100%"
            minHeight={isMobile ? "32px" : "50px"}
            display={emailVerified ? "none" : "block"}
            options={{
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  newPasswordRef.current.focus();
                }
              },
            }}
            component={Input}
          />

          {emailVerified && (
            <Box width="100%">
              <Field
                name="resetCode"
                customRef={newPasswordRef}
                placeholder={InputPlaceholders.resetCode}
                autocapitalize={true}
                component={Input}
                minHeight={isMobile ? "32px" : "50px"}
              />
              <Field
                name="newPassword"
                placeholder={InputPlaceholders.newPassword}
                password
                component={Input}
                minHeight={isMobile ? "32px" : "50px"}
              />
              <Field
                name="confirmNewPassword"
                width="100%"
                minHeight={isMobile ? "32px" : "50px"}
                placeholder={InputPlaceholders.confirmPassword}
                password
                component={Input}
              />
            </Box>
          )}

          <Flex alignItems="center" flexDirection="column">
            <Button
              type="submit"
              buttonStyle="default"
              size={isMobile ? "small" : "medium"}
              fontSize={isMobile ? "13px" : "20px"}
              alignSelf="center"
              mt={isMobile ? "xxxs" : "xxs"}
              icon={submitting ? "Loader" : null}
              disabled={submitting}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              bg="white"
              p={isMobile ? "5px 12px" : "8.5px 20px"}
              fontWeight="700"
            >
              {emailVerified ? ButtonLabels.changePassword : ButtonLabels.sendMeTheResetCode}
            </Button>
            {!emailVerified && (
              <Button
                buttonStyle="link"
                type="button"
                textDecoration="underline"
                color="black"
                mt="xs"
                alignSelf="center"
                onClick={() => history.goBack()}
              >
                {ButtonLabels.back}
              </Button>
            )}
            {emailVerified && (
              <Box>
                <Text mt="xxs" size="small">
                  {Instructions.noCode}
                </Text>
                <Button
                  type="button"
                  onClick={() => dispatch(sendPasswordResetCode(email))}
                  buttonStyle="text"
                  alignSelf="center"
                  fontFamily="Inter"
                  textDecoration="underline"
                  color="black"
                >
                  {ButtonLabels.requestAnotherOne}
                </Button>
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    </form>
  );
};

const submit = (values, dispatch) => {
  console.log("EXECUTES");
  console.dir(values);
  if (!values.resetCode) {
    return dispatch(sendPasswordResetCode(values.email));
  } else {
    return dispatch(resetPassword(values));
  }
};

const validate = (values) => {
  const errors = {};
  console.log(values);

  if (!values.email) {
    errors.email = InputErrors.noEmail;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = InputErrors.invalidEmail;
  }

  if (!values.resetCode) {
    errors.resetCode = InputErrors.noResetCode;
  }

  if (!values.newPassword) {
    errors.newPassword = InputErrors.noPassword;
  } else if (!/^.{9,}$/.test(values.newPassword)) {
    errors.newPassword = InputErrors.insecurePassword;
  }

  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = InputErrors.noConfirmPassword;
  } else if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = InputErrors.confirmPasswordMatch;
  }

  return errors;
};

let ForgottenPasswordForm = reduxForm({
  form: "forgottenPassword",
  validate,
})(Form);

const selector = formValueSelector("forgottenPassword");

function mapStateToProps(state) {
  const emailVerified = selector(state, "emailVerified");
  const email = selector(state, "email");
  return { emailVerified, email };
}

ForgottenPasswordForm = connect(mapStateToProps, null)(ForgottenPasswordForm);

export { ForgottenPasswordForm };
