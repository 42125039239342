import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { space } from "styled-system";
import _ from "lodash";
import { clickedInsideNode } from "../../utils";

import { Box, Flex, Text, Separator } from "../fundamentals";
import { Button } from ".";
import { Dropdown, DropdownItem } from "../modules";

const StyledSelect = styled(Flex)`
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  align-items: center;
  ${space}
`;

const DropdownSelect = ({
  input,
  meta,
  label,
  stateful,
  options,
  additionalOnChange,
  inverted,
  labelSize = "body",
  buttonProps,
  placeholder,
  disabled,
  clearable,
  ...props
}) => {
  const [open, setDropdownOpen] = useState();
  const ref = useRef();
  useEffect(() => {
    const handleClick = e => !clickedInsideNode(e, ref) && setDropdownOpen(false);
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  function renderDropdownItems() {
    const items = _.map(options, ({ label }, value) => {
      return (
        <DropdownItem
          key={value}
          label={label}
          onSelect={() => {
            input.onChange(value);
            if (additionalOnChange) additionalOnChange(value);
            setDropdownOpen(!open);
          }}
          active={value === input.value}
        />
      );
    });
    if (clearable && input.value) {
      items.push(
        <Box>
          <Separator />
          <DropdownItem
            key="clear"
            label="Clear"
            icon="X"
            inactiveColor="red"
            onSelect={() => {
              input.onChange(null);
              if (additionalOnChange) additionalOnChange(null);
              setDropdownOpen(!open);
            }}
          />
        </Box>
      );
    }
    return items;
  }
  return (
    <StyledSelect ref={ref} userSelect="none" {...props}>
      <Text size={labelSize}>{label}</Text>
      <Button
        buttonStyle="text"
        size="medium"
        icon={open ? "ChevronUp" : !disabled ? "ChevronDown" : null}
        pl="xs"
        pr="xxs"
        onClick={() => setDropdownOpen(!open)}
        flip={true}
        inverted={inverted}
        disabled={disabled}
        preventDefault
        {...buttonProps}
      >
        {options[input.value] ? options[input.value].label : placeholder}
      </Button>
      {open && (
        <Dropdown top="l" left="xl" stateful={stateful}>
          {renderDropdownItems()}
        </Dropdown>
      )}
    </StyledSelect>
  );
};

export { DropdownSelect };
