import React from "react";
import styled from "styled-components";

import { Box, Flex, Image, Text, Link } from "../fundamentals";
import { Instructions, LinkLabels } from "../../localisation";
import { Button } from "../elements";
import { RegisterForm } from "../forms";
import { push } from "connected-react-router";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Typography } from "@mui/material";

const StyledRegisterScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const accountExistStyle = {
  fontFamily: "DM Sans",
  fontSize: "20px",
  lineHeight: "26px",
  fontWeight: "400",
  marginRight:'20px'
};

const RegisterScreen = () => {
  const isMobile = useIsScreenMobile();
  const history = useHistory();
  const submit = (values, dispatch) => {
    return dispatch(push("/onboarding/user-role"));
  };

  return (
    <StyledRegisterScreen bg="white">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Helmet>
          <title>Start Your 30-Day Free Trial | simplify.art</title>
          <meta
            name="description"
            content="Sign up for a 30-day trial and start simplifying with our art collection app for free!"
          />
        </Helmet>
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />

        <Box display="flex" alignItems="center" mr={isMobile ? "l" : "xxxl"}>
          {!isMobile && (
            <Typography sx={accountExistStyle}>{Instructions.accountExists}</Typography>
          )}

          <Button
            buttonStyle="default"
            type="button"
            size={isMobile ? "small" : "medium"}
            fontSize={isMobile ? "13px" : "20px"}
            bg="white"
            p={isMobile ? "5px 12px" : "8.5px 20px"}
            fontWeight="700"
            onClick={(e) => {
              e.preventDefault();
              history.push("/login");
            }}
          >
            {LinkLabels.signinText}
          </Button>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
        <Box
          width={isMobile ? "325px" : "550px"}
          height={isMobile ? "271px" : "417px"}
          bg="white"
          p={isMobile ? "m" : "60px 60px 35px 60px"}
          mt={isMobile ? "l" : "xxl"}
          boxShadow="medium"
          borderRadius="20px"
        >
          <RegisterForm onSubmit={submit} />
        </Box>
      </Box>
    </StyledRegisterScreen>
  );
};

export default RegisterScreen;
