import React from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { connect, useSelector } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { closeModal, restartSubscription } from "../../store/actions";
import { selectUser } from "../../store/selectors";

import { Box, Text, Flex } from "../fundamentals";
import { Button, StripeCardNumberInput, StripeCardExpiryInput, StripeCardCvcInput, RadioSelect } from "../elements";

import { returnStripePlansBasedOnUserRole, returnSelectedPlan } from "../../utils";

import { ButtonLabels, InputPlaceholders, InputErrors, DynamicLabels } from "../../localisation";

const renderPaymentPlan = ({ input, label, itemValue, price, discount, billing_period, mr, ...props }) => {
  const selected = input.value === itemValue;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      border="2px solid black"
      borderRadius="15px"
      width="100px"
      height="70px"
      cursor="pointer"
      bg={selected ? "#5E54FF" : undefined}
      color={selected ? "white" : undefined}
      onClick={() => input.onChange(itemValue)}
      mr={mr}
    >
      <Text fontWeight="700" size="20px">
        {"\u20ac" + price}
        <Text size="small" fontWeight="700">
          /mo
        </Text>
      </Text>
      <Box mt="xxxs">
        <Text size="smaller" fontWeight="600">
          {billing_period.toUpperCase()}
        </Text>
      </Box>
    </Box>
  );
};

const Form = ({ title, handleSubmit, submitting, pristine, plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector(selectUser);

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }
    dispatch(closeModal());
    return dispatch(
      restartSubscription.request({
        ...values,
        stripe,
        elements,
      })
    );
  };

  return (
    <Box width="320px">
      <Text size="heading3" mb="m" color="black">
        {title}
      </Text>
      <Flex justifyContent="flex-start">
        <form onSubmit={handleSubmit(submit)}>
          <Flex flexDirection="column">
            <Field
              name="plan"
              options={returnStripePlansBasedOnUserRole(user.role)}
              direction="horizontal"
              customItemComponent={renderPaymentPlan}
              component={RadioSelect}
              ml="l"
            />
            <Text fontSize="small" lineHeight="button" mb="m" userSelect="none">
              Enter your card details
            </Text>
            <Field
              name="cardNumber"
              component={StripeCardNumberInput}
              options={{ placeholder: InputPlaceholders.creditCardNumber }}
            />
            <Flex justifyContent="space-between" mt="s">
              <Field name="cardExpiryDate" component={StripeCardExpiryInput} />
              <Field name="cardCvc" component={StripeCardCvcInput} />
            </Flex>
            <Text mt="m" size="small">
              {DynamicLabels.subscriptionCharge(
                returnSelectedPlan(plan)?.price,
                user.account?.subscription?.currentPeriodEnd
              )}
            </Text>
            <Button
              buttonStyle="primary"
              size="medium"
              type="submit"
              disabled={submitting || pristine}
              icon={submitting ? "Loader" : null}
              iconSpinning={true}
              flip={true}
              iconOffset="xs"
              my="l"
              cursor={plan ? "pointer" : "default"}
            >
              {ButtonLabels.restartMySubscription}
            </Button>
          </Flex>
        </form>
      </Flex>
    </Box>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.plan) errors.plan = InputErrors.noPlan;
  return errors;
};

const RestartSubscriptionForm = reduxForm({
  form: "restartSubscription",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("restartSubscription");

function mapStateToProps(state) {
  const plan = selector(state, "plan");

  return { plan };
}

const RestartSubscriptionModal = connect(mapStateToProps, null)(RestartSubscriptionForm);

export { RestartSubscriptionModal };
