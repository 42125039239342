import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Box, Flex } from "../fundamentals";

const StyledTransitionGroup = styled(TransitionGroup)`
  position: relative;
  display: flex;
  justify-content: center;
  .autoWidthTransition-enter {
    opacity: 0;
    max-width: 0px;
  }
  .autoWidthTransition-enter.autoWidthTransition-enter-active {
    opacity: 1;
    max-width: 500px;
    transition: max-width 0.5s, opacity 0.25s;
  }
  .autoWidthTransition-exit {
    opacity: 1;
    max-width: 500px;
  }
  .autoWidthTransition-exit.autoWidthTransition-exit-active {
    opacity: 0;
    max-width: 0px;
    transition: max-width 0.3s, opacity 0.25s;
  }
`;

const StyledFloatingActionPill = styled(Flex)`
  transition: background-color 0.25s;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  ${variant({
    prop: "inverted",
    variants: {
      false: {
        bg: "white"
      },
      true: {
        bg: "blue.60"
      }
    }
  })}
`;

export const FloatingActionPill = React.forwardRef(({ children, inverted = false, ...props }, ref) => {
  return (
    <Flex
      ref={ref}
      position="fixed"
      zIndex="100"
      bottom="m"
      left="0"
      right="0"
      justifyContent="center"
      pointerEvents="none"
      display={children && children.length > 0 ? null : "none"}
    >
      <StyledFloatingActionPill inverted={inverted} p="xs" pointerEvents="all" {...props}>
        <StyledTransitionGroup p="xs">
          {children.map((child, index) => {
            return (
              <CSSTransition key={index} classNames="autoWidthTransition" timeout={{ enter: 500, exit: 500 }}>
                <Box overflow="hidden">{child}</Box>
              </CSSTransition>
            );
          })}
        </StyledTransitionGroup>
      </StyledFloatingActionPill>
    </Flex>
  );
});
