import React, { useState } from "react";
import { Box, Flex, Text } from "../fundamentals";
import { useMediaQuery } from "react-responsive";
import { Typography } from "@mui/material";

const numberStyle=(active, activeColor)=>{
  return {
     color: active ? activeColor : 'black', 
     fontWeight:700,
}
}

const textStyle=(active, activeColor)=>{
  return {
     color: active ? activeColor : 'black', 
     fontWeight:600,
     paddingTop:'24px',
     lineHeight:'17px',
     fontSize:'16px',
     fontFamily:"Inter",
     letterSpacing:"2px",
     textAlign:"center",
}
}

const ProgressStepButton = ({ number, title, isActive, activeColor, color, onClick }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return ( 
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingTop="s"
      paddingBottom="77px"
      paddingLeft={isMobile ? "l" : "xxl"}
      paddingRight={isMobile ? "l" : "xxl"}
      onClick={onClick}
    >
      <Box
        borderRadius="50%"
        border="3px solid"
        borderColor={isActive ? activeColor : "none"}
        display="flex"
        width="40px"
        height="40px"
        justifyContent="center"
        alignItems="center"
      >
      
        <Typography sx={numberStyle(isActive, activeColor)}>{number}</Typography>
      </Box>
     
      <Typography sx={textStyle(isActive, activeColor)}>{title.toUpperCase()}</Typography>
    </Box>
  );
};

const ProgressStepBar = ({ steps, color, activeColor, additionalOnClick, activeIndex = 0, ...props }) => {
  const [activeStep, setActiveStep] = useState(activeIndex);

  return (
    <Flex padding="0px l" {...props}>
      {steps.map(({ label }, index) => {
        return (
          <ProgressStepButton
            key={index}
            number={index + 1}
            title={label}
            isActive={index === activeStep}
            activeColor={activeColor}
            color={color}
          />
        );
      })}
    </Flex>
  );
};

export { ProgressStepBar };
