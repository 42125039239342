import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateAppVersion, closeModal } from "../../store/actions";
import { WEBAPP_VERSION } from "../../config";
import { Flex, Box, Text, Image } from "../fundamentals";
import { Button } from "../elements";
import { changelog } from "../../../changelog.js";
import { Headings, ButtonLabels } from "../../localisation";

const FeatureChangelogModal = () => {
  const dispatch = useDispatch();
  const versionSplit = WEBAPP_VERSION.split(".");
  const { date, sections } = changelog[`${versionSplit[0]}.${versionSplit[1]}`];

  useEffect(() => {
    return () => {
      dispatch(updateAppVersion(WEBAPP_VERSION));
    };
  });

  return (
    <Flex flexDirection="column" width="390px" minHeight="500px">
      <Box mt="xxs" mb="xxl">
        <Text size="heading2" color="grey.100">
          {Headings.featureChangelog + WEBAPP_VERSION}
        </Text>
        <Text size="small" color="grey.80">
          {new Date(Date.parse(date)).toDateString()}
        </Text>
      </Box>
      {sections.map(({ title, texts, image }) => {
        return (
          <Box mb="xxl" textAlign="justify">
            <Text size="heading3" color="grey.100" mb="m">
              {title}
            </Text>
            {texts.map((text) => (
              <Text size="body" color="grey.80" mb="s">
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </Text>
            ))}
            {image && <Image src={image} maxWidth="100%" mt="l" />}
          </Box>
        );
      })}
      <Button buttonStyle="primary" size="medium" onClick={() => dispatch(closeModal())} alignSelf="flex-end">
        {ButtonLabels.gotIt}
      </Button>
    </Flex>
  );
};

export { FeatureChangelogModal };
