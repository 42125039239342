import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../store/actions";
import { Box, Button, Stack, Typography, Modal } from "@mui/material";

const containerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1)",
};

const contentStyle = {
  padding: "48px",
};

const headingTextStyle = {
  fontSize: "20px",
  lineHeight: "28px",
  color: "#2B2A2E",
};

const bodyTextStyle = {
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
};

const cancelButtonStyle = {
  height: "32px",
  border: "1px solid #FF3737",
  gap: "4px",
  borderRadius: "16px",
  padding: "8px 16px",
  color: "#FF3737",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "16px",
  ":active": { opacity: 0.7 },
};

const deleteButtonStyle = {
  height: "32px",
  gap: "4px",
  borderRadius: "16px",
  padding: "8px 16px",
  color: "white",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "16px",
  backgroundColor: "#FF3737",
  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1)",
  ":hover": {
    backgroundColor: "#FF3737",
  },
  ":active": { opacity: 0.7 },
};

const DeleteModal = ({ headerText, bodyText, onDelete }) => {
  const dispatch = useDispatch();
  return (
    <Box sx={containerStyle}>
      <Box sx={contentStyle}>
        <Typography sx={headingTextStyle}>{headerText}</Typography>

        <Typography sx={bodyTextStyle}>{bodyText}</Typography>

        <Stack direction="row" gap="12px" mt="32px">
          <Button
            onClick={() => {
              dispatch(closeModal());
            }}
            sx={cancelButtonStyle}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              onDelete();
              dispatch(closeModal());
            }}
            sx={deleteButtonStyle}
          >
            Delete
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export { DeleteModal };
