import produce from "immer";
import { EXECUTE_TUTORIAL, NEW_TUTORIAL_STEP, TUTORIAL_COMPLETED, SIGN_OUT_SUCCESS } from "../actions/types";

const TUTORIAL_INITIAL_STATE = {
  executing: false,
  joyrides: {}
};

const tutorialReducer = (state = TUTORIAL_INITIAL_STATE, action) => {
  return produce(state, draftState => {
    switch (action.type) {
      case EXECUTE_TUTORIAL:
        return executeTutorial(draftState, action);
      case NEW_TUTORIAL_STEP:
        return newTutorialStep(draftState, action);
      case TUTORIAL_COMPLETED:
        return tutorialCompleted(draftState, action);
      case SIGN_OUT_SUCCESS:
        return TUTORIAL_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const executeTutorial = (draftState, action) => {
  draftState.executing = { name: action.payload.name, step: 0 };
};

const newTutorialStep = (draftState, action) => {
  draftState.executing.step = action.payload.step;
};

const tutorialCompleted = (draftState, action) => {
  const { executing } = draftState;
  if (executing) {
    draftState.joyrides[executing.name] = false;
    draftState.executing = false;
  }
};

export default tutorialReducer;
