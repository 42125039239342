import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { destroy } from "redux-form";
import {
  openModal,
  addActionToQueue,
  editArtwork,
  replaceSelectedArtwork,
  clearAllSelectedArtworks,
  clearUnactiveCacheImages,
} from "../../store/actions";
import {
  selectArtworksById,
  selectSelectedArtworkIds,
  selectCategoryArtworks,
  selectArtworkNavigationIndex,
  selectSelectedCategory,
} from "../../store/selectors";
import { ButtonLabels, UiLabels, InputLabels, DynamicLabels } from "../../localisation";
import { DEFAULT_ARTWORK_STATUSES } from "../../config";

import { NavigationBar } from "../react-material/NavigationBar";
import { SizeMe } from "react-sizeme";
import { Flex, Box, Text } from "../fundamentals";
import { Button, Modal, DropdownSelect } from "../elements";
import { ArtworkForm } from "../forms";
import { AdvancedExportsModal, ShareModal } from "../modals";
import { ArtworkSidebar, ArtworkDetailsHeader, DropdownContainer, Dropdown, DropdownItem, BackToTop } from "../modules";
import { WithRequireAccess, StickyObservable } from "../hocs";

const StyledHeaderContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
`;

const ArtworkDetailsScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sidebarWidth, setSidebarWidth] = useState();
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);
  const artworks = useSelector((state) => selectArtworksById(state, selectedArtworkIds));
  const artworkNavigationIndex = useSelector((state) => selectArtworkNavigationIndex(state, selectedArtworkIds[0]));
  const selectedCategory = useSelector(selectSelectedCategory);
  const artworksInCategory = useSelector((state) => selectCategoryArtworks(state, selectedCategory));
  const isModalOpened = useSelector((state) => state.ui.modal);
  const firstArtwork = artworks[0];
  const {
    artworkCollection,
    status,
    dateCreated,
    artworkValues: { frame, ...restOfArtworkValues },
    lastModified, //not used in form
    activeShareSource, //not used in form
    archiveArtworkNumber, //not used in form
    artistId, //not used in form
    isFromServer, //not used in form
    isModified, //not used in form
    isActive, //not used in form
    ...restOfArtwork
  } = firstArtwork;

  const lastModifiedDate = dateCreated && new Date(lastModified || dateCreated);

  const initialFormValues = {
    collection: {
      id: artworkCollection,
    },
    frame: frame ? frame : null,
    ...restOfArtworkValues,
    status,
    ...restOfArtwork,
  };

  useEffect(() => {
    document.addEventListener("keyup", handleOnArrowLeftRigthKeysPress);

    return () => {
      document.removeEventListener("keyup", handleOnArrowLeftRigthKeysPress);
    };
  });

  const handleOnNavigateToArtwork = (navigateTo) => {
    if (navigateTo === "next") {
      dispatch(destroy("artworks.edit"));
      dispatch(replaceSelectedArtwork(artworksInCategory[artworkNavigationIndex + 1]));
    } else if (navigateTo === "prev") {
      dispatch(destroy("artworks.edit"));
      dispatch(replaceSelectedArtwork(artworksInCategory[artworkNavigationIndex - 1]));
    }
  };

  const handleOnArrowLeftRigthKeysPress = (event) => {
    if (selectedArtworkIds.length === 1 && artworksInCategory.length > 1 && !isModalOpened) {
      if (event.keyCode === 39) {
        if (artworkNavigationIndex !== artworksInCategory.length - 1) handleOnNavigateToArtwork("next");
      } else if (event.keyCode === 37) {
        if (artworkNavigationIndex !== 0) handleOnNavigateToArtwork("prev");
      }
    }
  };

  const renderFloatingActionPillButtons = (reset, dirty, pristine, submitting) => {
    if (dirty) {
      return [
        <Button key="save" type="submit" buttonStyle="text" size="medium" icon="UploadCloud" inverted={true}>
          {ButtonLabels.saveArtwork}
        </Button>,

        <Button
          key="reset"
          type="button"
          buttonStyle="text"
          size="medium"
          icon="RotateCcw"
          inverted={true}
          onClick={!pristine ? reset : null}
          preventDefault
        >
          {ButtonLabels.reset}
        </Button>,
      ];
    } else {
      return [
        <Button
          key="export"
          buttonStyle="text"
          size="medium"
          icon="FileText"
          preventDefault
          onClick={() => dispatch(openModal("advanced_export"))}
        >
          {ButtonLabels.export}
        </Button>,
        <Button
          key="share"
          buttonStyle="text"
          size="medium"
          icon="Share"
          preventDefault
          onClick={() => dispatch(openModal("share"))}
        >
          {ButtonLabels.share}
        </Button>,
        // <Button
        //   key="viewingroom"
        //   buttonStyle="text"
        //   size="medium"
        //   icon="Eye"
        //   preventDefault
        //   onClick={() => dispatch(openModal("viewing_room"))}
        // >
        //   {ButtonLabels.createViewingRoom}
        // </Button>,
        <DropdownContainer
          component={Button}
          buttonStyle="text"
          size="medium"
          icon="MoreHorizontal"
          iconSize="22"
          iconOffset="xs"
          label={ButtonLabels.more}
          key="more"
          mr="s"
          preventDefault
        >
          <Dropdown bottom="xl">
            <DropdownItem
              buttonStyle="text"
              size="medium"
              icon="Trash2"
              inactiveColor="red"
              iconOffset="xl"
              label={DynamicLabels.deleteArtworks(selectedArtworkIds.length)}
              pl="xxs"
              preventDefault
              onSelect={() => dispatch(openModal("delete_artwork"))}
            />
          </Dropdown>
        </DropdownContainer>,
      ];
    }
  };

  const renderStatusSelector = () => {
    return (
      <DropdownSelect
        input={{
          value: status,
        }}
        label={InputLabels.status}
        options={DEFAULT_ARTWORK_STATUSES}
        buttonProps={{
          size: "small",
          height: "100%",
          bg: DEFAULT_ARTWORK_STATUSES[status] ? DEFAULT_ARTWORK_STATUSES[status].color : null,
          color: status ? "black" : "grey.80",
          borderRadius: "4px",
          hoverColor: "grey.90",
          ml: "xs",
        }}
        placeholder={InputLabels.status}
        disabled={true}
        labelSize="small"
        clearable
        stateful
      />
    );
  };

  return (
    <Flex flex="1" flexDirection="column" maxWidth="1680px" width="100%">
      <NavigationBar />

      <Flex bg="white" boxShadow="small" mb="xxxl" position="relative" flex="1">
        <StyledHeaderContainer mt="s">
          <Flex pl="xl">
            <Button
              buttonStyle="text"
              size="small"
              icon="ArrowLeft"
              onClick={() => {
                history.replace("/artworks");
                dispatch(clearUnactiveCacheImages());
                dispatch(clearAllSelectedArtworks());
              }}
              pl="0px"
              pb="none"
            >
              {ButtonLabels.backToArtworks}
            </Button>
          </Flex>

          <StickyObservable options={{ threshold: 1 }}>
            <ArtworkDetailsHeader
              artwork={firstArtwork}
              pl="xl"
              right="0"
              sidebarWidth={sidebarWidth}
              renderStatusSelector={renderStatusSelector}
              showNavigationButtons={selectedArtworkIds.length === 1 && artworksInCategory.length > 1 ? true : false}
              handleOnNavigateToArtworkCallback={handleOnNavigateToArtwork}
              isFirstArtwork={artworkNavigationIndex === 0}
              isLastArtwork={artworkNavigationIndex === artworksInCategory.length - 1}
              isMultiEditMode={false}
            />
          </StickyObservable>

          <Flex color="grey.80" mt="xxxs" pl="xl" mb="xxl" alignItems="center" whiteSpace="nowrap">
            {firstArtwork.archiveArtworkNumber && (
              <Text size="small" mr="m">
                {`ID \u0023${firstArtwork.archiveArtworkNumber}`}
              </Text>
            )}
            {renderStatusSelector()}

            <Text size="small" ml="m" whiteSpace="nowrap">
              {lastModifiedDate
                ? `${UiLabels.lastUpdated} ${lastModifiedDate.toDateString()}, ${(
                    "0" + lastModifiedDate.getHours()
                  ).slice(-2)}:${("0" + lastModifiedDate.getMinutes()).slice(-2)}`
                : ""}
            </Text>
          </Flex>
        </StyledHeaderContainer>

        <Box flex="1">
          <ArtworkForm
            form="artworks.edit"
            singleTab={false}
            initialValues={initialFormValues}
            selectedArtworkIds={selectedArtworkIds}
            isMultiEditMode={selectedArtworkIds.length > 1 ? true : false}
            numArtworks={selectedArtworkIds.length}
            renderFloatingActionPillButtons={renderFloatingActionPillButtons}
            onSubmit={submit}
            mt="168px"
          />
        </Box>

        <SizeMe>
          {({ size }) => {
            if (sidebarWidth !== size.width) setSidebarWidth(size.width);
            return <ArtworkSidebar artworks={artworks} artworkPreviewNum={6} isMultiEditMode={false} />;
          }}
        </SizeMe>
      </Flex>

      <BackToTop />

      <Modal id="share">
        <ShareModal />
      </Modal>
      <Modal id="advanced_export">
        <AdvancedExportsModal />
      </Modal>
    </Flex>
  );
};

const submit = (values, dispatch) => {
  console.log("SUBMITS FORM", values);
  return dispatch(addActionToQueue(editArtwork(values), values.id));
};

export default WithRequireAccess(ArtworkDetailsScreen, 1);

//
// const initialFormValues = {
//   collection: artwork.collection,
//   artist: artwork.artist,
//   title: artwork.artworkValues.title,
//   year: artwork.artworkValues.year,
//   edition: ,
//   seriesTitle: ,
//   dimensions: {
//     height: ,
//     width: ,
//     depth: ,
//     variable: ,
//   } ,
//   technique: ,
//   workDescription: ,
//   location: ,
//   position: ,
//   currentOwner: artwork.artworkValues.currentOwner,
//   source: ,
//   price: {
//     currency: ,
//     value:
//   },
//   insuranceValue: {
//     currency: ,
//     value:
//   },
//   productionCosts: {
//     currency: ,
//     value:
//   },
//   framingCosts: {
//     currency: ,
//     value:
//   },
//   photoCopyrightOwner: ,
//   incomingDate: ,
//   packaging: {
//     crates: {},
//     instructions: ,
//   },
//   notes: ,
//   images: {
//     saved: ,
//   }
// };
