import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { layout } from "styled-system";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { SizeMe } from "react-sizeme";
import { Flex } from "../fundamentals";

const StyledTransitionGroup = styled(TransitionGroup)`
  min-height: 100%;
  .pageTransition-enter {
    opacity: 0;
  }
  .pageTransition-enter.pageTransition-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 0.65s, opacity 0.5s;
  }
  .pageTransition-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .pageTransition-exit.pageTransition-exit-active {
    opacity: 0;
    transition: transform 0.65s, opacity 0.5s;
  }
  ${props => props.transition && `transition: ${props.transition};`}
  ${layout}
`;

const StyledFirstChild = styled(Flex)`
  position: absolute;
  width: 100%;
  flex-direction: column;
  &.pageTransition-enter {
    transform: translate3d(-100%, 0, 0);
  }

  &.pageTransition-exit.pageTransition-exit-active {
    transform: translate3d(-100%, 0, 0);
  }
  ${props => props.childAlignment && `align-items: ${props.childAlignment};`}
`;

const StyledSubsequentChild = styled(Flex)`
  position: absolute;
  width: 100%;
  flex-direction: column;
  &.pageTransition-enter {
    transform: translate3d(100%, 0, 0);
  }
  &.pageTransition-exit.pageTransition-exit-active {
    transform: translate3d(100%, 0, 0);
  }
  ${props => props.childAlignment && `align-items: ${props.childAlignment};`}
`;

export const PageTransitions = ({
  page,
  children,
  initialWidth = 0,
  initialHeight = 0,
  onPageChange,
  transition,
  timeout = 650,
  childAlignment
}) => {
  const ref = useRef(true);
  useEffect(() => {
    if (ref.current && onPageChange) onPageChange(page);
  }, [onPageChange, page]);
  const [childHeight, setChildHeight] = useState(initialHeight);
  const [childWidth, setChildWidth] = useState(initialWidth);
  const childIndex = children.findIndex(child => child.props.page === page);
  const child = childIndex !== undefined ? children[childIndex] : null;
  const AnimationWrapper = childIndex === 0 ? StyledFirstChild : StyledSubsequentChild;
  return (
    <StyledTransitionGroup ref={ref} width={childWidth} height={childHeight} transition={transition}>
      <CSSTransition
        classNames="pageTransition"
        height="100%"
        timeout={{ enter: timeout, exit: timeout }}
        key={child && child.props.page}
      >
        <AnimationWrapper childAlignment={childAlignment}>
          <SizeMe monitorHeight>
            {({ size }) => {
              size.height && setChildHeight(size.height);
              size.width && setChildWidth(size.width);
              return child;
            }}
          </SizeMe>
        </AnimationWrapper>
      </CSSTransition>
    </StyledTransitionGroup>
  );
};
