import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import RootRouter from "./router/RootRouter";

import { signOut } from "../store/actions";
import { Flex } from "../components/fundamentals";
import { Footer, Modal, Joyride } from "../components/elements";
import { ErrorBoundary } from "../components/hocs";
import { FeatureChangelogModal, RemoteAlertModal, AlertModal } from "../components/modals";
import { RecordScreenAnalytics } from "../analytics";
import { Warnings } from "../localisation";
import { Helmet } from "react-helmet";

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return history.listen((location) => {
      RecordScreenAnalytics(location.pathname);
    });
  }, [history]);

  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Helmet>
        <meta
          name="description"
          content="The solution for managing and archiving your art. Start managing daily tasks, create collections, keep track of your interests and share with others using simplify.art."
        />
      </Helmet>
      <ErrorBoundary>
        <Flex flex="1" bg="grey.10" justifyContent="center" fontFamily="body">
          <RootRouter />
        </Flex>
        <Joyride />

        <Footer />

        <Modal id="feature_changelog">
          <FeatureChangelogModal />
        </Modal>
        <Modal id="remote_alert">
          <RemoteAlertModal />
        </Modal>
        <Modal id="confirm_signout_modal">
          <AlertModal
            title={Warnings.logoutAlertTitle}
            message={Warnings.logoutAlertDescription}
            onAccept={() => {
              dispatch(signOut());
            }}
          />
        </Modal>
      </ErrorBoundary>
    </Flex>
  );
};

export default App;
