import produce from "immer";
import { v4 as uuid } from "uuid";
import {
  CREATE_CONTACT_LOCALLY,
  CREATE_CONTACT_SUCCESS,
  DELETE_CONTACT_SUCCESS,
  EDIT_CONTACT_LOCALLY,
  EDIT_CONTACT_SUCCESS,
  UPLOAD_CONTACT_IMAGE_SUCCESS,
  SYNC_CONTACTS,
  SIGN_OUT_SUCCESS,
  DOWNLOAD_CONTACTS_SUCCESS,
  DELETE_CONTACTS,
} from "../actions/types";

const CONTACT_INITIAL_STATE = {
  contacts: { byId: {} },
};

const contactReducer = (state = CONTACT_INITIAL_STATE, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case DOWNLOAD_CONTACTS_SUCCESS:
        return downloadContacts(draftState, action);
      case CREATE_CONTACT_LOCALLY:
        return createContactLocally(draftState, action);
      case CREATE_CONTACT_SUCCESS:
        return createContactSuccess(draftState, action);
      case UPLOAD_CONTACT_IMAGE_SUCCESS:
        return uploadContactImageSuccess(draftState, action);
      case EDIT_CONTACT_LOCALLY:
        return editContactLocally(draftState, action);
      case EDIT_CONTACT_SUCCESS:
        return editContactSuccess(draftState, action);
      case DELETE_CONTACTS:
        return deleteContacts(draftState, action);
      case DELETE_CONTACT_SUCCESS:
        return deleteContactSuccess(draftState, action);
      case SYNC_CONTACTS:
        return syncContacts(state, draftState, action);
      case SIGN_OUT_SUCCESS:
        return CONTACT_INITIAL_STATE;
      default:
        return state;
    }
  });
};

const downloadContacts = (draftState, action) => {
  action.payload.forEach((contact) => {
    draftState.contacts.byId[contact.id] = { ...contact, isFromServer: true, isModified: false, isActive: true };
  });
};

const createContactLocally = (draftState, action) => {
  const { tempContactId, ...restOfContact } = action.payload;

  draftState.contacts.byId[tempContactId] = {
    id: tempContactId,
    ...restOfContact,
    isFromServer: false,
    isModified: false,
    isActive: true,
  };
};

const createContactSuccess = (draftState, action) => {
  const { id: contactId, tempContactId } = action.payload;

  draftState.contacts.byId[contactId] = { ...draftState.contacts.byId[tempContactId] };
  draftState.contacts.byId[contactId].id = contactId;
  draftState.contacts.byId[contactId].isFromServer = true;
  delete draftState.contacts.byId[tempContactId];
};

const uploadContactImageSuccess = (draftState, action) => {
  const { contactId, connectionId, imageToConnect } = action.payload;

  const index = draftState.contacts.byId[contactId].images.findIndex((image) => image.connectionId === connectionId);

  if (index !== -1) {
    draftState.contacts.byId[contactId].images[index].key = uuid();
    draftState.contacts.byId[contactId].images[index].url = imageToConnect.url;
    draftState.contacts.byId[contactId].images[index].description = imageToConnect.description;
    draftState.contacts.byId[contactId].images[index].sortIndex = imageToConnect.sortIndex;
    draftState.contacts.byId[contactId].images[index].isFromServer = true;
  }
};

const editContactLocally = (draftState, action) => {
  const { contactValues: oldContactValues, ...oldContactData } = draftState.contacts.byId?.[action.payload.id];
  const { contactValues: newContactValues, ...newContactData } = action.payload;

  draftState.contacts.byId[action.payload.id] = {
    contactValues: { ...oldContactValues, ...newContactValues },
    ...oldContactData,
    ...newContactData.formValues,
    isModified: true,
  };
};

const editContactSuccess = (draftState, action) => {
  const { contactId } = action.payload;

  draftState.contacts.byId[contactId].isModified = false;
};

const deleteContacts = (draftState, action) => {
  const { contactIds } = action.payload;

  contactIds.forEach((contactId) => {
    draftState.contacts.byId[contactId].isActive = false;
  });
};

const deleteContactSuccess = (draftState, action) => {
  delete draftState.contacts.byId[action.payload.contactId];
};

const syncContacts = (state, draftState, action) => {
  const { contacts } = action.payload;

  contacts.forEach((contact) => {
    if (contact.isDeleted) {
      delete draftState.contacts.byId[contact.id];
      return;
    }

    draftState.contacts.byId[contact.id] = {
      ...contact,
      isFromServer: true,
      isModified: false,
      isActive: true,
    };
  });
};

export default contactReducer;
