import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { WithRequireAccess } from "../hocs";
import { Headings, Messages, LinkLabels } from "../../localisation";
import { useIsScreenMobile } from "../hooks/useMediaQuery";

import { Box, Flex, Text, Image, Link } from "../fundamentals";

const StyledWelcomeLayout = styled(Flex)`
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-image: url("/images/brand-identity/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const WelcomeScreen = () => {
  const history = useHistory();
  const isMobile = useIsScreenMobile();

  useEffect(() => {
    setTimeout(() => history.replace("/artworks"), 5000);
  }, []);

  return (
    <StyledWelcomeLayout bg="white">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image
          src={
            process.env.PUBLIC_URL +
            `/images/brand-identity/${isMobile ? "logo-icon-black.svg" : "logo_horizontal-black.svg"}`
          }
          alt="logo"
          width={isMobile ? "25px" : "184px"}
          height={isMobile ? "35px" : ""}
          p={isMobile ? "35px 30px" : "35px 50px"}
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pt="mega">
        <Box
          width={isMobile ? "350px" : "505px"}
          height={isMobile ? "300px" : "348px"}
          bg="white"
          p="xxl"
          pt="xl"
          boxShadow="medium"
          borderRadius="20px"
        >
          <Flex flexDirection="column" alignItems="center">
            <Text size="heading1" fontWeight="500" textAlign="center" mb="l" width="317px">
              {Headings.accountCreated}
            </Text>
            <Text size="small" fontWeight="400" textAlign="center" width="300px" mb="s">
              {Messages.welcomeRedirectText}
            </Text>
            <Text size="small" fontWeight="400" textAlign="center" width="250px">
              {Messages.welcomeNotRedirectedText}
              <Link to="/artworks" replace>
                {LinkLabels.here}
              </Link>
            </Text>
          </Flex>
        </Box>
      </Box>
    </StyledWelcomeLayout>
  );
};

export default WithRequireAccess(WelcomeScreen, 1);
