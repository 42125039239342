import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ProtectedRoute } from "../../components/hocs";
// screens
import ArchiveScreen from "../../components/screens/ArchiveScreen";
import AddArtworkScreen from "../../components/screens/AddArtworkScreen";
import ArtworkDetailsScreen from "../../components/screens/ArtworkDetailsScreen";
import EditMultipleArtworkScreen from "../../components/screens/EditMultipleArtworkScreen";
import ShareArtworkDetailsScreen from "../../components/screens/ShareArtworkDetailsScreen";

import ContactsScreen from "../../components/screens/ContactsScreen";
import AddContactScreen from "../../components/screens/AddContactScreen";
import EditContactScreen from "../../components/screens/EditContactScreen";

import SettingsScreen from "../../components/screens/SettingsScreen";
import ExpiredPaymentScreen from "../../components/screens/ExpiredPaymentScreen";
import ExpiredPaymentSuccessScreen from "../../components/screens/ExpiredPaymentSuccessScreen";
import ComingSoonScreen from "../../components/screens/ComingSoonScreen";

import AuthRouter from "./AuthRouter";

import { Messages } from "../../localisation";

const RootRouter = () => {
  return (
    <Switch>
      <ProtectedRoute path="/artworks" exact minAccessLevel={1} component={ArchiveScreen} />
      <ProtectedRoute
        path="/artists"
        exact
        minAccessLevel={1}
        message={Messages.upComingArtistFeature}
        component={() => <ComingSoonScreen message={Messages.upComingArtistFeature} />}
      />
      <ProtectedRoute path="/artworks/add" exact minAccessLevel={1} component={AddArtworkScreen} />
      <ProtectedRoute path="/artworks/details" exact minAccessLevel={1} component={ArtworkDetailsScreen} />
      <ProtectedRoute path="/artworks/multi-edit" exact minAccessLevel={1} component={EditMultipleArtworkScreen} />
      <ProtectedRoute path="/settings" exact minAccessLevel={1} component={SettingsScreen} />
      <ProtectedRoute path="/share/artworks/details" exact minAccessLevel={1} component={ShareArtworkDetailsScreen} />
      <ProtectedRoute path="/expired-payment-method" exact minAccessLevel={1} component={ExpiredPaymentScreen} />

      <ProtectedRoute path="/contacts" exact minAccessLevel={1} component={ContactsScreen} />
      <ProtectedRoute path="/contacts/add" exact minAccessLevel={1} component={AddContactScreen} />
      <ProtectedRoute path="/contacts/edit" exact minAccessLevel={1} component={EditContactScreen} />

      <ProtectedRoute
        path="/lists"
        exact
        minAccessLevel={1}
        component={() => <ComingSoonScreen message={Messages.upComingListFeature} />}
      />
      <ProtectedRoute
        path="/exhibitions"
        exact
        minAccessLevel={1}
        component={() => <ComingSoonScreen message={Messages.upComingExhibitionFeature} />}
      />
     
      <ProtectedRoute path="/discovery" exact minAccessLevel={1} component={ArchiveScreen} />
      <ProtectedRoute
        path="/expired-payment-method/success"
        exact
        minAccessLevel={1}
        component={ExpiredPaymentSuccessScreen}
      />

      {/*Not Implemented Yet*/}
      {/*<ProtectedRoute path="/viewingroom/:id" exact component={ViewingRoomLayout} />*/}

      <Route render={(props) => <AuthRouter {...props} />} />

      <Redirect from="*" to="/artworks" />
    </Switch>
  );
};

export default RootRouter;
