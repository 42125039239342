const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      //Cumulative layout shift
      getCLS(onPerfEntry);
      //First input delay
      getFID(onPerfEntry);
      //First contentful paint
      getFCP(onPerfEntry);
      //Largest contentful paint
      getLCP(onPerfEntry);
      //Time to first byte
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
