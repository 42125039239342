import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { WithRequireAccess } from "../hocs";
import { useSelector, useDispatch } from "react-redux";
import {
  addActionToQueue,
  createArtwork,
  createArtworkAndCopy,
  createArtworkAndNew,
  clearUnactiveCacheImages,
} from "../../store/actions";
import { selectSelectedCollection, selectUser } from "../../store/selectors";
import { ButtonLabels } from "../../localisation";

import { Box, Flex } from "../fundamentals";
import { Button } from "../elements";
import { ArtworkForm } from "../forms";
import { BackToTop } from "../modules";

const renderFloatingActionPillButtons = (reset, dirty, pristine, submitting, handleSubmit) => {
  return [
    <Button
      key="save"
      type="submit"
      buttonStyle="text"
      size="medium"
      inverted={true}
      disabled={submitting}
      icon={submitting ? "Loader" : "UploadCloud"}
    >
      {ButtonLabels.saveArtwork}
    </Button>,

    <Button
      key="saveAndCopy"
      buttonStyle="text"
      size="medium"
      inverted={true}
      disabled={submitting}
      icon={submitting ? "Loader" : "Copy"}
      onClick={handleSubmit(submitAndCopy)}
      preventDefault
    >
      {ButtonLabels.saveAndCopy}
    </Button>,

    <Button
      key="saveAndNew"
      buttonStyle="text"
      size="medium"
      inverted={true}
      disabled={submitting}
      icon={submitting ? "Loader" : "PlusCircle"}
      onClick={handleSubmit(submitAndNew)}
    >
      {ButtonLabels.saveAndNew}
    </Button>,

    <Button
      key="reset"
      buttonStyle="text"
      size="medium"
      inverted={true}
      onClick={!pristine ? reset : null}
      disabled={submitting}
      preventDefault
      icon={submitting ? "Loader" : "RotateCcw"}
    >
      {ButtonLabels.reset}
    </Button>,
  ];
};

const AddArtworkScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCollection = useSelector(selectSelectedCollection);
  const user = useSelector(selectUser);

  const initialValues = {
    collection: {
      id: selectedCollection.id,
    },
    artist: selectedCollection.type === "production" ? user.name : "",
    currentOwner: selectedCollection.type === "production" ? user.name : "",
    images: [],
  };

  return (
    <Flex flex="1" flexDirection="column" bg="white">
      <Flex justifyContent="space-between" alignSelf="stretch" p="l" pt="m">
        <Button
          buttonStyle="text"
          size="small"
          icon="ArrowLeft"
          pl="none"
          onClick={() => {
            history.goBack();
            dispatch(clearUnactiveCacheImages());
          }}
        >
          {ButtonLabels.back}
        </Button>
      </Flex>
      <Flex position="relative" flex="1" justifyContent="center">
        <Box flex="1" maxWidth="1200px">
          <ArtworkForm
            form="artworks.add"
            singleTab={true}
            initialValues={initialValues}
            selectedArtworkIds={[]}
            isMultiEditMode={false}
            numArtworks={0}
            renderFloatingActionPillButtons={renderFloatingActionPillButtons}
            onSubmit={submit}
          />
        </Box>
      </Flex>
      <BackToTop />
    </Flex>
  );
};

const submit = (values, dispatch) => {
  console.log("EXECUTES");
  console.dir(values);
  const tempArtworkId = uuid();
  return dispatch(addActionToQueue(createArtwork(values, tempArtworkId), tempArtworkId));
};

const submitAndCopy = (values, dispatch) => {
  console.log("EXECUTES");
  console.dir(values);
  const tempArtworkId = uuid();
  return dispatch(addActionToQueue(createArtworkAndCopy(values, tempArtworkId), tempArtworkId));
};

const submitAndNew = (values, dispatch) => {
  console.log("EXECUTES");
  console.dir(values);
  const tempArtworkId = uuid();
  return dispatch(addActionToQueue(createArtworkAndNew(values, tempArtworkId), tempArtworkId));
};

export default WithRequireAccess(AddArtworkScreen, 1);
