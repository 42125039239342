import { all, put, take, fork, delay, cancel, cancelled, takeLatest } from "redux-saga/effects";
import {
  INITIALISE_QUEUE_SYSTEM_CLEANER,
  START_QUEUE_SYSTEM_CLEANER,
  STOP_QUEUE_SYSTEM_CLEANER
} from "../actions/types";
import { addFailedActionsToQueue, queueCleanedSuccess, queueCleanedFailure } from "../actions";

function* backgroundQueueCleanerSaga() {
  try {
    while (true) {
      yield delay(30000);

      yield all([put(addFailedActionsToQueue()), put(queueCleanedSuccess())]);
    }
  } catch (error) {
    console.log(error);
    yield put(queueCleanedFailure());
  } finally {
    if (yield cancelled()) yield put(queueCleanedFailure());
  }
}

function* queueCleaner() {
  while (yield take(START_QUEUE_SYSTEM_CLEANER)) {
    // starts the task in the background
    const backgroundQueueCleanerTask = yield fork(backgroundQueueCleanerSaga);

    // wait for the user to click signout
    yield take(STOP_QUEUE_SYSTEM_CLEANER);

    // user clicked signout. cancel the background task
    // this will cause the forked backgroundQueueCleanerTask task to jump into its finally block
    yield cancel(backgroundQueueCleanerTask);
  }
}

function* queueCleanerSaga() {
  yield takeLatest(INITIALISE_QUEUE_SYSTEM_CLEANER, queueCleaner);
}

export default queueCleanerSaga;
