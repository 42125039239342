import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Box, Button } from "@mui/material";
import { ChevronDown } from "react-feather";
import React from "react";
import { ButtonLabels, InputLabels, UiLabels } from "../../../localisation";
import { change, Field, FormSection } from "redux-form";
import { Input } from "../Input/Input";
import { InputSelect } from "../InputSelect/InputSelect";
import { XCircle } from "react-feather";
import CountryData from "country-region-data";
import { useDispatch } from "react-redux";
import { RenderError } from "../Input";

const labelStyle = {
  width: "151px",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#6A6870",
  fontFamily: "Inter",
};

const stackStyle = {
  borderBottom: "1px solid #C3C2C9",
  alignItems: "center",
  justifyContent: "space-between",
  height: "50px",
};

export const ContactAdditionalFormSection = ({ form, reset }) => {
  const dispatch = useDispatch();
  const resetForm = () => {
    dispatch(change(form, "ContactAdditionalFormSection", {}));
  };
  const fieldStyle = {
    flexGrow: 1,
    ".MuiFilledInput-root": {
      backgroundColor: "white",
      height: "50px",
      "&:hover": {
        backgroundColor: "#F3F4FF",
        borderBottom: "2px solid #C8CEFF",
        marginBottom: "-1px",
      },
      "&:before": {
        content: "none",
      },
      "&.Mui-focused": {
        borderBottom: "none",
        backgroundColor: "#F3F4FF",
        marginBottom: "-1px",
      },
    },
  };

  return (
    <FormSection name="ContactAdditionalFormSection">
      <Accordion sx={{ mt: "2.5rem" }}>
        <AccordionSummary
          sx={{ paddingBottom: "0px", paddingTop: "0px" }}
          expandIcon={<ChevronDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ fontSize: "20px", lineHeight: "28px" }}>{UiLabels.additional}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: "0px" }}>
          <Stack>
            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.website}</Typography>
                <Field name="websiteUrl" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="websiteUrl" component={RenderError} />
            </Box>

            <Box>
              <Stack sx={stackStyle} direction="row">
                <Typography sx={labelStyle}>{InputLabels.instagramUrl}</Typography>
                <Field name="instagramUrl" formName={form} component={Input} sx={fieldStyle} hideError={true} />
              </Stack>
              <Field name="instagramUrl" component={RenderError} />
            </Box>

            <Box sx={{ borderBottom: "1px solid #C3C2C9" }}>
              <Stack direction="row" height="50px" alignItems="center" justifyContent="space-between">
                <Typography sx={{ width: "151px", fontSize: "14px", color: "#6A6870" }}>
                  {InputLabels.position}
                </Typography>

                <Field name="position" formName={form} component={Input} sx={fieldStyle} />
              </Stack>
            </Box>
            <Box sx={{ borderBottom: "1px solid #C3C2C9" }}>
              <Stack direction="row" alignItems="center" height="50px" justifyContent="space-between">
                <Typography sx={{ width: "151px", fontSize: "14px", color: "#6A6870" }}>{InputLabels.vat}</Typography>

                <Field name="vatNumber" formName={form} component={Input} sx={fieldStyle} />
              </Stack>
            </Box>
          </Stack>
          <Stack sx={{ marginTop: "72px" }}>
            <Box sx={{ borderBottom: "1px solid #C3C2C9" }}>
              <Stack direction="row" alignItems="center" height="50px" justifyContent="space-between">
                <Typography sx={{ width: "151px", fontSize: "14px", color: "#6A6870" }}>
                  {InputLabels.addressLine1}
                </Typography>

                <Field name="address1" formName={form} component={Input} sx={fieldStyle} />
              </Stack>
            </Box>
            <Box sx={{ borderBottom: "1px solid #C3C2C9" }}>
              <Stack direction="row" alignItems="center" height="50px" justifyContent="space-between">
                <Typography sx={{ width: "151px", fontSize: "14px", color: "#6A6870" }}>
                  {InputLabels.addressLine2}
                </Typography>

                <Field name="address2" formName={form} component={Input} sx={fieldStyle} />
              </Stack>
            </Box>
            <Stack direction="row" spacing={1}>
              <Box sx={{ borderBottom: "1px solid #C3C2C9", width: "380px" }}>
                <Stack direction="row" alignItems="center" height="55px" justifyContent="space-between">
                  <Typography sx={{ width: "151px", fontSize: "14px", color: "#6A6870" }}>
                    {InputLabels.city}
                  </Typography>
                  <Field name="city" component={Input} sx={{ ...fieldStyle, paddingTop: "5px", width: "223px" }} />
                </Stack>
              </Box>

              <Box sx={{ borderBottom: "1px solid #C3C2C9", width: "380px" }}>
                <Stack direction="row" alignItems="center" height="55px" justifyContent="space-between">
                  <Typography sx={{ width: "125px", fontSize: "14px", color: "#6A6870" }}>
                    {InputLabels.postCode}
                  </Typography>
                  <Field
                    name="postalCode"
                    formName={form}
                    component={Input}
                    sx={{ ...fieldStyle, paddingTop: "5px" }}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box sx={{ borderBottom: "1px solid #C3C2C9", width: " 380px" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" height="55px">
                  <Typography sx={{ width: "151px", fontSize: "14px", color: "#6A6870" }}>
                    {InputLabels.state}
                  </Typography>

                  <Field
                    name="state"
                    formName={form}
                    component={Input}
                    sx={{ ...fieldStyle, paddingTop: "5px", width: "223px" }}
                  />
                </Stack>
              </Box>
              <Box
                sx={{
                  width: " 374px",
                  borderBottom: "1px solid #C3C2C9",
                  display: "flex",
                  // alignItems: "center",
                  height: "55px",
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography sx={{ width: "125px", fontSize: "14px", color: "#6A6870" }}>
                    {InputLabels.country}
                  </Typography>
                  <Field
                    name="country"
                    component={InputSelect}
                    sx={{
                      width: "250px",
                      minHeight: "55px",
                      "&.MuiFilledInput-root": { backgroundColor: "white" },
                      "& .MuiSelect-filled": { backgroundColor: "white" },
                    }}
                    options={CountryData.map((country) => ({ value: country.countryName, label: country.countryName }))}
                  />
                </Stack>
              </Box>
            </Stack>
            <Box sx={{ borderBottom: "1px solid #C3C2C9", marginTop: "72px" }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ width: "151px", fontSize: "14px", color: "#6A6870", pt: "1.1rem" }}>
                  {InputLabels.notes}
                </Typography>

                <Field
                  name="notes"
                  component={Input}
                  formName={form}
                  sx={{
                    flexGrow: 1,
                    ".MuiFilledInput-root": {
                      paddingTop: "0px",
                      "&:hover": {
                        backgroundColor: "#F3F4FF",
                        borderBottom: "2px solid #C8CEFF",
                        marginBottom: "-1px",
                      },
                      "&:before": {
                        content: "none",
                      },
                      "&.Mui-focused": {
                        borderBottom: "none",
                        backgroundColor: "#F3F4FF",
                        marginBottom: "-1px",
                      },
                    },
                  }}
                  multiline={true}
                />
              </Stack>
            </Box>
            <Stack direction="row-reverse" alignItems="center" sx={{ marginTop: "24px" }}>
              <Button
                startIcon={<XCircle style={{ width: "16px", height: "16px", position: "relative", right: "-5px" }} />}
                sx={{ paddingRight: "0", paddingTop: "0px", fontSize: "14px", fontWeight: 500 }}
                onClick={resetForm}
              >
                {ButtonLabels.clear}
              </Button>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </FormSection>
  );
};
