import React, { useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../config";

import {
  getPaymentMethod,
  cancelSubscription,
  changeSubscription,
  openModal,
  getSubscription,
  restartSubscription,
} from "../../store/actions";
import { selectUser } from "../../store/selectors";
import { STRIPE_PLANS } from "../../config";

import { Box, Flex, Separator, Text } from "../fundamentals";
import { Section, Button, Modal } from "../elements";
import { ChangePaymentMethodModal, AlertModal } from "../modals";

import { Headings, DynamicLabels, ButtonLabels, Warnings, UiLabels, ApiErrors } from "../../localisation";

const BillingSettingsScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const paymentMethodLoading = useSelector((state) => state.ui.paymentMethodLoading);
  const subscriptionLoading = useSelector((state) => state.ui.subscriptionLoading);
  const currentPlan = _.find(STRIPE_PLANS, (stripePlan) => stripePlan.plan === user.account.activePlan);
  const alternativePlan = _.find(STRIPE_PLANS, (stripePlan) => {
    return stripePlan.plan === currentPlan.alternative_plan;
  });

  useEffect(() => {
    dispatch(getPaymentMethod());
    dispatch(getSubscription());
  }, []);

  const handleOnCancelSubscription = () => {
    dispatch(openModal("cancel_subscription_modal"));
  };
  const handleOnChangeBillingPeriod = () => {
    dispatch(openModal("change_billing_period_modal"));
  };
  const handleOnRestartSubscription = () => {
    dispatch(openModal("restart_subscription_modal"));
  };

  return (
    <Box flex="1">
      <Text size="heading2" mb="xxl">
        {Headings.subscriptionAndBilling}
      </Text>
      <Section title={Headings.cardDetails} border="1px solid #C3C2C9" borderRadius="10px" width="615px" mb="m" p="l">
        {paymentMethodLoading && <Text>Loading...</Text>}

        {!paymentMethodLoading && !user.account.card && (
          <Flex mb="m">
            <Flex>
              <Text size="body" color="grey.90">
                {ApiErrors.failedToGetCardDetails}
              </Text>
            </Flex>
          </Flex>
        )}

        {!paymentMethodLoading && user.account.card && (
          <Box>
            <Flex mb="m" flexDirection="column">
              <Box display="flex">
                <Text size="body" color="grey.90" width="200px">
                  {UiLabels.cardNumber}
                </Text>
                <Text size="body" color="black">
                  **** **** **** {user.account.card.lastFourNumbers}
                </Text>
              </Box>

              <Separator />
              <Box display="flex">
                <Text size="body" color="grey.90" width="200px">
                  {UiLabels.expirationDate}
                </Text>
                <Text size="body" color="black">
                  {("0" + user.account.card.expMonth).slice(-2)} / {user.account.card.expYear.toString().slice(-2)}
                </Text>
              </Box>
            </Flex>
          </Box>
        )}
      </Section>

      {!paymentMethodLoading && (
        <Button
          buttonStyle="primary"
          size="medium"
          fontFamily="Inter"
          mb="m"
          px="s"
          py="xxs"
          fontWeight={500}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          onClick={() => dispatch(openModal("change_payment_card"))}
        >
          {ButtonLabels.updateCard}
        </Button>
      )}
      <Separator mb="xl" />
      <Section title={Headings.subscription} border="1px solid #C3C2C9" borderRadius="10px" width="615px" p="l" mb="m">
        {subscriptionLoading && <Text>Loading...</Text>}

        {!subscriptionLoading && (
          <Flex mb="m" flexDirection="column">
            <Box display="flex">
              <Text size="body" color="grey.90" width="200px">
                {UiLabels.plan}
              </Text>

              <Text size="body" color="black">
                {currentPlan && currentPlan.label}
              </Text>
            </Box>
            <Separator mb="xs" />
            <Box display="flex">
              <Text size="body" color="grey.90" width="200px">
                {UiLabels.status}
              </Text>

              <Text size="body" color="black">
                {moment().unix() <= user?.account?.subscription?.trialEnd * 1000 ? "Free Trial" : ""}
                {user.account.activePlan !== "none" && !user?.account?.subscription?.cancelAtPeriodEnd ? (
                  <Text
                    size="smaller"
                    ml="xxs"
                    fontWeight={500}
                    fontFamily="Inter"
                    bg="#FFE49A"
                    p="4px 8px"
                    borderRadius="4px"
                  >
                    Active
                  </Text>
                ) : (
                  <Text
                    size="smaller"
                    ml="xxs"
                    fontWeight={500}
                    fontFamily="Inter"
                    bg="#FF3737"
                    color="white"
                    p="4px 8px"
                    borderRadius="4px"
                  >
                    Cancelled
                  </Text>
                )}
              </Text>
            </Box>
            <Separator mb="xs" />
            {user.account.subscription && (
              <Box display="flex">
                <Text size="body" color="grey.90" width="200px">
                  {UiLabels.billingPeriodStart}
                </Text>
                {user.account.subscription && (
                  <Text size="body" color="black">
                    {moment(user.account.subscription.create).format("ddd DD MMM YYYY")}
                  </Text>
                )}
              </Box>
            )}
            <Separator mb="xs" />
            {user.account.subscription && (
              <Box display="flex">
                <Text size="body" color="grey.90" width="200px">
                  {UiLabels.billingPeriodEnd}
                </Text>
                {user.account.subscription && (
                  <Text size="body" color="black">
                    {moment(user.account.subscription.currentPeriodEnd * 1000).format("ddd DD MMM YYYY")}
                  </Text>
                )}
              </Box>
            )}
          </Flex>
        )}
      </Section>
      {!subscriptionLoading && user.account.subscription && (
        <Flex justifyContent="space-between" width="404px">
          <Button
            buttonStyle="primary"
            size="medium"
            fontFamily="Inter"
            fontWeight={500}
            px="s"
            py="xxs"
            flip={true}
            iconOffset="xs"
            onClick={handleOnChangeBillingPeriod}
          >
            {ButtonLabels.changeBillingPeriod}
          </Button>
          {user.account.activePlan !== "none" && !user.account.subscription.cancelAtPeriodEnd ? (
            <Button
              buttonStyle="primary"
              size="medium"
              fontFamily="Inter"
              fontWeight={500}
              px="s"
              py="xxs"
              flip={true}
              iconOffset="xs"
              onClick={handleOnCancelSubscription}
            >
              {ButtonLabels.cancelSubscription}
            </Button>
          ) : (
            <Button
              buttonStyle="primary"
              size="medium"
              fontFamily="Inter"
              fontWeight={500}
              px="s"
              py="xxs"
              flip={true}
              iconOffset="xs"
              onClick={handleOnRestartSubscription}
            >
              {ButtonLabels.restartSubscription}
            </Button>
          )}
        </Flex>
      )}

      {/* Modals for Subscription and Billing Screen */}
      <Modal id="change_payment_card">
        <Elements stripe={stripePromise}>
          <ChangePaymentMethodModal />
        </Elements>
      </Modal>

      <Modal id="cancel_subscription_modal" modalRadius="10px" width="440px">
        <AlertModal
          title={Warnings.cancelSubscription}
          message={Warnings.cancelSubscriptionDesc}
          acceptButtonText={ButtonLabels.cancelSubscriptionConfirmation}
          onAccept={() => {
            dispatch(cancelSubscription());
          }}
        />
      </Modal>

      <Modal id="change_billing_period_modal" modalRadius="10px" width="440px">
        <AlertModal
          title={DynamicLabels.changeBillingPeriod(currentPlan.billing_period === "monthly" ? "yearly" : "monthly")}
          message={DynamicLabels.changeBillingPeriodMessage(
            user.account.subscription && user.account.subscription.currentPeriodEnd,
            alternativePlan && alternativePlan.price,
            alternativePlan && alternativePlan.billing_period,
            user.account.card && user.account.card.lastFourNumbers
          )}
          highlightWords={[
            `${moment(user?.account?.subscription?.currentPeriodEnd * 1000).format("ddd MMM DD YYYY")},`,
            `€${alternativePlan?.price}`,
            alternativePlan?.billing_period === "monthly" ? "each month" : "each year",
          ]}
          acceptButtonText={ButtonLabels.changeBillingPeriodConfirmation}
          onAccept={() => {
            dispatch(changeSubscription(alternativePlan.plan));
          }}
        />
      </Modal>

      <Modal id="restart_subscription_modal">
        <AlertModal
          title={"Restarting your subscription"}
          // message={Warnings.logoutAlertDescription}
          onAccept={() => {
            dispatch(restartSubscription());
          }}
        />
      </Modal>
    </Box>
  );
};

export default BillingSettingsScreen;
