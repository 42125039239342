import React from "react";
import { Field, reduxForm } from "redux-form";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { changePaymentMethod } from "../../store/actions";
import { ButtonLabels, Headings } from "../../localisation";

import { Box, Text } from "../fundamentals";
import { Button, StripeCardInput } from "../elements";

const Form = ({ handleSubmit, submitting }) => {
  const stripe = useStripe();
  const elements = useElements();

  const submit = (values, dispatch) => {
    if (submitting) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    return dispatch(changePaymentMethod.request({ stripe, elements }));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box minWidth="420px">
        <Text size="heading3" mb="xxl" color="black">
          {Headings.updateCardDetails}
        </Text>
        <Field name="card" component={StripeCardInput} />
        <Button
          buttonStyle="primary"
          type="submit"
          size="medium"
          iconSpinning={true}
          disabled={submitting}
          icon={submitting ? "Loader" : null}
          flip={true}
          iconOffset="xs"
          mt="m"
        >
          {ButtonLabels.updateCard}
        </Button>
      </Box>
    </form>
  );
};

const ChangePaymentMethodModal = reduxForm({
  form: "changePaymentMethod",
})(Form);

export { ChangePaymentMethodModal };
