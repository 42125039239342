import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import css from "@styled-system/css";
import _ from "lodash";

import Select from "react-select";
import { Box, Flex, Icon, Text } from "../fundamentals";

const StyledSelectControl = styled(Flex)`
  position: relative;
  height: 40px;
  ${(props) =>
    css({
      bg: props.error ? "rgba(255, 55, 55, 0.1)" : "#F3F4FF",
      [props.error && !props.active ? "border" : "borderBottom"]: [
        props.error && !props.active ? "1px solid" : "2px solid",
      ],
      borderColor: props.error ? "red" : "black",
      "&:hover": {
        bg: "#F3F4FF",
      },
      "&:focus-within": {
        bg: props.focusBackground ?? "#F3F4FF",
        borderBottomColor: props.error ? "red" : "#5E54FF",
        borderBottomWidth: "2px",
      },
    })};
  ${(props) =>
    props.isDisabled &&
    `
    border: none;
    color: black !important;
  `}
`;

const StyledSelectOption = styled(Flex)`
  padding: 8px 12px;
  ${(props) =>
    css({
      color: props.isSelected ? "blue.60" : "black",
      padding: "xxs",
      paddingLeft: "s",
      paddingRight: "s",
      ":hover": { backgroundColor: "grey.20" },
      ":active": { backgroundColor: "grey.30" },
    })}
`;

export const InputSelect = withTheme(
  ({
    input,
    meta: { error, ...meta },
    warning,
    info,
    options,
    label,
    stateful,
    disabled,
    placeholder,
    theme,
    additionalOnChange,
    hideError,
    ...props
  }) => {
    const [selectRef, setSelectRef] = useState();
    const displayError = error && (meta.visited || meta.submitFailed) && error;
    const feedback = ((meta.active || !meta.pristine) && warning) || info;
    return (
      <Box userSelect="none" pb={hideError ? "none" : "l"} {...props}>
        {label && (
          <Text fontSize="small" lineHeight="button" color="grey.90" mb="xxxs">
            {label}
          </Text>
        )}
        <Select
          ref={(ref) => setSelectRef(ref)}
          onFocus={() => input.onFocus()}
          onBlur={() => input.onBlur()}
          value={input.value ? _.find(options, (option) => _.isEqual(option.value, input.value)) : null}
          components={{
            Control: SelectContainer,
            IndicatorSeparator: null,
            DropdownIndicator: SelectIndicatorsContainer,
            Option: SelectOptionContainer,
            SingleValue,
            Placeholder,
          }}
          styles={{
            menu: () => ({
              position: "absolute",
              backgroundColor: "white",
              minWidth: "100%",
              zIndex: "1",
              boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.12)",
            }),
          }}
          onChange={(selected) => {
            input.onChange(selected.value);
            if (additionalOnChange) additionalOnChange(selected.value, { ...meta, prevValue: input.value });
          }}
          onMenuClose={() => selectRef && selectRef.select.blur()}
          options={options}
          isDisabled={disabled}
          menuPortalTarget={document.querySelector(".ReactModal__Overlay")}
          stateful={stateful}
          error={displayError}
          placeholder={placeholder}
          {...props}
        />
        <Box position="relative">
          <Flex
            position="absolute"
            alignItems="center"
            color={displayError ? "red" : warning ? "amber.90" : "grey.80"}
            overflow="hidden"
            height={hideError && !displayError && !feedback ? 4 : 24}
            whiteSpace="nowrap"
            mb="xxs"
          >
            {(feedback || displayError) && <Icon icon="Info" size="14" mr="xxxs" />}
            <Text fontSize="smaller" lineHeight="button">
              {displayError || feedback}
            </Text>
          </Flex>
        </Box>
      </Box>
    );
  }
);

const SelectContainer = ({ className, children, innerProps, innerRef, isDisabled, ...props }) => {
  return (
    <StyledSelectControl ref={innerRef} isDisabled={isDisabled} error={props.selectProps.error} {...innerProps}>
      {children}
    </StyledSelectControl>
  );
};

const SelectIndicatorsContainer = ({ innerProps, innerRef, isDisabled, selectProps, ...props }) => {
  return isDisabled || selectProps.hideSelectIndicator ? null : (
    <Icon
      icon={props.isFocused ? "ChevronUp" : "ChevronDown"}
      size={selectProps.selectIndicatorSize}
      color="black"
      mr="xxxs"
      {...innerProps}
    />
  );
};

const SelectOptionContainer = ({ children, innerRef, innerProps, isSelected, ...props }) => {
  return (
    <StyledSelectOption ref={innerRef} isSelected={isSelected} {...innerProps}>
      {props.selectProps.stateful && (
        <Box>
          <Icon icon="Check" color={isSelected ? "blue.60" : "transparent"} mr="xs" />
        </Box>
      )}
      <Text fontSize="15px" lineHeight="body" color="black">
        {children}
      </Text>
    </StyledSelectOption>
  );
};

const Placeholder = ({ children, ...props }) => {
  return <Text color='#000000' fontFamily='Inter' fontSize='14px' lineHeight='18px'>{children}</Text>;
};

const SingleValue = ({ children, ...props }) => {
  return (
    <Text fontSize="15px" lineHeight="body" color="black">
      {props.selectProps.formatSelected ? props.selectProps.formatSelected(children) : children}
    </Text>
  );
};
