import React, { useState, useEffect } from "react";
import { Box, ImageWithCache } from "../fundamentals";
import { Button } from "../elements";

const ImageViewerModal = ({ images, initialIndex = 0 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);

  useEffect(() => {
    function keydown(e) {
      switch (e.keyCode) {
        case 37:
          currentImageIndex !== 0 && setCurrentImageIndex(currentImageIndex - 1);
          break;
        case 39:
          currentImageIndex !== images.length - 1 && setCurrentImageIndex(currentImageIndex + 1);
          break;
        default:
          break;
      }
    }
    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  });

  return (
    <Box position="relative" maxWidth="85vw">
      {currentImageIndex !== 0 && (
        <Button
          position="fixed"
          top="0"
          bottom="0"
          left="-5px"
          buttonStyle="text"
          icon="ArrowLeft"
          size="large"
          color="white"
          onClick={() => setCurrentImageIndex(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1)}
        />
      )}

      <ImageWithCache
        image={images[currentImageIndex]}
        maxHeight="92vh"
        maxWidth="100%"
        width="auto"
        urlSuffix="/medium.jpg"
      />

      {images.length > currentImageIndex + 1 && (
        <Button
          position="fixed"
          top="0"
          bottom="0"
          right="-5px"
          buttonStyle="text"
          icon="ArrowRight"
          size="large"
          color="white"
          onClick={() => setCurrentImageIndex(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1)}
        />
      )}
    </Box>
  );
};

export { ImageViewerModal };
