import React from "react";
import styled, { withTheme } from "styled-components";
import { WithRequireAccess } from "../hocs";
import { Box, Flex, Image, Text, Link } from "../fundamentals";
import { Instructions, LinkLabels } from "../../localisation";
import ExpiredPaymentForm from "../forms/ExpiredPaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../config";

const StyledExpiredPaymentScreen = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ExpiredPaymentScreen = ({ theme }) => {
  return (
    <StyledExpiredPaymentScreen background={theme.backgroundColor}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Image src={process.env.PUBLIC_URL + "/images/brand-identity/logo-text.png"} alt="logo" width="184px" p="l" />
        <Box display="flex" alignItems="center">
          <Text size="heading3" mr="s">
            {Instructions.needHelp}
          </Text>
          <Link to="/login" replace mr="xl" isRound="true" px="xs" py="xxxs">
            {LinkLabels.emailUs}
          </Link>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" pt="l">
        <Box width="480px" bg="white" p="l" boxShadow="medium" borderRadius="20px">
          <Elements stripe={stripePromise}>
            <ExpiredPaymentForm />
          </Elements>
        </Box>
      </Box>
    </StyledExpiredPaymentScreen>
  );
};

export default WithRequireAccess(withTheme(ExpiredPaymentScreen));
