import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import { SUPPORTED_CURRENCIES } from "../../config";

import { Box, Flex, Text } from "../fundamentals";
import { Input, InputSelect } from ".";
import { FormSection, Field, change, getFormInitialValues } from "redux-form";

const PriceInput = ({
  name,
  disabled,
  label,
  preventOverlapRef,
  scrollingRef,
  defaultCurrency,
  changeValue,
  getInitialCurrency,
  warning,
  ...props
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(false);
  const formatCurrency = value => {
    if (!value) return "";
    return `${parseFloat(value)
      .toString()
      .replace(/^[+-]?\d+/, int => int.replace(/(\d)(?=(\d{3})+$)/g, "$1,"))}${
      value[value.length - 1] === "." ? "." : ""
    }`;
  };
  const parseCurrency = value => {
    if (value) {
      const splitNumbers = value.replace(/[^0-9.]/g, "").split(".");
      if (splitNumbers[0] === "") return undefined;
      if (splitNumbers.length > 1) {
        if (splitNumbers[1] === "") return splitNumbers[0] + ".";
        else return parseFloat(splitNumbers[0] + "." + splitNumbers[1].substring(0, 2));
      }
      return parseFloat(splitNumbers[0]);
    }
    return undefined;
  };
  return (
    <FormSection name={name} flex="1">
      <Box>
        {label && (
          <Text fontSize="small" lineHeight="button" color="grey.90" mb="xxxs">
            {label}
          </Text>
        )}
        <Flex {...props}>
          <Field
            name="currency"
            options={SUPPORTED_CURRENCIES.map(value => ({ label: value, value }))}
            disabled={disabled}
            component={InputSelect}
            minWidth="50px"
            isSearchable={false}
            selectIndicatorSize={15}
            formatSelected={selected => getSymbolFromCurrency(selected)}
            placeholder={getSymbolFromCurrency(selectedCurrency || defaultCurrency)}
            additionalOnChange={(value, { prevValue, form }) => {
              setSelectedCurrency(value);
              if (prevValue === "") changeValue(form, name, "");
            }}
          />
          <Field
            name="value"
            format={formatCurrency}
            parse={parseCurrency}
            preventOverlapRef={preventOverlapRef}
            scrollingRef={scrollingRef}
            disabled={disabled}
            component={Input}
            warning={warning}
            additionalOnBlur={(value, { form }) => {
              if (`${value}`.slice(-1) === ".") changeValue(form, `${name}.value`, parseFloat(parseCurrency(value)));
            }}
            additionalOnChange={(value, { initial, prevValue, form }) => {
              if (value === "" || value.replace(/\D/g, "") === "") changeValue(form, name, "");
              else if (!prevValue) {
                changeValue(form, `${name}.currency`, selectedCurrency || getInitialCurrency(form) || defaultCurrency);
              }
            }}
            flex="1"
          />
        </Flex>
      </Box>
    </FormSection>
  );
};

function mapStateToProps(state, ownProps) {
  const { user } = state;

  return {
    defaultCurrency: user.settings && user.settings.defaultCurrency ? user.settings.defaultCurrency : "",
    getInitialCurrency: form => {
      const initialValues = getFormInitialValues(form)(state)[ownProps.name];
      if (initialValues) return initialValues.currency;
    }
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ changeValue: change }, dispatch);
}

const ConnectedPriceInput = connect(mapStateToProps, mapDispatchToProps)(PriceInput);

export { ConnectedPriceInput as PriceInput };
