import _ from "lodash";
import { call, put, select, takeEvery } from "redux-saga/effects";
import localForage from "localforage";
import { CACHE_IMAGE, REMOVE_CACHE_IMAGE, CLEAR_UNACTIVE_CACHE_IMAGES } from "../actions/types";
import { removeCacheImage } from "../actions";

function* cacheImageSaga(action) {
  const { localId, fileAsDataUrl } = action.payload;
  try {
    yield call([localForage, localForage.setItem], localId, fileAsDataUrl);
  } catch (error) {
    console.log(error);
  }
}

function* removeCacheImageSaga(action) {
  const { localId } = action.payload;
  try {
    yield call([localForage, localForage.removeItem], localId);
  } catch (error) {
    console.log(error);
  }
}

function* clearUnactiveCacheImagesSaga() {
  try {
    const cachedImageUrls = yield select(state => state.session.cachedImageUrls);

    const cachedImages = _.map(cachedImageUrls, image => image);

    for (const image of cachedImages) {
      if (!image.isActive) yield put(removeCacheImage(image.localId));
    }
  } catch (error) {
    console.log(error);
  }
}

function* sessionSaga() {
  yield takeEvery(CACHE_IMAGE, cacheImageSaga);
  yield takeEvery(REMOVE_CACHE_IMAGE, removeCacheImageSaga);
  yield takeEvery(CLEAR_UNACTIVE_CACHE_IMAGES, clearUnactiveCacheImagesSaga);
}

export default sessionSaga;
