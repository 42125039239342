import React, { useEffect } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  ROLE_ARTIST,
  ROLE_COLLECTOR,
  ROLE_ART_PROFESSIONAL,
  ROLE_GALLERIST,
  ROLE_ART_INSTITUTION,
} from "../../constants";
import { connect } from "react-redux";
import { Headings, ButtonLabels, Info, InputPlaceholders, InputErrors } from "../../localisation";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Flex, Text, Box, Icon } from "../fundamentals";
import { Button } from "../elements";
import { useIsScreenMobile } from "../hooks/useMediaQuery";
import { Input } from "../react-material/Input";

const inputDesign = () => {
  return {
    flexGrow: 1,
    ".MuiFilledInput-root": {
      backgroundColor: "#F3F4FF",
      height: "50px",
      borderBottom: "2px solid #000000",
      width: "380px",
      "&:hover": {
        backgroundColor: "#F3F4FF",
        borderBottom: "2px solid #000000",
      },
      "&:focus": {
        backgroundColor: "#F3F4FF",
      },
      "&:before": {
        content: "none",
      },
      "&.Mui-focused": {
        backgroundColor: "#F3F4FF",
        borderBottom: "2px solid transparent",
      },
      "& .Mui-error": {
        marginLeft: "0px",
        "&:focus-within::after": {
          borderBottomColor: "#5E54FF !important",
        },
        ".MuiFilledInput-root": {
          color: "#FF3737",
          background: "#FFDFDA",
          borderBottom: "2px solid red",
        },
        ".MuiFilledInput-Input": {
          color: "#FF3737",
          background: "#FFDFDA",
          borderBottom: "2px solid red",
        },
      },
    },
  };
};

const Form = ({ handleSubmit, submitting, role }) => {
  const history = useHistory();
  const isMobile = useIsScreenMobile();

  useEffect(() => {
    if (!role) history.replace("/register");
  }, [role, history]);

  return (
    <form onSubmit={handleSubmit}>
      <Flex
        flexDirection="column"
        alignItems="center"
        width={isMobile ? "325px" : "505px"}
        bg="white"
        p={isMobile ? "m" : "60px 60px 35px 60px"}
        boxShadow="medium"
        borderRadius="20px"
      >
        <Box display="flex" flexDirection="column">
          <Text size="heading2" mb="m">
            {Headings.tellUsAboutYourSelf}
          </Text>
        </Box>

        <Field
          name="name"
          type="text"
          required={true}
          placeholder={role === ROLE_ARTIST ? InputPlaceholders.artistName : InputPlaceholders.name}
          width="100%"
          sx={inputDesign}
          style={{ marginBottom: "12px" }}
          component={Input}
          formName="register"
        />

        {role !== "artist" && (
          <Field
            name="organisationName"
            type="text"
            placeholder={InputPlaceholders.organisation}
            width="100%"
            required={role === ROLE_GALLERIST || role === ROLE_ART_INSTITUTION}
            sx={inputDesign}
            style={{ marginBottom: "6px" }}
            component={Input}
            formName="register"
          />
        )}
        <Flex alignItems="start" justifyContent="center" width="100%" pl="8px">
          <Icon icon="Info" size={isMobile ? "10" : "14"} mr="xxs" />
          <Text size={isMobile ? "8px" : "12px"} textAlign="justify" mb="m" width={isMobile ?? "215px"}>
            {role === ROLE_ARTIST
              ? Info.artistInfo
              : _.includes([ROLE_ART_PROFESSIONAL, ROLE_COLLECTOR], role)
              ? Info.organisationInfo
              : Info.artInfo}
          </Text>
        </Flex>
        <Button
          buttonStyle="default"
          size={isMobile ? "small" : "medium"}
          type="submit"
          disabled={submitting}
          icon={submitting ? "Loader" : null}
          iconSpinning={true}
          flip={true}
          iconOffset="xs"
          alignSelf="center"
          bg="white"
        >
          {ButtonLabels.next}
        </Button>
        <Button
          buttonStyle="link"
          type="button"
          padding="xxs"
          textDecoration="underline"
          color="black"
          alignSelf="center"
          onClick={() => history.goBack()}
        >
          {ButtonLabels.back}
        </Button>
      </Flex>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  const { name, organisationName, role } = values;
  if (!name) errors.name = InputErrors.noName;

  if (role === ROLE_GALLERIST || role === ROLE_ART_INSTITUTION) {
    if (!organisationName) errors.organisationName = InputErrors.noOrganisation;
  }

  return errors;
};

let OnboardingSecondForm = reduxForm({
  form: "register",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(Form);

const selector = formValueSelector("register");

function mapStateToProps(state) {
  const role = selector(state, "role");
  return { role };
}

OnboardingSecondForm = connect(mapStateToProps, null)(OnboardingSecondForm);

export { OnboardingSecondForm };
