import React from "react";
import styled, { withTheme } from "styled-components";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../config";
import { Box, Flex, Image } from "../fundamentals";
import { PaymentForm } from "../forms";

const StyledPaymentScreen = styled(Flex)`
  position: absolute;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const PaymentScreen = ({ theme }) => {
  return (
    <StyledPaymentScreen background={theme.backgroundColor}>
      <Image src={process.env.PUBLIC_URL + "/images/brand-identity/logo-text.png"} alt="logo" width="184px" p="s" />

      <Box display="flex" justifyContent="center" pt="l">
        <Elements stripe={stripePromise}>
          <PaymentForm />
        </Elements>
      </Box>
    </StyledPaymentScreen>
  );
};

export default withTheme(PaymentScreen);
