import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearch,
  selectSelectedCollection,
  selectSelectedCollectionArtworksCategories,
} from "../../store/selectors";
import { useVirtual } from "react-virtual";
import { Box, Flex, Text, Image, Link } from "../fundamentals";
import { Button, Modal } from "../elements";
import { AdvancedExportsModal, ShareModal } from "../modals";
import { CollectionHeader, ArtworkCategory } from "../modules";
import { WithRequireAccess, StickyObservable, WithItemMeasure } from "../hocs";
import { ButtonLabels, Messages } from "../../localisation";
import { useHistory } from "react-router-dom";
import { clearAllSelectedArtworks, updateSearchTerm } from "../../store/actions";
import { NavigationBar } from "../react-material/NavigationBar";
import { Helmet } from "react-helmet";

const ArtworkCategoryWithItemMeasure = WithItemMeasure(ArtworkCategory);

const ArchiveScreen = () => {
  const selectedCollection = useSelector(selectSelectedCollection);
  const categories = useSelector(selectSelectedCollectionArtworksCategories);
  const search = useSelector(selectSearch);
  const dispatch = useDispatch();
  const history = useHistory();
  const isSearchTermEmpty = search.searchTerm !== "";

  useEffect(() => {
    dispatch(clearAllSelectedArtworks());
    dispatch(updateSearchTerm(""));
  }, []);

  const handleAddArtworkButtonClick = () => {
    dispatch(clearAllSelectedArtworks());
    history.push("/artworks/add");
  };

  const renderEmptyArtworkList = () => {
    return (
      <Flex flexDirection="column" alignItems="center" height="100%" width="100%">
        <Image
          src={process.env.PUBLIC_URL + "/images/icons/brand-icons/empty-state-illustration.svg"}
          alt="brand"
          width="130px"
          height="180px"
          mt="xxxl"
        />

        <Text fontFamily="bodyDmSans" size="heading2" mt="m">
          {isSearchTermEmpty ? Messages.noArtWorksFound : Messages.noArtWorks}
        </Text>
        <Text width="300px" height="48px" overflow="hidden" overflowY="hidden" mt="s" textAlign="center">
          {isSearchTermEmpty ? Messages.emptySearch(search.searchTerm) : Messages.addArtWorks}
        </Text>
        <Box mt="m">
          {isSearchTermEmpty ? (
            <Button
              icon="XCircle"
              buttonStyle="secondary"
              size="medium"
              mr="s"
              onClick={() => dispatch(updateSearchTerm(""))}
            >
              {ButtonLabels.clearSearch}
            </Button>
          ) : (
            <Link href="https://www.simplify.art/help" target="_blank" mr="s">
              <Button icon="HelpCircle" buttonStyle="secondary" size="medium">
                {ButtonLabels.help}
              </Button>
            </Link>
          )}
          <Button icon="Plus" buttonStyle="primary" size="medium" onClick={handleAddArtworkButtonClick}>
            {ButtonLabels.addArtwork}
          </Button>
        </Box>
      </Flex>
    );
  };

  return (
    <Flex position="fixed" right="0" left="0" top="0" bottom="0" justifyContent="center" bg="grey.10" zIndex="1">
      {/*App Bar*/}
      <Helmet>
        <title>Simplify.art | App for Collectors, Gallerists & Artists</title>
        <meta
          name="description"
          content="The app for managing your collection, gallery or portfolio. We make managing art as effortless as enjoying it"
        />
      </Helmet>
      <Flex flexDirection="column" width="100%" maxWidth="98%" margin="0 auto" position="relative">
        <NavigationBar />

        {selectedCollection && (
          <Text size="heading2" color="black" pb={["xxs", "xs"]} pl="l">
            {selectedCollection.name}
          </Text>
        )}
        <StickyObservable options={{ threshold: 1 }}>
          <CollectionHeader initialValues={{ searchTerm: search.searchTerm, sortBy: search.sortBy }} />
        </StickyObservable>

        {/*Artwork List*/}
        {categories.length === 0 && renderEmptyArtworkList()}
        {categories.length !== 0 && <RowVirtualizerDynamic rows={categories} />}
      </Flex>
      <Modal id="advanced_export">
        <AdvancedExportsModal />
      </Modal>
      <Modal id="share">
        <ShareModal />
      </Modal>
    </Flex>
  );
};

const RowVirtualizerDynamic = ({ rows, listHeight, listWidth, scrollPosition }) => {
  const parentRef = useRef(null);

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.totalSize}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          return (
            <ArtworkCategoryWithItemMeasure
              key={virtualRow.index}
              measure={virtualRow.measureRef}
              category={rows[virtualRow.index]}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                transform: `translateY(${virtualRow.start}px)`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WithRequireAccess(ArchiveScreen, 1);
