import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "redux-form";
import { selectSelectedArtworkIds, selectSelectedArtworks, selectUser } from "../../store/selectors";
import { TUTORIAL_NAMES } from "../../config";
import { FieldNotEmpty } from "../../utils";
import { createExport, createCsv, executeJoyride } from "../../store/actions";
import { Box, Flex, Text } from "../fundamentals";
import { GenericExportForm } from "../forms";
import { PageTransitions, Input, InputSelect, RadioSelect, Checkbox, Button, Section } from "../elements";

import {
  Headings,
  InputLabels,
  Instructions,
  UiLabels,
  Messages,
  ExportStandardConditions,
  InputErrors,
  Warnings
} from "../../localisation";

const AdvancedExportsModal = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState("template");
  const selectedArtworkIds = useSelector(selectSelectedArtworkIds);

  useEffect(() => {
    dispatch(executeJoyride(TUTORIAL_NAMES.whiteLabeledExports));
  }, []);

  const handleOnBackToTemplate = () => {
    setPage("template");
  };

  return (
    <Box>
      <PageTransitions
        page={page}
        initialWidth={800}
        initialHeight={510}
        transition="width 0.2s ease-out"
        timeout="0"
        childAlignment="center"
      >
        <Flex page="template" width="70vw" maxWidth="800px" minWidth="460px" height="510px" alignItems="stretch">
          <Box
            width="5px"
            background="linear-gradient(129.48deg, #3C21F7 5.19%, #1CFFD3 96.07%)"
            mt="-48px"
            mb="-48px"
            ml="-48px"
            overflow="hidden"
          />
          <Box flex="2" ml="xxl">
            <Text size="heading2" color="black">
              {Headings.exportArtworks}
            </Text>
            <Text size="body" color="grey.80">
              {Instructions.exportsTypeSelect}
            </Text>
            <Flex mt="xxl">
              <Box flex="1">
                <Section title={Headings.artworkLists} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button buttonStyle="text" size="medium" color="grey.60" pl="none" disabled={true}>
                    {`${InputLabels.portfolio} (coming soon)`}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("workList")}>
                    {InputLabels.workList}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("transportList")}
                  >
                    {InputLabels.transportList}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("insuranceList")}
                  >
                    {InputLabels.insuranceList}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("priceList")}>
                    {InputLabels.priceList}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color={selectedArtworkIds.length > 1 ? "grey.60" : "black"}
                    pl="none"
                    onClick={() => setPage("dataSheet")}
                    disabled={selectedArtworkIds.length > 1}
                  >
                    {InputLabels.dataSheet}
                  </Button>
                </Section>

                <Section
                  title={Headings.salesAndAgreements}
                  titleSize="small"
                  color="grey.80"
                  titleStyle={{ pb: "xxs" }}
                >
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("invoice")}>
                    {InputLabels.invoice}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("loanAgreement")}
                  >
                    {InputLabels.loanAgreement}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("consignmentAgreement")}
                  >
                    {InputLabels.consignmentAgreement}
                  </Button>
                </Section>
              </Box>
              <Box flex="1">
                <Section title={Headings.artworkDocuments} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="black"
                    pl="none"
                    onClick={() => setPage("certificate")}
                  >
                    {InputLabels.certificate}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="grey.60" pl="none" disabled={true}>
                    {`${InputLabels.conditionReport} (coming soon)`}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color={selectedArtworkIds.length > 1 ? "grey.60" : "black"}
                    pl="none"
                    onClick={() => setPage("installationManual")}
                    disabled={selectedArtworkIds.length > 1}
                  >
                    {InputLabels.installationManual}
                  </Button>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("handoff")}>
                    {InputLabels.handoff}
                  </Button>
                </Section>

                <Section title={Headings.labels} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="grey.60"
                    disabled={true}
                    pl="none"
                    onClick={() => setPage("storageLabels")}
                  >
                    {`${InputLabels.storageLabel} (coming soon)`}
                  </Button>
                  <Button
                    buttonStyle="text"
                    size="medium"
                    color="grey.60"
                    disabled={true}
                    pl="none"
                    onClick={() => setPage("wallLabels")}
                  >
                    {`${InputLabels.wallLabel} (coming soon)`}
                  </Button>
                </Section>
                <Section title={Headings.csv} titleSize="small" color="grey.80" titleStyle={{ pb: "xxs" }}>
                  <Button buttonStyle="text" size="medium" color="black" pl="none" onClick={() => setPage("csv")}>
                    {InputLabels.csv}
                  </Button>
                </Section>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <WorkListModal page="workList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <TransportListModal page="transportList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <InsuranceListModal page="insuranceList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <PriceListModal page="priceList" handleOnBackToTemplate={handleOnBackToTemplate} />
        <InvoiceModal page="invoice" handleOnBackToTemplate={handleOnBackToTemplate} />
        <LoanAgreementModal page="loanAgreement" handleOnBackToTemplate={handleOnBackToTemplate} />
        <ConsignmentAgreementModal page="consignmentAgreement" handleOnBackToTemplate={handleOnBackToTemplate} />
        <HandoffDocumentModal page="handoff" handleOnBackToTemplate={handleOnBackToTemplate} />
        <CertificateModal page="certificate" handleOnBackToTemplate={handleOnBackToTemplate} />
        <InstallationManualModal page="installationManual" handleOnBackToTemplate={handleOnBackToTemplate} />
        <DataSheetModal page="dataSheet" handleOnBackToTemplate={handleOnBackToTemplate} />
        <StorageLabelsModal page="storageLabels" handleOnBackToTemplate={handleOnBackToTemplate} />
        <WallLabelsModal page="wallLabels" handleOnBackToTemplate={handleOnBackToTemplate} />
        <CsvModal page="csv" handleOnBackToTemplate={handleOnBackToTemplate} />
      </PageTransitions>
    </Box>
  );
};

const WorkListModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.workList}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.workList,
        sortBy: "artist.name",
        showPrice: false,
        showLocation: false,
        showCurrentOwner: false
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year }
        ]}
        component={InputSelect}
        stateful
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        <Field name="showLocation" label={InputLabels.location} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const TransportListModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.transportList}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.transportList,
        sortBy: "artist.name",
        showPrice: false,
        showLocation: false
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="originAddress"
        label={InputLabels.pickupAddress}
        validate={FieldNotEmpty}
        component={Input}
        required
      />
      <Field
        name="targetAddress"
        label={InputLabels.destinationAddress}
        validate={FieldNotEmpty}
        mb="s"
        component={Input}
        required
      />

      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year }
        ]}
        component={InputSelect}
        stateful
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        <Field name="showLocation" label={InputLabels.location} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const InsuranceListModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.insuranceList}
      page={page}
      width="390px"
      initialValues={{ title: InputLabels.insuranceList, sortBy: "artist.name" }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year },
          { value: "artworkValues.location", label: InputLabels.location }
        ]}
        component={InputSelect}
        stateful
      />
    </GenericExportForm>
  );
};

const PriceListModal = ({ page, handleOnBackToTemplate }) => {
  const artworks = useSelector(selectSelectedArtworks);

  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.priceList}
      page={page}
      width="390px"
      initialValues={{ title: InputLabels.priceList, sortBy: "artist.name" }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
      warn={values => {
        const warnings = {};
        if (artworks.some(artwork => !artwork.artworkValues.hasOwnProperty("price"))) {
          warnings._warning = Warnings.missingSelectedArtworkPrices;
        }
        return warnings;
      }}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="sortBy"
        label={InputLabels.sortBy}
        options={[
          { value: "artist.name", label: InputLabels.artist },
          { value: "archiveArtworkNumber", label: InputLabels.id },
          { value: "artworkValues.year", label: InputLabels.year }
        ]}
        component={InputSelect}
        stateful
      />
    </GenericExportForm>
  );
};

const InvoiceModal = ({ page, handleOnBackToTemplate }) => {
  const artworks = useSelector(selectSelectedArtworks);

  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.invoice}
      page={page}
      width="390px"
      initialValues={{ title: InputLabels.invoice, sortBy: "artist.name" }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
      warn={values => {
        const warnings = {};
        if (artworks.some(artwork => !artwork.artworkValues.hasOwnProperty("price"))) {
          warnings._warning = Warnings.missingSelectedArtworkPrices;
        }
        return warnings;
      }}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} component={Input} required />
      <Field
        name="invoiceAddress"
        label={InputLabels.invoiceAddress}
        validate={FieldNotEmpty}
        component={Input}
        required
      />
      <Field name="discount" label={InputLabels.discount} type="number" suffix="%" component={Input} />
      <Field
        name="vat"
        label={InputLabels.vat}
        validate={FieldNotEmpty}
        type="number"
        suffix="%"
        component={Input}
        required
      />
      <Field name="bankDetails" label={InputLabels.bankDetails} type="textarea" minHeight="100px" component={Input} />
      <Field
        name="paymentConditions"
        label={InputLabels.paymentConditions}
        type="textarea"
        minHeight="100px"
        component={Input}
      />
      <Field name="remarks" label={InputLabels.remarks} type="textarea" minHeight="100px" component={Input} />
    </GenericExportForm>
  );
};

const LoanAgreementModal = ({ page, handleOnBackToTemplate }) => {
  const user = useSelector(selectUser);
  const { email, name, address } = user;
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.loanAgreement}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.loanAgreement,
        lender: {
          name: name,
          address: address ? `${address.addressLine1}, ${address.city} ${address.country} ${address.postalCode}` : "",
          contactPersonEmail: email
        },
        consignmentConditions: ExportStandardConditions.loanAgreement
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} component={Input} required />
      <Field name="loanDuration" label={InputLabels.loanDuration} validate={FieldNotEmpty} component={Input} required />
      <Section title={Headings.borrower} titleSize="body" pb="xxxs" mb="s">
        <Field
          name="borrower.name"
          label={InputLabels.borrowerName}
          validate={FieldNotEmpty}
          component={Input}
          required
        />
        <Field
          name="borrower.address"
          label={InputLabels.address}
          validate={FieldNotEmpty}
          component={Input}
          required
        />
        <Field name="borrower.contactPerson" label={InputLabels.contactPerson} component={Input} />
        <Field name="borrower.contactPersonEmail" label={InputLabels.email} component={Input} />
        <Field name="borrower.contactPersonPhone" label={InputLabels.phoneNumber} component={Input} />
      </Section>
      <Section title={Headings.lender} titleSize="body" pb="xxxs" mb="s">
        <Field name="lender.name" label={InputLabels.lenderName} validate={FieldNotEmpty} component={Input} required />
        <Field name="lender.address" label={InputLabels.address} validate={FieldNotEmpty} component={Input} required />
        <Field name="lender.contactPerson" label={InputLabels.contactPerson} component={Input} />
        <Field name="lender.contactPersonEmail" label={InputLabels.email} component={Input} />
        <Field name="lender.contactPersonPhone" label={InputLabels.phoneNumber} component={Input} />
      </Section>
      <Section title={Headings.exhibition} titleSize="body" pb="xxxs" mb="s">
        <Field
          name="exhibitionValues.title"
          label={InputLabels.title}
          validate={FieldNotEmpty}
          component={Input}
          required
        />
        <Field name="exhibitionValues.duration" label={InputLabels.duration} component={Input} />
      </Section>
      <Section title={Headings.credits} titleSize="body" pb="xxxs" mb="s">
        <Field name="loanCreditstextPrint" label={InputLabels.printCredits} component={Input} />
        <Field name="loanCreditstextPhoto" label={InputLabels.photoCredits} component={Input} />
      </Section>
      <Section title={Headings.transport} titleSize="body" pb="xxxs" mb="s">
        <Field name="pickupAddress" label={InputLabels.pickupAddress} component={Input} />
        <Field name="returnAddress" label={InputLabels.returnAddress} component={Input} />
      </Section>
      <Section title={Headings.insurance} titleSize="body" pb="xxxs" mb="s">
        <Field name="insuranceAgreement" label={InputLabels.insuranceAgreement} component={Input} />
      </Section>
      <Section title={Headings.consignmentConditions} titleSize="body" pb="xxxs" mb="none">
        <Field name="consignmentConditions" type="textarea" minHeight="100px" component={Input} />
      </Section>
    </GenericExportForm>
  );
};

const ConsignmentAgreementModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.consignmentAgreement}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.consignmentAgreement,
        consignmentConditions: ExportStandardConditions.consignmentAgreement
      }}
      validate={({ percentageArtistSplit, percentageGallerySplit }) => {
        const errors = {};
        if (
          percentageArtistSplit !== undefined &&
          percentageGallerySplit !== undefined &&
          parseFloat(percentageArtistSplit) + parseFloat(percentageGallerySplit) !== 100
        ) {
          errors.percentageArtistSplit = InputErrors.percetageNot100;
        }
        return errors;
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field name="targetAddress" label={InputLabels.artist} validate={FieldNotEmpty} component={Input} required />
      <Flex justifyContent="space-between">
        <Field
          name="percentageArtistSplit"
          label={InputLabels.percentageArtistSplit}
          type="number"
          suffix="%"
          validate={FieldNotEmpty}
          component={Input}
        />
        <Field
          name="percentageGallerySplit"
          label={InputLabels.percentageGallerySplit}
          type="number"
          suffix="%"
          validate={FieldNotEmpty}
          component={Input}
        />
      </Flex>

      <Field
        name="consignmentConditions"
        label={Headings.consignmentConditions}
        type="textarea"
        minHeight="100px"
        component={Input}
      />
    </GenericExportForm>
  );
};

const HandoffDocumentModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.handoffDocument}
      page={page}
      width="390px"
      initialValues={{ title: InputLabels.handoff }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field
        name="originAddress"
        label={InputLabels.originAddress}
        validate={FieldNotEmpty}
        component={Input}
        required
      />
      <Field
        name="targetAddress"
        label={InputLabels.targetAddress}
        validate={FieldNotEmpty}
        component={Input}
        required
      />
    </GenericExportForm>
  );
};

const CertificateModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.certificate}
      page={page}
      width="390px"
      initialValues={{ docText: Messages.certificateText }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} mb="s" component={Input} required />
      <Field name="docText" label={InputLabels.text} type="textarea" minHeight="100px" component={Input} />
    </GenericExportForm>
  );
};

const InstallationManualModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.installationManual}
      page={page}
      width="390px"
      initialValues={{ title: InputLabels.installationManual }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} validate={FieldNotEmpty} component={Input} required />
    </GenericExportForm>
  );
};

const DataSheetModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.dataSheet}
      page={page}
      width="390px"
      initialValues={{
        title: InputLabels.dataSheet,
        sortBy: "artist.name",
        showPrice: false,
        showLocation: false
      }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s">
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const StorageLabelsModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.storageLabels}
      page={page}
      width="390px"
      initialValues={{ title: InputLabels.storageLabel, labelSize: "regular", printGuidelines: false }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field
        name="labelSize"
        label={InputLabels.labelFormat}
        options={{
          regular: { label: InputLabels.regular, description: "99.06 x 67.73 mm\n8 / A4 sheet" },
          thin: { label: InputLabels.small, description: "6 / A4 sheet" }
        }}
        component={RadioSelect}
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s" mt="none">
        <Field name="printGuidelines" label={InputLabels.guidelines} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const WallLabelsModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createExport(page, values));
  };

  return (
    <GenericExportForm
      title={Headings.wallLabels}
      page={page}
      width="390px"
      initialValues={{ title: InputLabels.wallLabel, labelSize: "regular", printGuidelines: false }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field
        name="labelSize"
        label={InputLabels.labelFormat}
        options={{
          regular: { label: InputLabels.regular, description: "105 x 148 mm\n4 / A4 sheet" },
          small: { label: InputLabels.small, description: "105 x 74 mm\n8 / A4 sheet" }
        }}
        component={RadioSelect}
      />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s" mt="none">
        <Field name="printGuidelines" label={InputLabels.guidelines} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

const CsvModal = ({ page, handleOnBackToTemplate }) => {
  const submit = (values, dispatch) => {
    return dispatch(createCsv(values));
  };

  return (
    <GenericExportForm
      title={Headings.newCsv}
      page={page}
      width="390px"
      initialValues={{ showPrice: false, showLocation: false, showCurrentOwner: false }}
      onSubmit={submit}
      handleOnBackToTemplate={handleOnBackToTemplate}
    >
      <Field name="title" label={InputLabels.title} component={Input} />
      <Section subtitle={UiLabels.include} pb="xxxs" mb="s" mt="none">
        <Field name="showPrice" label={InputLabels.price} mb="s" component={Checkbox} />
        <Field name="showLocation" label={InputLabels.location} mb="s" component={Checkbox} />
        <Field name="showCurrentOwner" label={InputLabels.currentOwner} component={Checkbox} />
      </Section>
    </GenericExportForm>
  );
};

export { AdvancedExportsModal };
