import React from "react";
import styled from "styled-components";
import "swiper/css/swiper.css";

import Swiper from "react-id-swiper";
import { Box } from "../fundamentals";

const StyledSwiper = styled(Box)`
  .swiper-wrapper {
    margin-bottom: 20px;
  }
  .swiper-scrollbar {
    margin-bottom: 10px;
  }
  .swiper-scrollbar-drag {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const SimplifySwiper = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledSwiper>
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        preventClicks={false}
        allowTouchMove={children.length > 1}
        touchStartPreventDefault={false}
        scrollbar={{
          el: ".swiper-scrollbar",
          hide: false,
          draggable: true
        }}
        watchOverflow
      >
        {React.Children.map(children, child => (
          <Box width="auto" maxWidth="100%" mt="xs" mb={children.length > 1 ? "xs" : "none"}>
            {child}
          </Box>
        ))}
      </Swiper>
    </StyledSwiper>
  );
});

export { SimplifySwiper as Swiper };
